/* eslint-disable no-case-declarations */

import { Container } from '@cmp/canvas-lib';
import { ResizeStrategy, ResizeStrategyType } from '@cmp/game-mechanics';
import { IBaseConfig, assertNever } from '@cmp/common';

export type ResizeParams = {
  evt: any,
  orientation: 'Hor' | 'Vert',
  parentWidth: number,
  parentHeight: number,
  path?: string,
  resizeStrategy?: ResizeStrategy,
};

type ResizeValue = 'x' | 'y' | 'w' | 'h' | 'r';

/* Per Frame Resize Function */

export const defaultResize = (targetRef: Ref<number>, parentRef: Ref<number>, newValue: number) => {
  targetRef.value = parentRef.value * (newValue / 100);
};

/* Transform Functions */

const applyParentLockedStrategy = (key: ResizeValue, params: ResizeParams, values) => {
  const { parentWidth, parentHeight } = params;
  const { x, y, width, height } = params.evt;
  const ratio = height / width;

  switch (key) {
    case 'x':
    case 'w':
      if (parentWidth < width) {
        values.width = parentWidth;
        values.height = parentWidth * ratio;
      }
      if (x + width / 2 > parentWidth) {
        values.x = parentWidth - width / 2;
      }
      if (x - width / 2 < 0) {
        values.x = width / 2;
      }
      break;
    case 'y':
    case 'h':
      if (parentHeight < height) {
        values.height = parentHeight;
        values.width = parentHeight / ratio;
      }
      if (y + height / 2 > parentHeight) {
        values.y = parentHeight - height / 2;
      }
      if (y - height / 2 < 0) {
        values.y = height / 2;
      }
      break;
    case 'r':
      break;
    default:
      assertNever(key);
  }
};

// const applyStaticConfigStrategy = (key: ResizeValue, params: ResizeParams, values) => {
//   switch (key) {
//     case 'x':
//     case 'y':
//       values[key] = params.evt[key];
//       break;
//     case 'w':
//     case 'h':
//     case 'r':
//       values[key] = params.evt[key];
//       break;
//     default:
//       assertNever(key);
//   };
// }

const applyResizeStrategy = (key: ResizeValue, strategy: ResizeStrategyType, params: ResizeParams, values) => {
  switch (strategy) {
    case ResizeStrategyType.PARENTLOCKED:
      applyParentLockedStrategy(key, params, values);
      break;
    case ResizeStrategyType.DISABLED:
      break;
    case ResizeStrategyType.OVERRIDEPARENT:
      break;
    case ResizeStrategyType.STATICCONFIG:
      // applyStaticConfigStrategy(key, params, values);
      break;
    default:
      assertNever(strategy);
  }
};

export const onTransform = (params: ResizeParams, parentConfig = null) => {
  // Get base values that will be processed and returned
  const values = {
    rotation: +(params.evt.rotation),
    x: +(params.evt.x),
    y: +(params.evt.y),
    width: +(params.evt.width),
    height: +(params.evt.height),
  };

  // if ((key === 'w' || key === 'h') && childConfig[`aspectLock${orientation}`]) {
  //   if (node.width < node.height) {
  //     values.width = node.width * (childConfig[`width${orientation}`] / 100);
  //     values.height = values.width * ratio;
  //   } else {
  //     values.height = node.height * (childConfig[`height${orientation}`] / 100);
  //     values.width = values.height / ratio;
  //   }
  //   return;
  // }

  // Get modified values for layers that use alternate parent
  const { evt } = params;
  if (parentConfig) {
    const { config } = parentConfig;
    const { width, height } = parentConfig.parentNode;
    evt.rotation -= config.rotation;
    evt.x = +((evt.x - config.x * width + (config.width * (width / 2))) / config.width);
    evt.y = +((evt.y - config.y * height + (config.height * (height / 2))) / config.height);
    evt.width = +(evt.width / config.width);
    evt.height = +(evt.height / config.height);
  }

  if (params.resizeStrategy) {
    Object.keys(params.resizeStrategy).forEach((key) => {
      // Overwrites defaults in values object with values processed with resize strategy
      applyResizeStrategy(key as ResizeValue, params.resizeStrategy[key], params, values);
    });
  }

  // Return raw pixel values of the layer so the node can be updated in PlayLayer
  return values;
};

/* Per frame resize child nodes */

export const resizeChildDefault = (childNode: Container, oldChildPositions: Ref, node: Container) => {
  const oldChild = oldChildPositions.value[childNode.id];
  const newPos = node.globalToLocal(oldChild.x, oldChild.y);
  childNode.set(newPos);
  oldChildPositions.value[childNode.id] = node.localToGlobal(newPos.x, newPos.y);
};

const realKeys = {
  r: 'rotation',
  x: 'x',
  y: 'y',
  w: 'width',
  h: 'height',
};

const getConfigValue = (key: ResizeValue, childConfig: Partial<IBaseConfig>, parentNode: Container, orientation: 'Vert' | 'Hor') => {
  switch (key) {
    case 'r':
      return childConfig[`angle${orientation}`];
    case 'x':
      return parentNode.width * (childConfig[`xPercentage${orientation}`] / 100);
    case 'y':
      return parentNode.height * (childConfig[`yPercentage${orientation}`] / 100);
    case 'w':
      return parentNode.width * (childConfig[`width${orientation}`] / 100);
    case 'h':
      return parentNode.height * (childConfig[`height${orientation}`] / 100);
    default:
      return assertNever(key);
  }
};

const getChildBaseValues = (childNode: Container, childConfig: Partial<IBaseConfig>, node: Container, orientation: 'Vert' | 'Hor', strategy: ResizeStrategy) => {
  const values = {
    rotation: +(childNode.rotation),
    x: +(childNode.x),
    y: +(childNode.y),
    width: +(childNode.width),
    height: +(childNode.height),
  };

  const ratio = values.height / values.width;

  if (strategy) {
    Object.keys(strategy).forEach((key) => {
      switch (strategy[key]) {
        case ResizeStrategyType.OVERRIDEPARENT:
          delete values[realKeys[key]];
          break;
        case ResizeStrategyType.DISABLED:
        case ResizeStrategyType.PARENTLOCKED:
        case ResizeStrategyType.STATICCONFIG:
          if ((key === 'w' || key === 'h') && childConfig[`aspectLock${orientation}`]) {
            if (node.width < node.height) {
              values.width = node.width * (childConfig[`width${orientation}`] / 100);
              values.height = values.width * ratio;
            } else {
              values.height = node.height * (childConfig[`height${orientation}`] / 100);
              values.width = values.height / ratio;
            }
            return;
          }
          values[realKeys[key]] = getConfigValue(key as ResizeValue, childConfig, node, orientation);
          break;
        default:
          break;
      }
    });
  }

  return values;
};

export const resizeChild = (childNode: Container, childConfig: Partial<IBaseConfig>, node: Container, orientation: 'Vert' | 'Hor', strategy: ResizeStrategy) => {
  const values = getChildBaseValues(childNode, childConfig, node, orientation, strategy);
  if (strategy) {
    Object.keys(strategy).forEach((key) => {
      // Overwrites defaults in values object with values processed with resize strategy
      applyResizeStrategy(key as ResizeValue, strategy[key], {
        evt: values,
        orientation,
        parentWidth: node.width,
        parentHeight: node.height,
        resizeStrategy: strategy[key],
      }, values);
    });
  }

  childNode.set(values);
};

/* On asset swap */

export const onAssetSwap = (params: ResizeParams) => {
  const newValues = { width: params.evt.width, height: params.evt.height };

  Object.keys(params.resizeStrategy).forEach((key) => {
    // Overwrites defaults in values object with values processed with resize strategy
    applyResizeStrategy(
      key as ResizeValue,
      params.resizeStrategy[key],
      params,
      newValues,
    );
  });

  return newValues;
};

/* Transform child nodes */

export const transformChild = (params: ResizeParams) => onTransform(params);

/* Generate limits for transform in settings panel */

const getParentLockedLimit = (key: ResizeValue, config: Partial<IBaseConfig>) => {
  switch (key) {
    case 'x':
      return [{
        key: 'xPercentageVert',
        MIN: config.widthVert / 2,
        MAX: 100 - config.widthVert / 2,
      }, {
        key: 'xPercentageHor',
        MIN: config.widthHor / 2,
        MAX: 100 - config.widthHor / 2,
      }];
    case 'y':
      return [{
        key: 'yPercentageVert',
        MIN: config.heightVert / 2,
        MAX: 100 - config.heightVert / 2,
      }, {
        key: 'yPercentageHor',
        MIN: config.heightHor / 2,
        MAX: 100 - config.heightHor / 2,
      }];
    case 'w':
    case 'h':
      const ratioVert = config.heightVert / config.widthVert;
      const widthVertMax = Math.min(config.xPercentageVert * 2, (100 - config.xPercentageVert) * 2);
      const heightVertMax = Math.min(config.yPercentageVert * 2, (100 - config.yPercentageVert) * 2);
      const ratioHor = config.heightHor / config.widthHor;
      const widthHorMax = Math.min(config.xPercentageHor * 2, (100 - config.xPercentageHor) * 2);
      const heightHorMax = Math.min(config.yPercentageHor * 2, (100 - config.yPercentageHor) * 2);

      if (key === 'w') {
        return [{
          key: 'widthVert',
          MAX: config.aspectLockVert ? Math.min(widthVertMax, heightVertMax / ratioVert) : widthVertMax,
        }, {
          key: 'widthHor',
          MAX: config.aspectLockHor ? Math.min(widthHorMax, heightHorMax / ratioHor) : widthHorMax,
        }];
      }
      return [{
        key: 'heightVert',
        MAX: config.aspectLockVert ? Math.min(heightVertMax, widthVertMax * ratioVert) : heightVertMax,
      }, {
        key: 'heightHor',
        MAX: config.aspectLockHor ? Math.min(heightHorMax, widthHorMax * ratioHor) : heightHorMax,
      }];

    case 'r':
    default:
      return [];
  }
};

export const getLimits = (config: Partial<IBaseConfig>, strategy: ResizeStrategy) => {
  const lims = [];

  if (strategy) {
    Object.keys(strategy).forEach((key) => {
      const keyLims = getParentLockedLimit(key as ResizeValue, config);
      if (keyLims.length > 0) lims.push(...keyLims);
    });
  }

  return lims;
};
