/**
 * Function that receive an entity list, a list
 * of keys and a value to check if exists in at least
 * one key of the list.
 * @param entityList => Entity array (Temporalically any type)
 * @param keys => Keys array
 * @param value => Value to search
 */
export function filterByKeys(entityList: any[], keys: string[], value: string): any[] {
  return entityList.filter(
    (item) => keys.some(
      (key) => item[key].toLowerCase().includes(value.toLowerCase()),
    ),
  );
}

export default filterByKeys;
