/**
 * Receives a list and map it adding the separator and
 * Root to the result if receive root parameter.
 * @param list - List to map
 * @param root - Root value
 * @returns - Path in this format (item1 > item2 > ...)
 */
export function buildPathFromArray(list: string[], root: string) {
  const result = (root.length === 0) ? '' : root;

  if (list.length === 0) return result;

  return `${(root ? [result, ...list] : list).join(' > ')}`;
}

export default buildPathFromArray;
