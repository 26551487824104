export * from './types';
export const tracker = new Worker(new URL('./tracker.ts', import.meta.url), { type: 'module' });
export const creative = new Worker(new URL('./creative.ts', import.meta.url), { type: 'module' });
export const creativeSize = new Worker(new URL('./creativeSize.ts', import.meta.url), { type: 'module' });
export const socket = new Worker(new URL('./socket.ts', import.meta.url), { type: 'module' });
export const fetchAsset = new Worker(new URL('./fetchAsset.ts', import.meta.url), { type: 'module' });
export const fetchHistory = new Worker(new URL('./fetchHistory.ts', import.meta.url), { type: 'module' });
export const astar = new Worker(new URL('./astar.ts', import.meta.url), { type: 'module' });

export const offscreenCanvas = new Worker(new URL('./offscreenCanvas.ts', import.meta.url), { type: 'module' });
export const variantGenerator = new Worker(new URL('./variantGenerator.ts', import.meta.url), { type: 'module' });
