import { defineStore } from 'pinia';
import { Column } from '@/common/interfaces/column';

export const useTableStore = defineStore('table', {
  state: () => ({
    searchQuery: '',
    dialogQuery: '',
    isDialogOpened: false, // TODO => Check if it's necessary to move to new/different store
    tableOptions: [] as Column[],
    asyncSorting: false,
  }),

  getters: {
    currentSortIndex: (state) => state.tableOptions.findIndex((option: Column) => option.selected),
  },

  actions: {
    setTableOptions(options: Column[]) {
      this.tableOptions = options.map((option) => ({
        ...option,
        selected: false,
        desc: false,
      }));
    },

    setDialogStatus(status: boolean) {
      this.isDialogOpened = status;
    },

    updateOptionKey(index: number, key: string, value: string | boolean) {
      this.tableOptions[index][key] = value;
    },
  },

});

export default useTableStore;
