import { RouteRecordRaw } from 'vue-router';

const adminRoutes: RouteRecordRaw[] = [
  {
    path: 'admin',
    name: 'SystemAdminView',
    redirect: { name: 'SystemAdminCraftsmanPlus' },
    meta: { menu: 'systemAdmin' },
    component: () => import('@/views/admin/AdminView.vue'),
    children: [
      {
        path: 'users',
        name: 'SystemAdminCraftsmanPlus',
        component: () => import('@/views/admin/AdminCraftsmanView.vue'),
      },
      {
        path: 'companies/deleted',
        name: 'SystemAdminDeletedCompanies',
        component: () => import('@/views/admin/AdminDeletedCompanies.vue'),
      },
      {
        path: 'users/deleted',
        name: 'SystemAdminDeletedUsers',
        component: () => import('@/views/admin/AdminDeletedUsers.vue'),
      },
      {
        path: 'games',
        name: 'SystemAdminGames',
        component: () => import('@/views/admin/AdminGames.vue'),
      },
      {
        path: 'partners',
        name: 'AdminPartners',
        component: () => import('@/views/admin/AdminPartners.vue'),
      },
      {
        path: 'templates',
        name: 'AdminTemplates',
        component: () => import('@/views/admin/AdminTemplateTaxonomies.vue'),
      },
    ],
  },
];

export default adminRoutes;
