import { createRouter, createWebHistory } from 'vue-router';
import { useFavicon } from '@vueuse/core';
import { useUserStore, useCreativeStore, useAuthStore, useAppStore, useFeatureFlagsStore } from '@/common/store';
import routes from './routes';
import { tracker as worker } from '../workers';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const featureFlagGuard = (to) => {
  const featureFlagsStore = useFeatureFlagsStore();
  const featureNames = featureFlagsStore.getFeatureNames;
  type FeatureName = typeof featureNames[number];
  featureFlagsStore.$reset();
  let params = to?.query?.feature;
  if (params) {
    if (!Array.isArray(params)) {
      params = [params];
    }
    params.forEach((featureName) => {
      if (featureNames.indexOf(featureName as FeatureName) >= 0) {
        featureFlagsStore[featureName] = true;
      } else {
        console.warn(`Invalid feature flag name: ${featureName}`);
      }
    });
  }
};

router.beforeResolve(async () => {
  const appStore = useAppStore();
  // console.log(to.matched.find((n) => n.name === to.name).components.default);
  appStore.isLoading = false;

  const icon = useFavicon();
  icon.value = `${import.meta.env.BASE_URL}favicon-studio-16092022.ico`;
});

router.beforeEach(async (to, from, next) => {
  featureFlagGuard(to);
  const appStore = useAppStore();
  const authStore = useAuthStore();
  const isProtected = to.matched.slice().reverse().find((r) => r.meta && r.meta.protected);
  appStore.isLoading = true;

  // check the user has access or a token in local storage
  const hasAccess = await authStore.checkAuth();

  if (!hasAccess && isProtected) {
    sessionStorage.setItem('cmp:route', to.fullPath);
    return next({ name: 'SignInView' });
  }

  // Validate if the route is protected
  if (!isProtected) return next();
  const userStore = useUserStore();
  const creativeStore = useCreativeStore();

  // Fetch user info
  await userStore.getUserInfo();

  const { permissions } = userStore.currentUser;
  const isUserExists = Object.entries(userStore.currentUser).length > 0;

  // Validate if user isn't exist or if doesn't have roles
  if (!isUserExists || permissions.length === 0 || !permissions) return next({ name: 'SignInView' });

  // track user activity so we dont log out the user in the API
  worker.postMessage({ token: authStore.token });

  if (to.name === 'MainView' && permissions.length > 0) {
    creativeStore.$reset();

    // Validate if currentUser has the permissions to view companies
    if (!userStore.isAdmin) {
      return next({ name: 'CreativesListView' });
    }

    return next({ name: 'CompaniesListView' });
  }
  if (Object.keys(to.query).length === 0 && Object.keys(from.query).length > 0) {
    return next({ path: to.path, query: from.query });
  }
  return next();
});

export default router;
