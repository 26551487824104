import { i18n } from '@/common/plugins';
import { FileType } from '@/views/assets/enums';
import { AssetDialogConfig } from '@/views/assets/interfaces';

const { t } = i18n.global;

export const INITIAL_DIALOG_SETUP: AssetDialogConfig = {
  show: false,
  title: '',
  okText: t('UPLOAD'),
  componentState: 'global',
  fileType: FileType.ANY,
  enableMultiSelect: true,
};

export default INITIAL_DIALOG_SETUP;
