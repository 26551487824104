import { RouteRecordRaw } from 'vue-router';
import { useTableStore } from '@/common/store';

const companyRoutes: RouteRecordRaw[] = [
  {
    path: 'companies',
    name: 'CompaniesView',
    redirect: { name: 'CompaniesListView' },
    meta: { menu: 'company' },
    component: () => import('@/views/companies/CompaniesView.vue'),
    beforeEnter: async () => {
      const tableStore = useTableStore();
      tableStore.$reset();
    },
    children: [
      {
        path: '',
        name: 'CompaniesListView',
        meta: { menu: 'default' },
        component: () => import('@/views/companies/CompaniesListView.vue'),
      },
      {
        path: 'create',
        name: 'CompanyCreateView',
        component: () => import('@/views/companies/CompanyEditView.vue'),
      },
      {
        path: ':id',
        name: 'CompanyDetailView',
        component: () => import('@/views/companies/CompanyEditView.vue'),
      },
      {
        path: ':id/edit',
        name: 'CompanyEditView',
        component: () => import('@/views/companies/CompanyEditView.vue'),
      },
      {
        path: ':id/users',
        name: 'CompanyUsersView',
        component: () => import('@/views/companies/CompanyUsersView.vue'),
      },
      {
        path: ':id/user-groups',
        name: 'CompanyUserGroupsView',
        component: () => import('@/views/companies/CompanyUserGroupsView.vue'),
      },
    ],
  },
];

export default companyRoutes;
