<script setup lang="ts">
import { useQuasar } from 'quasar';
import { useHead } from '@vueuse/head';
import { createShortcutIndex, IShortKey } from '@/common/utils/shortKeys';
import { shortKeysKey, editingRichTextKey } from '@/common/interfaces/injectionTypes';
import { activity } from './common/utils/signOut';

// set quasar to dark theme
// https://quasar.dev/quasar-plugins/dark
const $q = useQuasar();
$q.dark.set(true);

useHead({
  title: 'CRAFTSMAN STUDIO',
  meta: [
    { name: 'description', content: 'CRAFTSMAN STUDIO' },
  ],
});

let keyList = [];
let keyId = 0;
const editingRichTextId = ref('');

function shortKeyCommand(evt: KeyboardEvent) {
  const target = evt.target as HTMLInputElement;
  if (!keyList.length) return;
  if (editingRichTextId.value !== '') return;
  if (['text'].includes(target?.dataset?.type)) return;
  // allow for enter key on single line inputs
  if (['search', 'text'].includes(target.type) && evt.key !== 'Enter') return;
  if (['input', 'textarea'].includes(target.type)) return;

  const key = createShortcutIndex(evt);
  evt.preventDefault();

  // find the correct key command within this scope
  const cmd = keyList.find((n) => n.keys.join('') === key);
  if (cmd && cmd.callback) cmd.callback();
}

function bindShortKeys(keys: IShortKey[]) {
  // make sure we remove any duplicate keys
  // then increment an id for the given keys to remove on the removeShortKeys command
  const keysWithId = keys
    .map((key) => {
      keyId += 1;
      return { ...key, id: keyId };
    });

  // update the global list of keys for filter above
  keyList = [...keyList, ...keysWithId];

  const removeShortKeys = () => {
    // remove this scope keys from the global keys
    keysWithId.forEach((key) => {
      keyList = keyList.filter((o) => o.id !== key.id);
    });
  };

  return removeShortKeys;
}

provide(shortKeysKey, { bindShortKeys });

// handle state when editing rich text
function update(value: string) {
  editingRichTextId.value = value;
}

// create provider for editing rich text
provide(editingRichTextKey, { editingRichTextId, update });

document.addEventListener('keydown', shortKeyCommand, true);

let timeout: ReturnType<typeof setTimeout>;

function throttle(callback: () => void, wait: number) {
  if (timeout) return;
  timeout = setTimeout(() => {
    callback();
    timeout = undefined;
  }, wait);
}

function activityCommand() {
  throttle(() => {
    activity();
  }, 1000);
}

onUnmounted(() => {
  document.removeEventListener('keydown', shortKeyCommand, true);
  document.removeEventListener('mousemove', activityCommand);
});

onMounted(() => {
  activity();
  document.addEventListener('mousemove', activityCommand);
});
</script>

<template>
  <router-view />
</template>
