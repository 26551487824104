export enum SocketRoutes {
  ENTER = 'creatives/enter',
  LEAVE = 'creatives/leave',
  EXPORT_CANVAS = 'creatives/exports/canvas',
  EXPORT_CANVAS_CANCEL = 'creatives/exports/canvas/cancel',
}

export interface ISocketSendMessage {
  route: SocketRoutes,
  creativeId: string;
}
