import icon, { iconsMap } from './Icon';
import button from './Button';

export default markRaw({
  name: 'cmpSection',

  props: {
    textColor: String,
    statusButton: {
      type: Boolean,
      default: false,
    },
    statusIcon: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value),
    },
    actionIcon: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value),
    },
  },

  emits: ['statusClick', 'actionClick'],
  setup(props, { slots, emit }) {
    const sectionClasses = computed(() => {
      const classString = 'cmp-section';
      return classString;
    });

    const textClasses = computed(() => {
      let classString = 'truncate';
      if (props.textColor) {
        classString += ` text-${props.textColor}`;
      }

      return classString;
    });

    const statusComponent = computed(() => (props.statusButton === false
      ? h(icon, { class: 'm-1', name: props.statusIcon })
      : h(button, {
        flat: true,
        dense: true,
        onClick: () => emit('statusClick'),
      }, () => h(icon, { name: props.statusIcon }))));

    const actionComponent = computed(() => {
      if (!props.actionIcon) return null;
      return h(button, {
        class: 'ml-auto',
        flat: true,
        dense: true,
        onClick: () => emit('actionClick'),
      }, () => h(icon, { name: props.actionIcon }));
    });

    return () => h('div', {
      class: sectionClasses.value,
    }, [
      h('span', {
        class: textClasses.value,
      }, slots.default()),

      statusComponent.value,

      actionComponent.value,
    ]);
  },
});
