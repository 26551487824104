import { nanoid } from 'nanoid';
import { defineStore } from 'pinia';

interface NamedCommand<T extends any[]> {
  execute(...args: T): void;
  undo(): void;
}

export class Command<A extends any[], U extends any[] = A> {
  private undoValue: U;

  private executeValue: A;

  constructor(args: A) {
    this.executeValue = args;
  }

  getUndoValue() {
    return this.undoValue;
  }

  getExecuteValue() {
    return this.executeValue;
  }

  setUndoValue(...value: U) {
    this.undoValue = value;
  }

  setExecuteValue(...value: A) {
    this.executeValue = value;
  }

  execute(...args: A): void {}

  undo(...args: U): void {}
}

interface Commands<T extends any[]> {
  command: Command<T> | string;
  id?: string;
  cmd: 'undo' | 'execute';
  args?: T,
}

let commands: Record<string, NamedCommand<any>> = {};
let list: Array<Command<any> | string> = [];
let queue: Commands<any>[] = [];

function sanatizeArgs(args) {
  if (typeof args === 'string' || typeof args === 'number') return args;
  return JSON.parse(JSON.stringify(toRaw(args)));
}

/**
 * TODO: Replace this store to createHistoryStore util and remove this file.
 */
export const useHistoryStore = defineStore('history', {
  state: () => ({
    max: 10,
    current: 0,
    undoCount: 0,
    results: [],
    running: false,
  }),

  getters: {
    canUndo: (state): boolean => state.current > 0,
    canRedo: (state): boolean => state.current < list.length,
    currentValue: (state) => {
      const { current } = state;

      if (current === 0) return null;

      return {
        command: list[current - 1],
        data: queue[current - 1],
      };
    },
  },

  actions: {
    /**
     * Reset the commands
     */
    resetCommands() {
      commands = {};
    },

    /**
     * Reset the store values but not the actions
     */
    reset() {
      list = [];
      queue = [];

      this.$patch({
        current: 0,
        undoCount: 0,
        results: [],
        running: false,
      });
    },

    /**
     * Add commands to use for undo/redo
     * inspiration http://blog.danielherzog.es/2017-01-04-javascript-command-pattern/
     * && https://github.com/yassilah/pinia-plugin-history/blob/main/src/index.ts
     * @param name => the command name
     * @param command => the commands should have an undo/redo function
     */
    addCommand(name: string, command: NamedCommand<any>) {
      if (commands[name]) return;
      commands[name] = command;
    },

    /**
     * Execute a given command
     * @param name => the command name
     * @param args => all other arguments to pass into the command
     */
    async execute<A extends any[]>(
      command: (new (args: A) => any) | string,
      ...args: A
    ) {
      const { current, max } = this;
      // remove any items after the current index
      list = list.slice(0, current);

      // if we hit the max amount of history remove the last action
      if (list.length >= max) {
        list.splice(0, 1);
        this.current -= 1;
      }

      // if there are more results than max start removing some
      if (this.results.length >= max) {
        this.results.splice(0, 1);
      }

      const sanatizedArgs = sanatizeArgs(args) as A;
      // eslint-disable-next-line new-cap
      const call: Command<A> | string = typeof command !== 'string' ? new command(sanatizedArgs) : command;

      // add the command and args to the queue
      const id = nanoid(5);
      const options: Commands<A> = {
        command: call,
        args: sanatizedArgs,
        cmd: 'execute',
        id,
      };

      queue.push(options);
      if (!this.running) void this.flush();
      return id;
    },

    async runNamedCommand(
      command: string,
      cmd: 'undo' | 'execute',
      args: any,
    ) {
      if (typeof command !== 'string') return null;
      return commands[command]?.[cmd](...(args || []));
    },

    async runCommand(
      command: Command<any>,
      cmd: 'undo' | 'execute',
    ) {
      if (typeof command === 'string') return null;

      if (cmd === 'undo') {
        return command.undo(...command.getUndoValue());
      }

      if (cmd === 'execute') {
        return command.execute(...command.getExecuteValue());
      }

      return null;
    },

    async flush() {
      if (queue.length === 0) {
        this.running = false;
        return;
      }

      this.running = true;
      const entry = queue.shift();

      try {
        let promise: any;
        let resultsIndex: number;

        if (typeof entry.command === 'string') {
          promise = this.runNamedCommand(entry.command, entry.cmd, entry.args);
        } else {
          promise = this.runCommand(entry.command, entry.cmd);
        }

        if (entry.id) {
          resultsIndex = this.results.length;
          this.results.push({ id: entry.id, result: promise });
        }

        const response = await promise;

        // decrease the current count and undo count when completed
        // this keeps the undo and redo actions in sync with the list
        if (entry.cmd === 'undo') {
          this.current -= 1;
          this.undoCount -= 1;
        }

        if (entry.cmd === 'execute') {
          this.current += 1;
        }

        // only add the command name to the list if this is the first trigger
        // only happens when args are provided
        if (entry.args && typeof entry.command === 'string') {
          list.push(entry.command);
        }

        if (typeof entry.command !== 'string' && list.indexOf(entry.command) === -1) {
          list.push(entry.command);
        }

        if (typeof resultsIndex === 'number') {
          this.results[resultsIndex].result = response;
        }
      } catch (err) {
        console.error(err);
      }

      // just wait for next vue update if there is any store updates
      await nextTick();

      void this.flush();
    },

    /**
     * Redo the last undone command
     */
    async redo() {
      return this.stackMethod('redo');
    },

    /**
     * Undo the last command
     */
    async undo() {
      return this.stackMethod('undo');
    },

    /**
     * Undo/redo stack method
     * @param method => undo or redo string
     */
    async stackMethod(method: 'undo' | 'redo') {
      const isUndo = (method === 'undo');
      const { current } = this;
      const can = isUndo ? (current > 0) : (current < list.length);

      if (!can) return;

      // increase undo count to keep in sync with the list items
      const cmd = isUndo ? 'undo' : 'execute';
      if (cmd === 'undo') this.undoCount += 1;

      // Move back, and undo the previous command.
      const command = list[this.current - this.undoCount];
      if (!command) return;

      queue.push({ command, cmd });
      if (!this.running) void this.flush();
    },
  },
});

export default useHistoryStore;
