import { Orientation } from '@/common/enums';

export const getArtBoardSize = (ori : Orientation) => {
  switch (ori) {
    case Orientation.LANDSCAPE:
      return {
        width: 200,
        height: 100,
      };
    case Orientation.PORTRAIT:
    default:
      return {
        width: 100,
        height: 200,
      };
  }
};

export default getArtBoardSize;
