import { CreativeGetOptions, AssetGetOptions, GetOptions } from '@/views/creatives/interfaces';

export function createQueryParams(args: CreativeGetOptions | AssetGetOptions) {
  const params = [];

  if (args.name) {
    params.push(`q=name:contains:${encodeURIComponent(args.name)}`);
  }

  if (args.companies) {
    params.push(`companies=${args.companies}`);
  }

  if ('folderId' in args && args.folderId) {
    params.push(`folderId=${args.folderId}`);
  }

  if ('parentId' in args) {
    params.push(`parentId=${args.parentId}`);
  }

  if (args.order && args.sortBy) {
    params.push(`order=${args.sortBy}:${args.order}`);
  }

  if (args.limit) {
    params.push(`limit=${args.limit}`);
  }

  if (args.page) {
    params.push(`page=${args.page}`);
  }

  if ('deleted' in args) {
    params.push(`deleted=${args.deleted}`);
  }

  if ('archived' in args) {
    params.push(`archived=${args.archived}`);
  }

  if ('type' in args && args.type) {
    params.push(`q=type:contains:${encodeURIComponent(args.type)}`);
  }

  return params;
}

export const queryParms = {
  companies: '',
  name: '',
  page: 1,
  limit: 50,
  sortBy: 'updatedAt',
  order: 'desc',
} as GetOptions;

export const creativeQueryParms = {
  ...queryParms,
  folderId: '',
  favorites: false,
} as CreativeGetOptions;

export const assetQueryParms = {
  ...queryParms,
  parentId: '',
  type: '',
} as AssetGetOptions;
