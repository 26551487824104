export default validateSelectedPaths;

function getType(path: string): string {
  const isMaster = /^masters\[M_\d+\]$/.test(path);
  const isFrame = /^masters\[M_\d+\]\.frames\[F_\w+\]$/.test(path);
  const isInstance = /^masters\[M_\d+\]\.frames\[F_\w+\]\.instances\[I_\w+\]$/.test(path);

  if (isMaster) return 'master';
  if (isFrame) return 'frame';
  if (isInstance) return 'instance';
  return null;
}

function getSelectionTypes(paths: string[]): Set<string> {
  return new Set(paths.map(getType));
}

function handleMasterSelection(currentSelectionTypes: Set<string>, newSelectionTypes: Set<string>, newSelection: string[]): string[] {
  if (currentSelectionTypes.has('master') && newSelectionTypes.has('master')) {
    return newSelection;
  }
  if (currentSelectionTypes.has('master') && (newSelectionTypes.has('frame') || newSelectionTypes.has('instance'))) {
    return newSelection;
  }
  return null;
}

function handleLayerSelection(currentSelectionTypes: Set<string>, newSelectionTypes: Set<string>, validatedPaths: string[], newSelection: string[]): string[] {
  if ((currentSelectionTypes.has('frame') || currentSelectionTypes.has('instance')) && newSelectionTypes.has('master')) {
    return validatedPaths;
  }
  if (currentSelectionTypes.has('instance') && newSelectionTypes.has('frame')) {
    return newSelection;
  }
  if (currentSelectionTypes.has('frame') && newSelectionTypes.has('instance')) {
    return newSelection;
  }
  return null;
}

export function validateSelectedPaths(selectedPaths: string[], newSelection: string[], ctrlPressed: boolean): string[] {
  let validatedPaths = selectedPaths;
  const currentSelectionTypes = getSelectionTypes(selectedPaths);
  const newSelectionTypes = getSelectionTypes(newSelection);

  const masterSelection = handleMasterSelection(currentSelectionTypes, newSelectionTypes, newSelection);
  if (masterSelection) return masterSelection;

  const layerSelection = handleLayerSelection(currentSelectionTypes, newSelectionTypes, validatedPaths, newSelection);
  if (layerSelection) return layerSelection;

  if (validatedPaths.includes('')) {
    validatedPaths = selectedPaths.filter((path) => path !== '');
  }

  if (ctrlPressed) {
    return validatedPaths.includes(newSelection[0])
      ? validatedPaths.filter((path) => path !== newSelection[0])
      : [...validatedPaths, ...newSelection];
  }

  return [...validatedPaths, ...newSelection];
}
