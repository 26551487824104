import { mdiAppleKeyboardCommand, mdiAppleKeyboardOption } from '@mdi/js';

/**
 * Function that returns the user's device type
 * string representing the device type.
 */
export function getDeviceType(): string {
  return navigator.platform;
}

/**
 * Function that returns the icon or string for the command key based on user's device
 * string representing the icon
 */
export function commandKey(): { type: string; value: string; } {
  const obj = { type: null, value: null };
  if (getDeviceType() === 'MacIntel') {
    obj.type = 'icon';
    obj.value = mdiAppleKeyboardCommand;
  } else {
    obj.type = 'string';
    obj.value = 'CTRL';
  }

  return obj;
}

/**
 * Function that returns the icon for the command key based on user's device
 * string representing the icon
 */
export function altKey(): { type: string; value: string; } {
  const obj = { type: null, value: null };
  if (getDeviceType() === 'MacIntel') {
    obj.type = 'icon';
    obj.value = mdiAppleKeyboardOption;
  } else {
    obj.type = 'string';
    obj.value = 'ALT';
  }

  return obj;
}
