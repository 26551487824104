export const margins = {
  sm: 6,
  md: 12,
  lg: 30,
};

export default markRaw({
  name: 'cmpSpacer',

  props: {
    // if true then the spacer will have a visable divider
    divider: {
      type: [Boolean, String],
      default: null,
    },
    spaced: {
      type: [Boolean, String],
      default: true,
    },
    inset: {
      type: [Boolean, String],
      default: null,
    },
    top: {
      type: [Boolean, String],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const classes = computed(() => {
      let classString = 'cmp-spacer';
      if (props.inset === true || props.inset === 'true') {
        classString += ' cmp-spacer-inset';
      }
      if (props.divider === true || props.divider === 'true') {
        classString += ' cmp-spacer-divider';
      }
      return classString;
    });

    const style = computed(() => {
      // we can assign css properties directly to the object
      const acc = {} as Record<string, string>;

      if (props.size !== null) {
        acc.height = props.size;
      }
      if (props.spaced !== false) {
        const size = (() => {
          // if spaced is true then use the default margin
          if (props.spaced === true) {
            return `${margins.md}`;
            // if spaced is a string then use the value from the margins object
          } if (props.spaced in margins) {
            return `${margins[props.spaced]}`;
          }
          // if spaced is a number then use that number
          return props.spaced;
        })();

        acc.marginTop = `${size}px`;
        acc.marginBottom = `${size}px`;

        // if top is set then only apply margin bottom and double it
        if (props.top === true || props.top === 'true') {
          acc.marginTop = '0';
          acc.marginBottom = `${size * 2}px`;
        }
      }
      // set color to hex value
      if (props.color) {
        acc.borderColor = props.color;
      }
      return acc;
    });

    return () => h('hr', {
      class: classes.value,
      style: style.value,
      'aria-orientation': 'horizontal',
    });
  },
});
