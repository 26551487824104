// TODO(2024-06-18): Move to cmp-common
export const deepClone = <T>(obj: T): T => {
  if (obj == null || typeof obj !== 'object') return obj;

  const clone = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    clone[key] = (typeof value === 'object') ? deepClone(value) : value;
  });

  return clone as T;
};

export default deepClone;
