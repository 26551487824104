/**
 * Function that takes care of adding a leading zero if
 * the month or day only contain a single digit
 * @param num => Number: Ex. 2
 * @returns => Formated number: Ex. '02'
 */
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

/**
 * Function that receive a date value and change with
 * Craftsman+ date format.
 * @param value => Date to convert
 */
export function getFormatDate(value: Date | number | string, fullFormat?: boolean): string {
  if (!value) return 'N/A';

  const date = new Date(value);

  if (fullFormat) {
    return Intl.DateTimeFormat('en-US', { timeStyle: 'medium', dateStyle: 'short' }).format(date);
  }

  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('/');
}

export default getFormatDate;
