import { defineStore } from 'pinia';

/**
 * This store is used to enable/disable features in the app.
 * It is used to test new features before they are released.
 * To enable a feature, add its ID to the `featureNames` array.
 * To enable a feature for a specific route, add `?feature=featureId` to the URL. E.g. ?feature=responsivity
 * And for multiple features, add `?feature=featureId1&feature=featureId2` to the URL.
 * To use the feature in your code please see the following example
 *  const featureFlags = useFeatureFlagsStore();
 *  const { responsivity } = featureFlags;
 *
 * For debugging purposes, please check featureFlagGuard in src/common/router/index.ts
 */

const featureNames = [
  // Add/remove feature flag IDs here:
  'responsivity',
  'debugPrint',
  'textValidation',
  'bulkMode',
  'pin',
  'multiInteracts',
] as const satisfies readonly string[];

type FeatureName = typeof featureNames[number];

type FeatureFlags = Record<FeatureName, boolean>;

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state(): FeatureFlags {
    const featureFlags = Object.fromEntries(featureNames.map((name) => ([name, false]))) as FeatureFlags;

    return featureFlags;
  },
  getters: {
    getFeatureNames() {
      return featureNames;
    },
    getActiveFeatureQuery() {
      let queryString = '';

      featureNames.forEach((name) => {
        if (this[name] && !queryString.length) {
          queryString += name;
        } else if (this[name]) {
          queryString += `&feature=${name}`;
        }
      });

      return queryString;
    },
  },
});

export default useFeatureFlagsStore;
