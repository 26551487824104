import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { buildWebStorage, setupCache, CacheAxiosResponse, CachedStorageValue } from 'axios-cache-interceptor';
import { gzip } from 'pako';

const myStorage = buildWebStorage(sessionStorage, 'axios-cache:');

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

export const api = setupCache(
  axios.create({
    baseURL: import.meta.env.VITE_API_URI,
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': import.meta.env.VITE_API_KEY,
    },
    transformRequest: [function content(data, headers) {
      if (!data) return data;

      // only convert JSON data not buffers or strings
      const string = typeof data === 'object' && !(data instanceof ArrayBuffer) ? JSON.stringify(data) : data;

      // compress strings if over 1KB
      if (data.length > 1024) {
        headers['Content-Encoding'] = 'gzip';
        return gzip(string);
      }

      return string;
    }],
  }),
  {
    headerInterpreter: () => 'dont cache', // !TODO fix this to cache correctly
    cacheTakeover: false,
    storage: myStorage,
    debug: import.meta.env.VITE_NODE_ENV !== 'production' ? console.log : undefined,
  },
);

/**
 * Clear cache handling
 * Custom logic to clear axios cache adaptor without caching update requests
 * https://axios-cache-interceptor.js.org/#/pages/invalidating-cache
 * @param {CacheAxiosResponse}response
 */
async function updateCache(response: CacheAxiosResponse) {
  if (!response.config.cache) return;

  const promises = Object.entries(response.config.cache.update).map(async ([key, updater]) => {
    if (updater === 'delete') return api.storage.remove(key);
    const cache = await api.storage.get(key);
    if (!cache || cache.state === 'loading') return Promise.resolve();
    const newValue: 'delete' | CachedStorageValue | 'ignore' = await updater(cache, response);
    if (typeof newValue === 'string') return Promise.resolve();
    return api.storage.set(key, newValue);
  });

  await Promise.all(promises);
}

/**
 * Install plugin
 * @usage
 * // Usage inside a setup script
 * const $api = inject('$api');
 * console.log($api);
 * @param app
 * @param {axios|Record<string:axios>}options
 */
function plugin(app) {
  if (app.vueAxiosInstalled) {
    return;
  }

  app.config.globalProperties.$axios = axios;
  app.provide('$axios', axios);
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api;
  app.provide('$api', api);
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API

  app.vueAxiosInstalled = true;
}

const responseBody = (response: AxiosResponse) => response?.data;

export const requests = {
  get: (url: string, args = {}) => api.get(url, args).then(responseBody),
  post: (url: string, body?: object | string, args = {}) => api.post(url, body, args).then(responseBody),
  put: (url: string, body?: object, args = {}) => api.put(url, body, args).then(responseBody),
  patch: (url: string, body: object, args = {}) => api.patch(url, body, args).then(responseBody),
  delete: (url: string, args = {}) => api.delete(url, args).then(responseBody),
};

api.interceptors.response.use(
  async (response: CacheAxiosResponse) => {
    await updateCache(response);
    return response;
  },
);

export default plugin;
