import { createApp } from 'vue';
import { createHead } from '@vueuse/head';
import { createPinia } from 'pinia';
import VueVirtualScroller from 'vue-virtual-scroller';
import { commandKey, altKey } from '@/common/utils';
import 'quasar/src/css/index.sass';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import App from './App.vue';
import router from './common/router';
import { i18n } from './common/plugins';
import axios from './common/plugins/axios';
import { Quasar, QuasarOptions } from './common/plugins/quasar';
import { sentry } from './common/plugins/sentry';
import loadCMPComponents from './common/plugins/cmpComponents';

const { VITE_MOCK_API } = import.meta.env;

async function enableMocking() {
  if (VITE_MOCK_API !== 'true') return Promise.resolve();

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({ onUnhandledRequest: 'bypass' });
}

enableMocking().then(() => {
  const app = createApp(App);

  sentry(app, router);

  loadCMPComponents(app);

  app.provide('$commandKey', commandKey());
  app.provide('$altKey', altKey());
  app.provide('$preloadedVideos', []);
  app.provide('$preloadedImages', new Map());
  app.provide('$ticker', new Map());

  app.use(createHead());
  app.use(createPinia());
  app.use(router);
  app.use(i18n);
  app.use(axios);
  app.use(Quasar, QuasarOptions);
  app.use(VueVirtualScroller);

  app.mount('#app');
}).catch(console.warn);
