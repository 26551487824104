import { RouteRecordRaw } from 'vue-router';
import { useCompanyStore, useCreativeStore } from '@/common/store';

const creativeRoutes: RouteRecordRaw[] = [
  {
    path: 'creatives',
    name: 'CreativesView',
    redirect: { name: 'CompaniesListView' },
    meta: { menu: 'creative' },
    component: () => import('@/views/creatives/CreativesView.vue'),
    beforeEnter: async () => {
      const companyStore = useCompanyStore();
      await companyStore.checkCompanyId();
    },
    children: [
      {
        path: '',
        name: 'CreativesListView',
        meta: { menu: 'default' },
        component: () => import('@/views/creatives/CreativesListView.vue'),
      },
      {
        path: 'templates',
        name: 'CreativeTemplatesView',
        meta: { menu: 'default' },
        component: () => import(/* webpackChunkName: "CreativeTemplatesView" */ '@/views/creatives/CreativeTemplatesView.vue'),
      },
      {
        path: 'favorites',
        name: 'CreativesFavoritesView',
        meta: { menu: 'default' },
        component: () => import('@/views/creatives/CreativesFavoritedView.vue'),
      },
      {
        path: 'archived',
        name: 'CreativesArchivedView',
        meta: { menu: 'default' },
        component: () => import('@/views/creatives/CreativesArchivedView.vue'),
      },
      {
        path: 'deleted',
        name: 'CreativesDeletedView',
        meta: { menu: 'default' },
        component: () => import('@/views/creatives/CreativesDeletedView.vue'),
      },
      {
        path: 'play/:id',
        name: 'PlayEditView',
        props: true,
        meta: { hideSidebar: true },
        component: () => import('@/views/creatives/PlayView.vue'),
        beforeEnter: () => {
          const creativeStore = useCreativeStore();
          creativeStore.historyMode = false;
        },
      },
      {
        path: 'play/:id/history/:historyId',
        name: 'PlayHistoryView',
        props: true,
        meta: { hideSidebar: true },
        component: () => import(/* webpackChunkName: "PlayView" */ '@/views/creatives/PlayView.vue'),
        beforeEnter: () => {
          const creativeStore = useCreativeStore();
          creativeStore.historyMode = true;
        },
      },
      {
        path: 'canvas/:id',
        name: 'CanvasEditView',
        props: true,
        meta: { hideSidebar: true },
        component: () => import('@/views/creatives/CanvasView.vue'),
      },
      {
        path: 'folder/:id',
        redirect: { name: 'CreativeListInFolderView' },
      },
      {
        path: 'folders/:id',
        name: 'CreativeListInFolderView',
        meta: { menu: 'default' },
        component: () => import('@/views/creatives/CreativesListView.vue'),
      },
      {
        path: 'templated/:id',
        name: 'CreativeV1View',
        meta: { hideSidebar: true },
        component: () => import('@/views/creatives/CreativeV1Editor.vue'),
      },
    ],
  },
];

export default creativeRoutes;
