import { RouteRecordRaw } from 'vue-router';
import companyRoutes from '@/views/companies/routes';
import creativeRoutes from '@/views/creatives/routes';
import adminRoutes from '@/views/admin/routes';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'MainView' },
  },
  {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'sign-in' },
    component: () => import('@/views/auth/AuthLayout.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'SignInView',
        component: () => import('@/views/auth/SigninView.vue'),
      },
      {
        path: 'forgotten-password',
        name: 'ForgottenPasswordView',
        component: () => import('@/views/auth/ForgottenPasswordView.vue'),
      },
      {
        path: 'reset-password',
        name: 'ResetPasswordView',
        component: () => import('@/views/auth/ResetPasswordView.vue'),
      },
    ],
  },
  {
    path: '/main',
    name: 'MainView',
    component: () => import('@/common/layouts/DashboardLayout.vue'),
    meta: { protected: true },
    children: [
      {
        path: 'profile/:id',
        name: 'UserProfileView',
        meta: { menu: 'default' },
        component: () => import('@/views/users/CurrentUserView.vue'),
      },
      ...companyRoutes,
      ...creativeRoutes,
      ...adminRoutes,
    ],
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/common/pages/ErrorView404.vue'),
  },
];

export default routes;
