export interface IShortKey {
  id?: string; // short key id will only be added when bind key to help tracking different short key events
  keys: string[];
  callback: () => void;
}

export const createShortcutIndex = (pKey: KeyboardEvent) => {
  let k = '';
  if (pKey.key === 'Shift' || pKey.shiftKey) { k += 'shift'; }
  if (pKey.key === 'Control' || pKey.ctrlKey) { k += 'ctrl'; }
  if (pKey.key === 'Meta' || pKey.metaKey) { k += 'meta'; }
  if (pKey.key === 'Alt' || pKey.altKey) { k += 'alt'; }
  if (pKey.key === 'ArrowUp') { k += 'arrowup'; }
  if (pKey.key === 'ArrowLeft') { k += 'arrowleft'; }
  if (pKey.key === 'ArrowRight') { k += 'arrowright'; }
  if (pKey.key === 'ArrowDown') { k += 'arrowdown'; }
  if (pKey.key === 'AltGraph') { k += 'altgraph'; }
  if (pKey.key === 'Escape') { k += 'esc'; }
  if (pKey.key === 'Enter') { k += 'enter'; }
  if (pKey.key === 'Tab') { k += 'tab'; }
  if (pKey.key === ' ') { k += 'space'; }
  if (pKey.key === 'PageUp') { k += 'pageup'; }
  if (pKey.key === 'PageDown') { k += 'pagedown'; }
  if (pKey.key === 'Home') { k += 'home'; }
  if (pKey.key === 'End') { k += 'end'; }
  if (pKey.key === 'Delete') { k += 'del'; }
  if (pKey.key === 'Backspace') { k += 'backspace'; }
  if (pKey.key === 'Insert') { k += 'insert'; }
  if (pKey.key === 'NumLock') { k += 'numlock'; }
  if (pKey.key === 'CapsLock') { k += 'capslock'; }
  if (pKey.key === 'Pause') { k += 'pause'; }
  if (pKey.key === 'ContextMenu') { k += 'contextmenu'; }
  if (pKey.key === 'ScrollLock') { k += 'scrolllock'; }
  if (pKey.key === 'BrowserHome') { k += 'browserhome'; }
  if (pKey.key === 'MediaSelect') { k += 'mediaselect'; }
  if (pKey.key === '=') { k += '='; }
  if (pKey.key === '-') { k += '-'; }
  if (pKey.key === '+') { k += '+'; }
  if (pKey.key === '0' || pKey.code === 'Digit0' || pKey.code === 'Numpad0') { k += '0'; }
  if (pKey.key === '1' || pKey.code === 'Digit1' || pKey.code === 'Numpad1') { k += '1'; }
  if (pKey.key === '2' || pKey.code === 'Digit2' || pKey.code === 'Numpad2') { k += '2'; }
  if (pKey.key === '3' || pKey.code === 'Digit3' || pKey.code === 'Numpad3') { k += '3'; }
  if (pKey.key === '4' || pKey.code === 'Digit4' || pKey.code === 'Numpad4') { k += '4'; }
  if (pKey.key === '5' || pKey.code === 'Digit5' || pKey.code === 'Numpad5') { k += '5'; }
  if (pKey.key === '6' || pKey.code === 'Digit6' || pKey.code === 'Numpad6') { k += '6'; }
  if (pKey.key === '7' || pKey.code === 'Digit7' || pKey.code === 'Numpad7') { k += '7'; }
  if (pKey.key === '8' || pKey.code === 'Digit8' || pKey.code === 'Numpad8') { k += '8'; }
  if (pKey.key === '9' || pKey.code === 'Digit9' || pKey.code === 'Numpad9') { k += '9'; }
  // if (pKey.key === '[') { k += '['; }
  // if (pKey.key === ']') { k += ']'; }
  if (pKey.code === 'BracketLeft') { k += '['; }
  if (pKey.code === 'BracketRight') { k += ']'; }
  if ((pKey.code && pKey.code !== ' ') && /^Key/.test(pKey.code)) k += pKey.code.replace(/Key/, '').toLowerCase();
  return k;
};
