import { defineStore } from 'pinia';

interface ICommand {
  execute: ((...args: any[]) => void) | any;
  undo: (() => void) | any;
}
// TODO: https://pinia.vuejs.org/cookbook/composables.html#setup-stores
// Try to combine logic in history and appHistory stores with composables
export const useAppHistoryStore = defineStore('appHistory', {
  state: () => ({
    max: 10,
    commands: {},
    list: [],
    current: 0,
    queue: [],
  }),

  getters: {
    canUndo: (state): boolean => state.current > 0,
    canRedo: (state): boolean => state.current < state.list.length,
    currentValue: (state) => {
      const { list, current, queue } = state;

      if (current === 0) return null;

      return {
        command: list[current - 1],
        data: queue[current - 1],
      };
    },
  },

  actions: {
    /**
     * Reset the store values but not the actions
     */
    reset() {
      this.$patch({
        list: [],
        current: 0,
        queue: [],
      });
    },

    /**
     * Add commands to use for undo/redo
     * inspiration http://blog.danielherzog.es/2017-01-04-javascript-command-pattern/
     * && https://github.com/yassilah/pinia-plugin-history/blob/main/src/index.ts
     * @param name => the command name
     * @param command => the commands should have an undo/redo function
     */
    addCommand(name: string, command: ICommand) {
      if (this.commands[name]) return;
      this.commands[name] = command;
    },

    /**
     * Execute a given command
     * @param name => the command name
     * @param args => all other arguments to pass into the command
     */
    async execute(name: string, ...args) {
      const { commands, list, current, max } = this;
      // remove any items after the current index
      this.list = list.slice(0, current);
      // if we hit the max amount of history remove the last action
      if (list.length >= max) {
        this.list.splice(0, 1);
        this.current -= 1;
      }
      // wait for the last promise to resolve
      const queueLength = this.queue.length;
      await this.queue.at(-1);
      this.queue.splice(queueLength, 1);
      // Exec command
      const response = await commands[name]?.execute(...args);
      this.list.push(name);
      this.current += 1;
      // add response to the queue to stop any race conditions
      this.queue.push(response);
      // Return promise response
      return response;
    },

    /**
     * Redo the last undone command
     */
    async redo() {
      return this.stackMethod('redo');
    },

    /**
     * Undo the last command
     */
    async undo() {
      return this.stackMethod('undo');
    },

    /**
     * Undo/redo stack method
     * @param method => undo or redo string
     */
    async stackMethod(method: 'undo' | 'redo') {
      const queueLength = this.queue.length;
      await this.queue.at(-1);
      this.queue.splice(queueLength, 1);

      const isUndo = (method === 'undo');
      const { commands, list, current } = this;
      const can = isUndo ? (current > 0) : (current < list.length);

      if (!can) {
        return 'ok';
      }

      try {
        // wait for the last promise to resolve
        const cmd = isUndo ? 'undo' : 'execute';

        if (method === 'undo') this.current -= 1;
        // Move back, and undo the previous command.
        const name = this.list[this.current];
        const response = await commands[name][cmd]();
        if (method === 'redo') this.current += 1;
        return response;
      } catch (err) {
        // increase the current index as this undo failed
        if (method === 'undo') this.current += 1;
        throw err;
      }
    },

    removeHistoryItems(command: string) {
      if (!(command in this.commands)) return;

      const queueIndexes = [];
      // Remove in commands
      this.list = this.list.filter((c, i) => {
        if (c === command) queueIndexes.push(i);
        return c !== command;
      });
      // Remove in queue
      this.queue = this.queue.filter((v, i) => !queueIndexes.includes(i));
      // Update current
      this.current -= queueIndexes.length;
    },
  },
});

export default useAppHistoryStore;
