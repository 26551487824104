import { defineStore } from 'pinia';
import { filterByKeys, updateAxiosCache } from '@/common/utils';
import { requests } from '@/common/plugins/axios';
import { CompanyUser } from '@/views/companies/interfaces';
import { useCompanyStore } from './company';

export const useCompanyUserStore = defineStore('companyUser', {
  state: () => ({
    currentCompanyUsers: [] as CompanyUser[],
    deletedUsers: [],
    selectedCompanyId: undefined,
    initialUserFormData: '',
    currentCompanyUserId: '',
    selectedCompanyUser: {
      email: '',
      firstName: '',
      lastName: '',
      licenses: [],
      groups: [],
    } as Partial<CompanyUser>,
  }),

  getters: {
    currentCompanyUsersFiltered: (state): CompanyUser[] => {
      if (state.deletedUsers.length > 0) {
        return state.currentCompanyUsers.filter((user) => state.deletedUsers.some((item) => item !== user.id));
      }

      return state.currentCompanyUsers;
    },
  },

  actions: {
    /**
     * Make a GET request to get user list of specific company
     * and set them to currentCompanyUsers state variable.
     * @param id => Company Id
     */
    async fetchCompanyUsers(id: string, name?: string): Promise<void> {
      this.selectedCompanyId = id;
      const result = await requests.get(`/companies/${id}/users?includes=licenses&includes=groups&includes=roles`, {
        id: `fetch-company-users-${id}`,
      });

      if (name?.length > 0) {
        this.currentCompanyUsers = filterByKeys(result.items, ['firstName', 'lastName', 'email'], name);
      } else {
        this.currentCompanyUsers = result.items;
      }
    },

    /**
     * Convert Partial<CompanyUser> in string and set
     * this string to 'initialUserFormData' state variable.
     * @param newUser => Data to convert
     */
    setInitialFormData(newUser: Partial<CompanyUser>) {
      this.initialUserFormData = JSON.stringify({ ...newUser });
    },

    /**
     * Set userId to 'currentCompanyUserId', find its data
     * and assign it to 'selectedCompanyUser' to update user info.
     * @param user => User to
     */
    selectCompanyUser(user: CompanyUser) {
      this.currentCompanyUserId = user.id;
      // Update licenses in selected user
      this.selectedCompanyUser = {
        ...user,
        licenses: user.licenses.map((license) => (license.isEnabled ? license.id : null)),
        groups: user.groups.map((group) => group.id),
      };
      // Set initial data
      this.setInitialFormData(this.selectedCompanyUser);
    },

    /**
     * Submit company user form sending 'selectedCompanyUser'
     * data to create or update a company user.
     */
    async submitCompanyUser(): Promise<void> {
      const user = this.selectedCompanyUser;
      const companyStore = useCompanyStore();

      if (this.currentCompanyUserId.length === 0) {
        const newUser = await requests.post(
          '/users?includes=licenses&includes=groups&includes=roles',
          { ...user },
          {
            cache: {
              update: {
                [`fetch-company-users-${this.selectedCompanyId}`]: (cache, response) => updateAxiosCache(cache, response, 'items', response.data.id),
              },
            },
          },
        );

        this.currentCompanyUsers.push(newUser);
        this.currentCompanyUserId = newUser.id;
      } else {
        // Make update
        const updatedUser = await requests.patch(
          `/users/${user.id}?includes=licenses&includes=groups&includes=roles`,
          { ...user },
          {
            cache: {
              update: {
                [`fetch-company-groups-${companyStore.currentCompanyId}`]: 'delete',
                [`fetch-company-users-${this.selectedCompanyId}`]: (cache, response) => updateAxiosCache(cache, response, 'items', user.id),
              },
            },
          },
        );

        const userIndex = this.currentCompanyUsers.findIndex((companyUser) => companyUser.id === user.id);
        this.currentCompanyUsers[userIndex] = updatedUser;
      }
    },

    /**
     * Reset 'selectedCompanyUser' values for initial
     * object data structure and set 'currentCompanyUserId'
     * value to empty.
     */
    resetCompanyUser() {
      this.currentCompanyUserId = '';
      this.selectedCompanyUser = {
        email: '',
        firstName: '',
        lastName: '',
        licenses: [],
        groups: [],
      };
    },

    /**
     * Make a DELETE request to delete a user in a Company.
     * @param id => User id
     */
    async deleteUser(id: string) {
      await requests.delete(`/users/${id}`, {
        cache: {
          update: {
            [`fetch-company-users-${this.selectedCompanyId}`]: 'delete',
          },
        },
      });

      this.deletedUsers.push(id);
    },

    /**
     * Make a POST request to allow user to update his password.
     * @param userId => User id
     */
    async resetUserPassword(userId: string) {
      await requests.post(`/users/${userId}/reset`);
    },
  },
});

export default useCompanyUserStore;
