import { init, browserProfilingIntegration, browserTracingIntegration, replayIntegration } from '@sentry/vue';
import { Router } from 'vue-router';
import { Options, Vue } from '@sentry/vue/types/types';

class VueAppElement extends HTMLElement {
  __vue_app__ = {
    config: {
      globalProperties: {
        $pinia: {
          state: {
            value: {},
          },
        },
      },
    },
  };
}

export const sentry = (app: Vue, router: Router) => {
  const LOCAL_DOMAINS = ['localhost', '127.0.0.1'];

  const {
    VITE_NODE_ENV,
    VITE_SENTRY_DSN,
    VITE_SENTRY_RELEASE,
    VITE_MOCK_API,
    VITE_STAGE,
  } = import.meta.env;

  // do not run sentry code when running e2e tests
  if (LOCAL_DOMAINS.includes(window.location.hostname) || !VITE_SENTRY_DSN || JSON.parse(VITE_MOCK_API)) return;

  const environment = VITE_STAGE || VITE_NODE_ENV;

  const params = {
    app,
    environment,
    trackComponents: true,
    dsn: VITE_SENTRY_DSN,
    logErrors: environment !== 'production',
    integrations: [
      browserProfilingIntegration(),
      browserTracingIntegration({ router }),
      replayIntegration(),
    ],
    dom: {
      serializeAttribute: ['data-test'],
    },
    beforeSend: (event, hint) => {
      // upload the state management data before sending the error event
      const el = document.getElementById('app') as VueAppElement;
      hint.attachments = [{
        filename: 'state.json',
        contentType: 'application/json',
        data: JSON.stringify(el.__vue_app__.config.globalProperties.$pinia.state.value),
      }];

      // fire post message to show a nice message to the user
      if (event.exception && event.event_id) {
        window.postMessage({
          action: 'sentry_error',
          event,
        });
      }

      return event;
    },
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'frontend.dev.craftsmanplus.com', 'frontend.prod.craftsmanplus.com', 'frontend.staging.craftsmanplus.com'],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment !== 'production' ? 0.3 : 0.1,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: environment !== 'production' ? 0.3 : 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  } as Partial<Omit<Options, 'tracingOptions'>>;

  if (VITE_SENTRY_RELEASE) {
    params.release = VITE_SENTRY_RELEASE;
  }

  init(params);
};

export default sentry;
