import { defineStore } from 'pinia';
import { RequestError } from '../interfaces';

export const useRequestStore = defineStore('request', {
  state: () => ({
    asyncError: {} as RequestError,
  }),

  actions: {
    setError(error: RequestError) {
      this.asyncError = error;
    },
  },
});

export default useRequestStore;
