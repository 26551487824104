import { extractDataPath } from './canvasPath';

export const getLayerType = (path : string) => {
  const pathData = extractDataPath(path);
  switch (true) {
    case pathData.has('variant') && pathData.has('instances'):
      return 'instance_variant';
    case pathData.has('variant') && pathData.has('frames'):
      return 'frame_variant';
    case pathData.has('variant') && pathData.has('masters'):
      return 'master_variant';
    case pathData.has('variant'):
      return 'variant';
    case pathData.has('instances'):
      return 'instance';
    case pathData.has('frames'):
      return 'frame';
    case pathData.has('masters'):
      return 'master';
    default:
      return '';
  }
};

export default getLayerType;
