/**
   * Extracts the data path from a string with last property as null
   * @param {string} path "variant[variantId].masters[0].frames[F_TexT].instances[T_txt01]"
   * @returns {Map<string, string>} {'variant' => 'variantId', 'masters' => '0', 'frames' => 'F_TexT', 'instances' => 'T_txt01', 'align' => 'undefined'}}
   */
export function extractDataPath(path:string) {
  // masters[P_3].frames[F_Back].instances[I_asdfg].color -> masters,P_3  frames,F_Back  instances,I_asdfg  config,null  color, null
  const pattern = /(\w+)(?:\[(.*?)\])?/g;

  let match = pattern.exec(path);
  const result = new Map();
  while (match !== null) {
    result.set(match[1], match[2] || null);
    match = pattern.exec(path);
  }
  return result;
}

/**
 * GeneratePath for Canvas
 * path example : masters[0]frames[2][frameId]instance[1]
 * @date 2/10/2023 - 4:52:31 PM
 *
 * @param {string} masterId
 * @param {(string | number | undefined)} frameId
 * @param {(string | number | undefined)} instanceId
 * @returns {string}
 */
export const buildCanvasPath = (
  boardId : string,
  frameId? :string | number | undefined,
  instanceId? : string | number | undefined,
  variantId? : number | string | undefined,
) => {
  const variantPath = variantId ? `variant[${variantId}].` : '';
  const masterPath = `masters[${boardId}]`;
  const framePath = frameId ? `.frames[${frameId}]` : '';
  const instancePath = instanceId ? `.instances[${instanceId}]` : '';

  return variantPath + masterPath + framePath + instancePath;
};

export default extractDataPath;
