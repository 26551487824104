/**
 * Function that receive an entity list and a name
 * to filter in the list.
 * @param entityList => Entity array (Temporalically any type)
 * @param name => Entity name
 */
export function filterByName(entityList: any[], name: string, key = 'name'): any[] {
  return entityList.filter((item) => item[key].toLowerCase().includes(name.toLowerCase()));
}

export default filterByName;
