import { defineStore } from 'pinia';
import { requests } from '@/common/plugins/axios';
import { Product } from '@/common/interfaces';

export const useDataStore = defineStore('data', {
  state: () => ({
    products: [] as Product[],
  }),

  getters: {
    enabledProducts: (state) => state.products?.filter((n) => n.isEnabled),
  },

  actions: {
    /**
     * Fetch possible products to be licensed from API and
     * set to products store variable.
     */
    async fetchProducts(): Promise<Product[]> {
      const result = await requests.get('/types/products', {
        id: 'fetch-products',
        cache: { ttl: 1000 * 60, interpretHeader: false },
      });
      this.products = result.items;
      return result;
    },
  },
});

export default useDataStore;
