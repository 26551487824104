export enum AuthChallenges {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  CUSTOM_CHALLENGE = 'CUSTOM_CHALLENGE',
}

interface DuoAuthChallenge {
  USERNAME: string;
  redirect: string;
}

export interface AuthChallenge {
  session?: string;
  challenge?: AuthChallenges;
  redirect?: string;
}

export interface AuthResponse extends AuthChallenge {
  parameters?: DuoAuthChallenge;
  expiresIn?: number;
  refreshToken?: string;
  token?: string;
  loggedInTime?: number;
}
