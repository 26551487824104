import { nanoid } from 'nanoid';
import { defineStore } from 'pinia';
import { socket as worker } from '@/common/workers';
import { useCreativeStore } from '@/common/store/creative';
import { useEditorStore } from '@/common/store/editor';
import { SocketRoutes } from '../interfaces';

const broadcastChannel = new BroadcastChannel('WebSocketChannel');

export const useSocketStore = defineStore('socket', {
  state: () => ({
    id: nanoid(),
    webSocketState: WebSocket.CONNECTING,
    token: '',
    queue: [],
  }),

  actions: {
    connect(token: string) {
      this.token = token;

      worker.onmessage = (event) => {
        switch (event.data.type) {
          case 'WSState':
            this.webSocketState = event.data.state;
            this.processQueue();
            break;
          default:
            this.handleBroadcast(event.data);
        }
      };

      /* worker.onmessageerror = (e) => {
        console.log(e);
      };

      worker.onerror = (e) => {
        console.log(e);
      }; */

      broadcastChannel.addEventListener('message', (event) => {
        switch (event.data.type) {
          case 'WSState':
            this.webSocketState = event.data.state;
            break;
          default:
            this.handleBroadcast(event.data);
        }
      });

      worker.postMessage({
        action: 'CONNECT',
        token,
      });

      window.addEventListener('beforeunload', () => {
        worker.postMessage({ action: 'CLOSE' });
      });
    },

    handleBroadcast(data: { result: { route: string } }) {
      const { result: { route } } = data;
      switch (route) {
        case SocketRoutes.ENTER:
          this.enterCreative(data.result);
          break;
        case SocketRoutes.LEAVE:
          this.leaveCreative(data.result);
          break;
        case SocketRoutes.EXPORT_CANVAS:
          this.updateExportProgress(data.result);
          break;
        case SocketRoutes.EXPORT_CANVAS_CANCEL:
          this.cancelExport(data.result);
          break;
        default:
          break;
      }
    },

    enterCreative(result) {
      const creativeStore = useCreativeStore();
      if (result.data?.creativeId !== creativeStore.currentCreative?.id) return;
      creativeStore.users = result.list;
    },

    leaveCreative(result) {
      const creativeStore = useCreativeStore();
      if (result.data?.creativeId !== creativeStore.currentCreative?.id) return;
      creativeStore.users = result.list;
    },

    sendAllTabs(data: object) {
      this.postMessageToWSServer(data);
    },

    sendFocusedTab(data: object) {
      this.postMessageToWSServer({
        ...data,
        from: this.id,
      });
    },

    exportCanvas(data: object) {
      this.postMessageToWSServer({
        ...data,
        token: this.token,
      });
    },

    updateExportProgress(result) {
      const editorStore = useEditorStore();
      const sessionIndex = editorStore.exportSessions
        .findIndex((session) => session.session === result.session);

      // Update export session progress
      if (sessionIndex !== -1) {
        const { totalItems } = editorStore.exportSessions[sessionIndex];
        let newList = editorStore.exportSessions[sessionIndex].toProcess;
        if (result?.data?.id) {
          const processedId = result.data.id;
          // remove variant from list when done
          newList = newList.filter((item) => item !== processedId);
        }
        const payload = {
          ...result,
          toProcess: newList,
          totalItems,
        };
        editorStore.exportSessions[sessionIndex] = payload;
        return;
      }
      // Add export session to export list
      if (result?.session) {
        const { length } = editorStore.exportListToProcess;
        // add variant to list
        const payload = {
          ...result,
          toProcess: [...editorStore.exportListToProcess],
          totalItems: length,
        };
        editorStore.exportSessions.push(payload);
        editorStore.exportListToProcess = [];
      }
    },

    cancelExport(data) {
      this.postMessageToWSServer({
        ...data,
        token: this.token,
      });
    },

    processQueue() {
      if ([WebSocket.CONNECTING, WebSocket.CLOSING, WebSocket.CLOSED].includes(this.webSocketState)) return;
      const message = this.queue.shift();
      if (!message) return;
      worker.postMessage(JSON.parse(JSON.stringify(message)));
    },

    postMessageToWSServer(input: object) {
      this.queue.push(input);
      this.processQueue();
    },
  },
});

export default useSocketStore;
