import { nanoid } from 'nanoid';
import { defineStore } from 'pinia';
import { requests } from '@/common/plugins/axios';
import { TransformerField } from '@cmp/common';
import { IApp, IHighlightedNode, IRichTextConfig } from '@/views/creatives/interfaces';
import { useCompanyStore } from './company';
import { get } from '../utils';

interface IExportSession {
  creativeId: string;
  status: 'QUEUED' | 'IN_PROGRESS' | 'COMPLETED' | 'FAILED' | 'CANCELLED';
  session: string; // 'Gfv8Zj6p6bB9nsqqsV1oJ'
  route: string; // 'creatives/exports/canvas'
  data?: {
    url: string;
    id?: string; // "1984845351"
    message?:string;
    status?: string;
  };
  toProcess?: string[];
  totalItems?: number;
}

type NodeDataTransformer = {
  id: string, // unique id of selected node
  disableFields?: TransformerField[], // disabled fields for this node's transformer if applicable
};

export const useEditorStore = defineStore('editor', {
  state: () => ({
    apps: {} as { [key: string]: IApp },
    fonts: [], // list of custom fonts for company
    activeAppId: '', // current active ad (Landscape/Portrait)
    activeArtBoardId: '',
    zoomScale: {} as Record<string, number>, // current zoom scale for different apps. { [appId]: zoomScale }
    zoomType: '' as 'scroll' | 'keyStroke' | '', // type of scroll to determine where to set center
    // zoom to fit action for targeting apps. Keys are appIds, the value number will store it's zoomtofit result, -1 means failed, undefined means haven't started yet
    zoomToFit: {} as Record<string, number>,
    interactions: { // keeping track of the interactions in editor
      move: false,
      pan: false,
      zoom: false,
      resize: false,
      transform: false,
      updates: false,
    },
    pointerOnBoard: false, // a boolean representing the pointer position whether it's on artboard or not
    selectedNodes: [] as NodeDataTransformer[], // array of selected nodes info for transformer tool
    triggerTransformer: false, // trigger a reposition for the transformer
    highlightedLayer: '', // reference to the layer that is currently hovered over in Play Left drawer and Canvas editor view
    highlightedNode: {} as IHighlightedNode, // used when moving a layer with keyboard
    highlightedNodes: [] as IHighlightedNode[],
    showTransformer: true, // flag to show transformer
    layerMove: { // used when moving a layer with keyboard
      x: 0,
      y: 0,
      state: '',
      boardOrientation: '',
      forceTrigger: 0,
    },
    isMultiSelect: false, // is the user holding down the shift or ctrl key
    isCtrlPressed: false, // is the user holding down the ctrl key
    isShiftPressed: false, // is the user holding down the shift key
    transformerAspectlock: false, // transformer.aspectLock state
    transformer: { // used when updating transformer position for guidelines
      x: 0,
      y: 0,
      rotation: 0,
    },
    rulerOn: false, // boolean to show UI canvas ruler
    layers: { // total number of layers for current scene for currentCreative in creativeStore
      total: -1,
      loaded: {},
    },
    sortingData: {
      id: null,
      cleared: false,
      moving: false,
      states: {},
    },
    xAxisGuidelines: {},
    yAxisGuidelines: {},
    drawXAxisGuidelines: [] as { id: string; draw: number; color: string }[],
    drawYAxisGuidelines: [] as { id: string; draw: number; color: string }[],
    guidelineHighlightedLayers: [],
    editableId: '',
    isTextEditorOpen: false, // instance editorTextInput
    exportSessionsNotify: null,
    exportSessions: [] as IExportSession[],
    exportSettings: {
      format: 'png',
      quality: 100,
      transparency: false,
      resolution: 72,
    }, // The export settings for this creative, dpi, format and quality.
    exportListToProcess: [] as string[], // list of variants ids to export
    reloadEditor: false, // used to refresh the editor area after a big data change
    currentKeys: [],
    removeShortKeys: undefined,
    hasKeysRemoved: false,
    activeRichSelection: {} as IRichTextConfig,
    arsPendingUpdate: false, // flag whether an editor action requires us to (carefully) update the ARS
    groupsDetails: [],
    visibleDialogs: {} as { [key: string] : boolean }, // current presenting dialogs state object - [key: dialogId] : boolean
    guidesSnapThreshold: 5, // threshold for snapping to guidelines
  }),
  getters: {
    getArtboardPosition(state) {
      const id = state.activeAppId;
      if (state.apps[id] && state.apps[id].artBoard) {
        return state.apps[id].artBoard.positions;
      }
      return [];
    },

    // used to highlight intersecting nodes with guidelines
    getGuidelinesNodeIntersects(state) {
      const { drawXAxisGuidelines, drawYAxisGuidelines } = state;
      const xAxis = drawXAxisGuidelines.map((xAxisGuideline) => xAxisGuideline.id);
      const yAxis = drawYAxisGuidelines.map((yAxisGuideline) => yAxisGuideline.id);
      return [...new Set([...xAxis, ...yAxis])];
    },

    // boolean flag to determine if all layers have loaded
    isLoaded(): boolean {
      return Object.keys(this.layers.loaded).length === (this.layers.total * Object.keys(this.apps).length);
    },
  },
  actions: {
    resetRichTextFont() {
      this.activeRichSelection.letterSpacing = 'Auto';
      this.activeRichSelection.letterCase = 'normal';
    },

    async fetchFonts(): Promise<void> {
      const companyStore = useCompanyStore();
      const companyId = companyStore.currentCompanyId;
      // do we need all fonts or just the ones used in the creative?
      const response = await requests.get(`/assets/library?companies=${companyId}&q=type:contains:font`, { cache: false }).catch(() => ({}));

      // find anly elements with size 0 and log them, then remove them
      // TODO: Set up a sentry warning for if the tool notices a zero-size font
      if (response.items) {
        response.items = response.items.filter((item) => item.size !== '0');
        this.fonts = response.items;
      }
    },

    addHighlight(node: IHighlightedNode) {
      // if the node.uid is found in highlightedNodes, remove it
      // then, add the new node to the list of highlightedNodes.
      const index = this.highlightedNodes.findIndex((item) => item.uid === node.uid);
      if (index > -1) {
        this.highlightedNodes.splice(index, 1);
      }

      this.highlightedNodes.push(node);
    },

    removeHighlight(node: IHighlightedNode) {
      const index = this.highlightedNodes.findIndex((item) => item.uid === node.uid);
      if (index > -1) {
        this.highlightedNodes.splice(index, 1);
      }
    },

    removeAllHighlights() {
      this.highlightedNodes = [];
    },

    createApp() {
      const id = nanoid();

      this.apps[id] = {
        canvas: null,
      };

      return id;
    },

    removeApp(id: string) {
      if (id === this.activeAppId) this.activeAppId = '';
      delete this.apps[id];
    },

    getCoordinatesFromPosition(positions) {
      const xCoordinates = positions.map((item) => item.x); // only looking for X coordinates
      const yCoordinates = positions.map((item) => item.y); // only looking for Y coordinates

      const left = Math.min(...xCoordinates); // minimum X value closest to the left
      const right = Math.max(...xCoordinates); // maximum X value closest to the right
      const width = (right - left);

      const top = Math.min(...yCoordinates); // minimum Y value closest to the top
      const bottom = Math.max(...yCoordinates); // maximum Y value closest to the bottom
      const height = (bottom - top);
      return { left, right, width, top, bottom, height };
    },

    setArtboardGuidelines(appId) {
      if (!appId) return;
      if (!this.apps[appId] || !this.apps[appId].artBoard) return;
      const { positions } = this.apps[appId].artBoard;
      this.updateGuidelinesPosition(appId, positions);
    },

    updateGuidelinesPosition(id, positions) {
      this.setXAxisGuidelines(id, positions);
      this.setYAxisGuidelines(id, positions);
    },

    // set horizontals guidelines —
    setXAxisGuidelines(id, positions) {
      const { top, bottom } = this.getCoordinatesFromPosition(positions);
      const middle = (bottom - top) / 2 + top; // middle Y value between top and bottom

      const guidelineCoordinates = { top, middle, bottom };
      this.setGuidelines(id, 'xAxisGuidelines', guidelineCoordinates);
    },

    // set vertical guidelines |
    setYAxisGuidelines(id, positions) {
      const { left, right } = this.getCoordinatesFromPosition(positions);
      const center = (right - left) / 2 + left; // middle X value between left and right

      const guidelineCoordinates = { left, center, right };
      this.setGuidelines(id, 'yAxisGuidelines', guidelineCoordinates);
    },

    removeGuidelinebyId(id) {
      delete this.xAxisGuidelines[id];
      delete this.yAxisGuidelines[id];
    },

    getCurrentAxisGuidelines(property) {
      const axisGuidelines = { ...this[property] };

      // remove guidelines that are for the currently selected nodes
      this.selectedNodes.forEach((node) => {
        delete axisGuidelines[node.id];
      });

      // get only active app guidelines
      const guidelines = Object.keys(axisGuidelines)
        .filter((key) => key.toLowerCase().includes(this.activeAppId.toLowerCase()))
        .reduce((obj, key) => ({
          ...obj,
          [key]: axisGuidelines[key],
        }), {});
      return guidelines || {};
    },

    updateVisibleGuidelines(x, y, globalPoints) {
      const { width, height } = this.getCoordinatesFromPosition(globalPoints);

      const currentDiff = (this.guidesSnapThreshold * this.zoomScale[this.activeAppId]); // distance from other value threshold
      const xAxisGuidelines = this.getCurrentAxisGuidelines('xAxisGuidelines');
      const yAxisGuidelines = this.getCurrentAxisGuidelines('yAxisGuidelines');

      const drawXAxisGuidelines = [];
      const drawYAxisGuidelines = [];

      const halfHeight = height * 0.5;
      const objectTop = y - halfHeight;
      const objectMiddle = y;
      const objectBottom = y + halfHeight;

      const halfWidth = width * 0.5;
      const objectLeft = x - halfWidth;
      const objectCenter = x;
      const objectRight = x + halfWidth;

      // check if top middle or bottom of the object is within any guidelines
      Object.keys(xAxisGuidelines).forEach((key) => {
        const guideline = xAxisGuidelines[key];
        Object.keys(guideline).forEach((k) => {
          [objectTop, objectMiddle, objectBottom].forEach((value) => {
            const diff = Math.abs(value - guideline[k]);
            if (diff < currentDiff) {
              drawXAxisGuidelines.push({ id: key, draw: guideline[k], color: '#997BFF' });
            }
          });
        });
      });

      // check if left middle or right of the object is within any guidelines
      Object.keys(yAxisGuidelines).forEach((key) => {
        const guideline = yAxisGuidelines[key];
        Object.keys(guideline).forEach((k) => {
          [objectLeft, objectCenter, objectRight].forEach((value) => {
            const diff = Math.abs(value - guideline[k]);
            if (diff < currentDiff) {
              drawYAxisGuidelines.push({ id: key, draw: guideline[k], color: '#997BFF' });
            }
          });
        });
      });
      this.drawXAxisGuidelines = drawXAxisGuidelines;
      this.drawYAxisGuidelines = drawYAxisGuidelines;
    },

    /**
     * helper function to start a zoom-to-fit action
     * @param appIds => This is zoom-to-fit apps'id array. If not exist, will use this.apps ids
     */
    startZoomToFit(appIds? : string[]) {
      const ids = appIds ?? Object.keys(this.apps);
      ids.forEach((appId) => { this.zoomToFit[appId] = undefined; });
    },

    /**
     * helper function to set a zoom calculation result
     * @param appId => This is the appId of this result
     * @param result => This is ths result number
     */
    setZoomToFitResult(appId : string, result : number) {
      // if zoomToFit action is off, return
      if (Object.keys(this.zoomToFit).length === 0) return;

      // assign result to app
      if (Object.keys(this.zoomToFit).includes(appId)) this.zoomToFit[appId] = result;

      // set zoomScale if all apps zoomed
      if (!Object.values(this.zoomToFit).includes(undefined)) {
        // TODO: check if we hate this behavior to have viewport has different zoomtofit number
        // const minScale = Object.values(this.zoomToFit).filter((scale) => scale !== -1).sort((a, b) => a - b)[0];
        Object.keys(this.zoomToFit).forEach((id) => { this.zoomScale[id] = this.zoomToFit[id]; });
        this.zoomToFit = {};
      }
    },

    /**
     * helper function to set guidelines in axis map
     * @param key => This can be a node id or artboard
     * @param axis => The pinia state property you want to assign to ['xAxisGuidelines','yAxisGuidelines']
     * @param coordinates => The values you wish to assign {top, middle, bottom, etc}
     */
    setGuidelines(key, axis, coordinates) {
      if (key in this[axis]) {
        this[axis][key] = coordinates;
      } else {
        this[axis] = {
          ...this[axis],
          [key]: coordinates,
        };
      }
    },

    /**
     * helper function for layers to use and update their rendering position to the parent
     * @param node => This is the node ref to be ordered
     * @param parentNode => This is the node ref which contains all nodes in certain order
     * @param newIndex => The new index of node as a child of parentNode
     * @param newId => The new id of node as identification
     */
    getSortIndexFunction(node, parentNode) {
      return async (newIndex, newId) => {
        node.renderIndex = newIndex;
        await nextTick();
        const childIndex = parentNode.children.findIndex((child) => child.renderIndex > newIndex);
        const currentIndex = parentNode.children.findIndex((child) => child.id === newId);
        // -1 is for situation when the currentIndex is too small, after deleting it, it will
        // affect the childIndex we culculated, and it should be 1 spot smaller
        parentNode.setChildIndex(node, currentIndex < childIndex ? childIndex - 1 : childIndex);
      };
    },
  },
});

export default useEditorStore;
