/* eslint-disable max-len */
import { mdiFormatText, mdiImageOutline, mdiFilmstrip, mdiMovieOutline, mdiEarth, mdiPound, mdiSelect, mdiCrosshairs, mdiCameraBurst, mdiMusicNote, mdiGamepad, mdiHexagonOutline, mdiShape,
  mdiArtboard, mdiPalette, mdiHelpBoxOutline, mdiDomain, mdiBell, mdiBellOff,

  mdiPlus, mdiMinus, mdiArrowLeft, mdiLock, mdiLockOpen, mdiEye, mdiEyeOff, mdiFilterVariant, mdiCogs, mdiDownload, mdiDownloadOff, mdiDelete, mdiTune, mdiMagnify, mdiDotsVertical, mdiUndo, mdiRedo,
  mdiCropPortrait, mdiCropLandscape, mdiCropSquare, mdiViewAgendaOutline, mdiHistory, mdiPackageUp, mdiRestart, mdiVolumeHigh, mdiVolumeMute, mdiPlay, mdiPause, mdiFullscreen,

  mdiArrowLeftTop, mdiPencil, mdiSwapHorizontalBold, mdiRefresh, mdiImport, mdiAlignHorizontalDistribute, mdiAlignVerticalDistribute, mdiClose, mdiLinkOff, mdiLink, mdiAlignVerticalTop,
  mdiAlignVerticalCenter, mdiAlignVerticalBottom, mdiAlignHorizontalLeft, mdiAlignHorizontalCenter, mdiAlignHorizontalRight, mdiFormatVerticalAlignTop, mdiFormatVerticalAlignCenter,
  mdiFormatVerticalAlignBottom, mdiFormatHorizontalAlignLeft, mdiFormatHorizontalAlignCenter, mdiFormatHorizontalAlignRight, mdiDragHorizontalVariant, mdiDragVerticalVariant, mdiArrowAll,

  mdiCircleOutline, mdiCircleSlice8, mdiMenuRight, mdiMenuLeft, mdiMenuUp, mdiMenuDown, mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiMinusBox, mdiTuneVariant, mdiMoviePlusOutline,
  mdiArrowDown, mdiArrowUp, mdiInformationOutline, mdiAlertCircle, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiChevronDoubleUp, mdiChevronDoubleDown, mdiAccountMultiple, mdiChevronUp,
  mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiCheckboxBlankCircle, mdiCircleSmall, mdiEyedropper, mdiShareVariant, mdiRefreshAuto, mdiMinusCircleOutline, mdiVectorArrangeAbove,
  mdiFlagVariant, mdiFlagVariantOutline, mdiHelpCircleOutline,

  mdiViewCarousel, mdiViewQuilt, mdiViewComfy, mdiWhiteBalanceSunny, mdiFileImage, mdiStar, mdiArchive, mdiFolderMultipleImage, mdiFolderPlus, mdiFilePlus, mdiViewList, mdiFolder, mdiOpenInNew,
  mdiBorderRadius, mdiFormatLetterSpacingVariant, mdiFormatLineHeight, mdiComment, mdiSitemap, mdiFlipHorizontal, mdiCloseCircleOutline, mdiStarOutline, mdiOrderBoolAscending, mdiRename,
  mdiFolderMove, mdiFileMultiple, mdiContentCopy, mdiContentPaste, mdiContentCut, mdiRotateRight, mdiFormatLetterCaseUpper, mdiFormatLetterCaseLower, mdiFormatLetterCase } from '@mdi/js';

// custom icons
export const flipHorizontal = 'M6.35 12.25 2 21h9V12.25c0-4.8-.05-8.75-.15-8.75-.05 0-2.1 3.95-4.5 8.75zM9 15.5v3.5H7.25c-.95 0-1.75-.1-1.75-.25 0-.35 3.2-6.75 3.35-6.75 .1 0 .15 1.55.15 3.5zM13 12.15V21h9l-4.3-8.65c-2.35-4.7-4.4-8.7-4.5-8.8-.2-.2-.4 3.75-.4 8.6z';
export const flipVertical = 'M3 6.5v4.5h8.85c4.85 0 8.7-.1 8.6-.2-.1-.1-4.1-2.15-8.8-4.5L3 2v4.5zm6 0.75 3.25 1.7-3.6.05H5v-1.75c0-.95.15-1.75.4-1.75.2.05 1.8.8 3.6 1.75zM3 17.5v4.5l8.65-4.3c4.7-2.35 8.7-4.4 8.8-4.5 .1-.1-3.75-.2-8.6-.2H3v4.5z';
export const analytics = 'M12 21L9.33609 16.6406H14.6636L12 21ZM7.53124 13.6875L5.16803 9.82031H18.8318L16.4687 13.6875H7.53124ZM3.36321 6.86719L1 3H23L20.6366 6.86719H3.36321Z';
// const batchBase64Icon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEuMzMzNCIgY3k9IjEwLjY2NjciIHI9IjIuNjY2NjciIGZpbGw9IiNFM0UzRTMiLz4KPGNpcmNsZSBjeD0iNy45OTk5OCIgY3k9IjQuNjY2NjciIHI9IjIuNjY2NjciIGZpbGw9IiNFM0UzRTMiLz4KPGVsbGlwc2UgY3g9IjQuNjY2NjciIGN5PSIxMC42NjY3IiByeD0iMi42NjY2NyIgcnk9IjIuNjY2NjciIGZpbGw9IiNFM0UzRTMiLz4KPC9zdmc+Cg==';
// const noBatchBase64Icon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNy45OTk5OSIgY3k9IjcuOTk5OTkiIHI9IjUuMzMzMzMiIHN0cm9rZT0iI0UzRTNFMyIgc3Ryb2tlLWRhc2hhcnJheT0iMiAyIi8+Cjwvc3ZnPgo=';
// const pin64Base = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjkuMzMzMzEiIGN5PSI2LjY2Njc1IiByeD0iMiIgcnk9IjIiIGZpbGw9IiM0NzNDNkUiLz4KPHJlY3QgeD0iNy42MDk1IiB5PSI3LjQ0Nzc1IiB3aWR0aD0iMS4zMzMzMyIgaGVpZ2h0PSI0IiByeD0iMC42NjY2NjciIHRyYW5zZm9ybT0icm90YXRlKDQ1IDcuNjA5NSA3LjQ0Nzc1KSIgZmlsbD0iIzQ3M0M2RSIvPgo8L3N2Zz4K';

// list of icons available
export const iconsMap = {
  text: mdiFormatText,
  image: mdiImageOutline,
  video: mdiFilmstrip,
  scene: mdiMovieOutline,
  url: mdiEarth,
  artboard: mdiPound, // cant find // mdiArtboard
  group: mdiSelect,
  hotspot: mdiCrosshairs,
  sequence: mdiCameraBurst,
  audio: mdiMusicNote,
  game: mdiGamepad,
  component: mdiHexagonOutline,
  shape: mdiShape,
  frame: mdiBorderRadius, // cant find
  background: mdiPalette,
  unknown: mdiHelpBoxOutline,
  company: mdiDomain,
  bellOn: mdiBell,
  bellOff: mdiBellOff,
  plus: mdiPlus,
  minus: mdiMinus,
  back: mdiArrowLeft,
  locked: mdiLock,
  unlocked: mdiLockOpen,
  visible: mdiEye,
  hidden: mdiEyeOff,
  filter: mdiFilterVariant,
  settings: mdiCogs, // cant find
  export: mdiDownload,
  exportDisabled: mdiDownloadOff,
  delete: mdiDelete,
  properties: mdiTune,
  search: mdiMagnify,
  menu: mdiDotsVertical,
  undo: mdiUndo,
  redo: mdiRedo,
  portrait: mdiCropPortrait,
  landscape: mdiCropLandscape,
  square: mdiCropSquare,
  sideBySide: mdiViewAgendaOutline, // we have rotated 90 degrees
  history: mdiHistory,
  restore: mdiPackageUp,
  restart: mdiRestart,
  volume: mdiVolumeHigh,
  volumeMute: mdiVolumeMute,
  play: mdiPlay,
  pause: mdiPause,
  fullScreen: mdiFullscreen,
  jumpBack: mdiArrowLeftTop,
  edit: mdiPencil,
  replace: mdiSwapHorizontalBold,
  flipHorizontal,
  flipVertical,
  cycle: mdiRefresh, // same as REFRESH
  import: mdiImport,
  distributeHorizontal: mdiAlignHorizontalDistribute,
  distributeVertical: mdiAlignVerticalDistribute,
  close: mdiClose,
  unsynced: mdiLinkOff,
  synced: mdiLink,
  alingTop: mdiAlignVerticalTop,
  alingMiddle: mdiAlignVerticalCenter,
  alingBottom: mdiAlignVerticalBottom,
  alingLeft: mdiAlignHorizontalLeft,
  alingCenter: mdiAlignHorizontalCenter,
  alingRight: mdiAlignHorizontalRight,
  formatTop: mdiFormatVerticalAlignTop,
  formatMiddle: mdiFormatVerticalAlignCenter,
  formatBottom: mdiFormatVerticalAlignBottom,
  formatLeft: mdiFormatHorizontalAlignLeft,
  formatCenter: mdiFormatHorizontalAlignCenter,
  formatRight: mdiFormatHorizontalAlignRight,
  dragHorizontal: mdiDragHorizontalVariant,
  dragVertical: mdiDragVerticalVariant,
  drag: mdiArrowAll,
  selectorUnselected: mdiCircleOutline,
  selectorSelected: mdiCircleSlice8,
  arrowRight: mdiMenuRight,
  arrowLeft: mdiMenuLeft,
  arrowUp: mdiMenuUp,
  arrowDown: mdiMenuDown,
  checkboxOff: mdiCheckboxBlankOutline,
  checkboxOn: mdiCheckboxMarked,
  checkboxMixed: mdiMinusBox,
  tune: mdiTuneVariant,
  scenePlus: mdiMoviePlusOutline,
  descending: mdiArrowDown,
  ascending: mdiArrowUp,
  info: mdiInformationOutline,
  warning: mdiAlertCircle,
  invalid: mdiAlertCircleOutline,
  valid: mdiCheckCircleOutline,
  chevronDoubleUp: mdiChevronDoubleUp, // CHEVRON 2 UP
  chevronDoubleDown: mdiChevronDoubleDown, // CHEVRON 2 DOWN
  account: mdiAccountMultiple,
  chevronUp: mdiChevronUp,
  chevronDown: mdiChevronDown,
  chevronLeft: mdiChevronLeft,
  chevronRight: mdiChevronRight,
  dot: mdiCheckboxBlankCircle,
  dotUnselected: mdiCircleSmall,
  eyeDropper: mdiEyedropper,
  share: mdiShareVariant,
  focal: mdiCrosshairs, // cant find (same as hotspot)
  refreshAuto: mdiRefreshAuto,
  flattened: mdiMinusCircleOutline,
  sendBack: mdiMinusCircleOutline,
  sendFront: mdiVectorArrangeAbove,
  flagOn: mdiFlagVariant,
  flagOff: mdiFlagVariantOutline,
  help: mdiHelpCircleOutline,
  masters: mdiViewCarousel,
  sizes: mdiViewQuilt,
  variants: mdiViewComfy,
  sun: mdiWhiteBalanceSunny,
  analytics, // cant find
  ad: mdiFileImage,
  favorite: mdiStar,
  archive: mdiArchive,
  assetLibrary: mdiFolderMultipleImage,
  createFolder: mdiFolderPlus,
  createAd: mdiFilePlus,
  listView: mdiViewList,
  folder: mdiFolder,
  open: mdiOpenInNew,
  round: mdiBorderRadius,
  letterSpacing: mdiFormatLetterSpacingVariant,
  lineHeight: mdiFormatLineHeight,
  comment: mdiComment,
  pathview: mdiSitemap,
  compare: mdiFlipHorizontal,
  closeCircle: mdiCloseCircleOutline,
  unfavorite: mdiStarOutline,
  transfer: mdiOrderBoolAscending,
  rename: mdiRename,
  move: mdiFolderMove,
  duplicate: mdiFileMultiple,
  copy: mdiContentCopy,
  paste: mdiContentPaste,
  cut: mdiContentCut,
  rotate: mdiRotateRight,
  upperCase: mdiFormatLetterCaseUpper,
  lowerCase: mdiFormatLetterCaseLower,
  titleCase: mdiFormatLetterCase,
};

// list of icons that should be rotated 90 degrees
export const rotatedIcons = ['sideBySide'];

export default markRaw({
  name: 'cmpIcon',

  props: {
    name: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value),
    },
    color: String,
  },

  setup(props) {
    const classes = computed(() => {
      let classString = 'cmp-icon';
      if (props.color) {
        classString += ` text-${props.color}`;
      }
      return classString;
    });

    if (!Object.keys(iconsMap).includes(props.name)) {
      console.warn(`Icon ${props.name} not found in the list`);
      return () => null;
    }

    return () => h(
      'span',
      {
        class: classes.value,
        ariaHidden: 'true',
        role: 'presentation',
      },
      h('svg', {
        viewBox: '0 0 24 24',
      }, [
        h('path', {
          d: iconsMap[props.name],
          transform: (rotatedIcons.includes(props.name)) ? 'rotate(90 12 12)' : '',
        }),
      ]),
    );
  },
});
