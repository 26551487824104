import { defineStore, storeToRefs } from 'pinia';
import { EditorMode, TimelineState } from '@/views/creatives/enums';
import { usePlayCanvasStore } from './playCanvas';
import { useCreativeStore } from './creative';

export const usePlayTimelineStore = defineStore('playTimeline', {
  state: () => ({
    timelineState: TimelineState.PEND,
    videoLoop: 0,
    // set current time to be an array but with only one content just for watch to be able to detect
    // every time when currentTime is reset.
    currentTime: [0],
    timeWasSkipped: false,
    globalTime: 0,
    globalTimerRunning: false,
    autoplayScene: false,
    FRAMES_PER_SECOND: 20,
    FRAME_MIN_TIME: (1000 / 60) * (60 / 20) - (1000 / 60) * 0.5,
    updateInPlace: false,
    zoomLevel: 60,
    horizPct: 0,
    currentVideoTime: null,
    triggerPreview: [false],
    pairedLayerIds: [],
    resetAt: 0,
  }),

  getters: {
    getCurrentDuration(): number {
      const playCanvasStore = usePlayCanvasStore();
      return playCanvasStore.getCurrentSceneLength || 0;
    },

    getCurrentTime(state): number {
      return state.currentTime[0];
    },

    getCurrentTimePct(state): number {
      if (this.getCurrentDuration === 0) return 0;
      return state.currentTime[0] / this.getCurrentDuration;
    },
    getTimelineState(state): TimelineState {
      return state.timelineState;
    },
  },

  actions: {
    stopGlobalTimer(): void {
      this.globalTimerRunning = false;
    },

    resetGlobalTimer(): void {
      this.globalTime = 0;
    },

    reset(): void {
      this.currentTime = [0];
      this.pairedLayerIds = [];
      this.timelineState = TimelineState.PEND;
      this.resetAt = performance.now();
    },

    setTime(newTime: number, timeSkip = false): void {
      // console.log('tick', this.currentTime[0], newTime);
      // a flag that resets each frame to indicate if we've moved the playhead manually
      // different components will handle this on their own.
      if (timeSkip) this.timeWasSkipped = true;
      else this.timeWasSkipped = false;

      this.currentTime = [Math.min(newTime, this.getCurrentDuration)];
      if (newTime >= this.getCurrentDuration) {
        // TODO: this should only set to pause if the next scene does not have an autoplaying video
        this.timelineState = TimelineState.PAUSE;
        this.currentVideoTime = null;
      }
    },

    goToScene(sceneIndex):void {
      const playCanvasStore = usePlayCanvasStore();
      const oldSceneIndex = playCanvasStore.getCurrentSceneIndex;

      playCanvasStore.setSelectedPath(`scenes[${sceneIndex}]`);
      this.reset();
    },

    pause(): void {
      this.timelineState = TimelineState.PAUSE;
    },

    // TODO: state can be deleted when custom scene duration is on and
    // onFinish now only happens in the end of the scene. This param is added
    // to solve the problem where onComplete is called twice when video finishes
    // and mess up with the timeline state if video is autoplay & video has
    // on finish to be restart the scene
    togglePlay(state?: TimelineState): void {
      if (this.currentTime[0] === this.getCurrentDuration) {
        this.currentTime = [0];
        this.pairedLayerIds = [];
      }

      if (state) this.timelineState = state;
      else this.timelineState = this.timelineState === TimelineState.PLAY ? TimelineState.PAUSE : TimelineState.PLAY;

      // updateInPlace prevents the editor from creating more than one update loop which throws off scene timing
      if (this.timelineState === TimelineState.PLAY && !this.updateInPlace) {
        const creativeStore = useCreativeStore();
        const playCanvasStore = usePlayCanvasStore();
        const { scenes } = creativeStore.currentCreative;
        const firstNonGlobalSceneIndex = scenes.findIndex((scene) => !scene.global);

        if (playCanvasStore.getCurrentSceneIndex === firstNonGlobalSceneIndex) {
          this.globalTimerRunning = true;
        }

        this.updateInPlace = true;
        this.updateTime(Date.now());
      }
    },

    updateTime(lastFrameTime: number): void {
      const now = Date.now();
      const delta = (now - lastFrameTime) / 1000;

      if (this.timelineState !== TimelineState.PLAY) {
        this.updateInPlace = false;
        return;
      }
      const baseTime = this.currentVideoTime === null ? this.getCurrentTime : this.currentVideoTime;
      if (delta > 0) this.setTime(baseTime + delta);
      window.requestAnimationFrame(() => this.updateTime(now));
    },
  },
});

export default usePlayTimelineStore;
