import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';
import { UserRole, ProductIds } from '@/common/enums';
import { calculateEndingDate, objectIsEmpty } from '@/common/utils';
import { requests } from '@/common/plugins/axios';
import { Company, CompanyLicense, CompanyUser } from '@/views/companies/interfaces';

export const useUserStore = defineStore('user', {
  state: () => ({
    currentUser: {} as CompanyUser,
  }),

  getters: {
    userLicenses: (state) => (state.currentUser?.licenses || []) as CompanyLicense[],

    /**
     * Check if currentUser is not empty in store, and return its
     * initials (firstName & lastName), empty string in otherwise.
     * @param state => User store state.
     */
    userInitials: (state): string => {
      if (Object.entries(state.currentUser).length === 0) return '';

      return state.currentUser.firstName[0] + state.currentUser.lastName[0];
    },

    /**
     * Returns current user firstName
     * @param state  User store state.
     */
    userFirstName: (state): string => {
      if (Object.entries(state.currentUser).length === 0) return '';

      return state.currentUser.firstName;
    },

    /**
     * Return the users roles as a mapped string
     * @param state => User store state.
     */
    roleNames: (state): UserRole[] => {
      if (Object.entries(state.currentUser).length === 0) return [];

      return state.currentUser.roles.map((n) => n.name);
    },

    /**
     * if the user has the system-admin role
     * @param state => User store state.
     */
    isSystemAdmin: function isAdmin(state): boolean {
      if (Object.entries(state.currentUser).length === 0) return false;

      return this.roleNames.includes(UserRole.SYSTEM_ADMIN);
    },

    /**
     * if the user has the system-admin role or craftsmanplus
     * @param state => User store state.
     */
    isAdmin: function isAdmin(state): boolean {
      if (Object.entries(state.currentUser).length === 0) return false;

      return this.isSystemAdmin || this.roleNames.includes(UserRole.CRAFTSMAN_ADMIN);
    },

    /**
     * if the user has the system-admin role, craftsmanplus or company admin
     * @param state => User store state.
     */
    isCompanyAdmin: function isAdmin(state): boolean {
      if (Object.entries(state.currentUser).length === 0) return false;

      return this.isAdmin || this.roleNames.includes(UserRole.COMPANY);
    },

    /**
     * if the user has a license which is play enabled
     * @param state => User store state.
     */
    enableCanvas(): boolean {
      return this.userLicenses.some((n) => n.productId === ProductIds.CANVAS);
    },

    /**
     * if the user has a license which is play enabled
     * @param state => User store state.
     */
    enablePlay: function enablePlay(): boolean {
      return this.userLicenses.some((n) => n.productId === ProductIds.PLAY);
    },

    /**
     * if the user has a license which is play enabled and has a valid enddate and is not a trail license
     * @param state => User store state.
     */
    enablePlayExport: function enablePlayExport(): boolean {
      return !!this.userLicenses
        .filter((n) => n.productId === ProductIds.PLAY)
        .find((n) => new Date(calculateEndingDate(n)).getTime() > Date.now() && !n.trial);
    },
  },

  actions: {
    initPendo() {
      const stage = import.meta.env.VITE_STAGE || import.meta.env.VITE_NODE_ENV;
      const user = this.currentUser;
      const company = user.companies?.[0] as Company || false;

      const params = {
        visitor: {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          isAdmin: this.isAdmin,
          roles: this.roleNames,
          environment: import.meta.env.VITE_NODE_ENV.toUpperCase(),
          stage: stage.toUpperCase(),
        },
        account: {
          id: 'NA',
        },
      } as pendo.Identity;

      if (company) {
        params.account = {
          id: company.id,
          name: company.name,
        };
      }

      pendo.initialize(params);
    },

    /**
     * Make a GET request to get user info of current user
     * and set to currentUser state variable.
     */
    async getUserInfo(): Promise<void> {
      const result = await requests.get(`/me?includes=roles&includes=companies&includes=licenses&timestamp=${Date.now()}`, {
        id: 'fetch-me',
      });

      this.currentUser = result;

      // set the user to sentry so we can identify errors with users
      // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/identify-user/
      Sentry.setUser({ id: result.id });

      if (import.meta.env.VITE_NODE_ENV !== 'test') this.initPendo();

      // Set companyId to sessionStorage if currentUser isn't admin
      if (!this.isAdmin && !objectIsEmpty(this.currentUser)) {
        window.sessionStorage.setItem('cmp:company-id', (this.currentUser.companies[0] as Partial<Company>).id);
      }
    },

    /**
     * Make a PUT request to update some data of current user
     * and update its data in current store.
     * @param data => User data to update
     */
    updateProfileData(data: CompanyUser): void {
      // Update current user data
      this.currentUser = { ...this.currentUser, ...data };
    },
  },
});

export default useUserStore;
