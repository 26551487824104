import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
  state: () => ({
    isLoading: false,
    isFetching: false,
  }),

  actions: {
    startLoading() {
      this.isLoading = true;
    },

    stopLoading() {
      this.isLoading = false;
    },

    startFetching() {
      this.isFetching = true;
    },

    stopFetching() {
      this.isFetching = false;
    },
  },
});

export default useAppStore;
