import { type InjectionKey, Ref } from 'vue';
import { IShortKey } from '@/common/utils';

export const layerLoadedCountKey = Symbol('Layer Loaded Count') as InjectionKey<{ count: Ref<number>; update: () => void; }>;
export const sceneLoadedCountKey = Symbol('Scene Loaded Count') as InjectionKey<{ count: Ref<number>; update: () => void; }>;
export const shortKeysKey = Symbol('Short Keys') as InjectionKey<{ bindShortKeys: (keys: IShortKey[]) => void; }>;
export const testKey = Symbol('Scene Loaded Count') as InjectionKey<number>;
export const editingRichTextKey = Symbol('Editing Rich Text') as InjectionKey<{
  editingRichTextId: Ref<string>;
  update: (value: string) => void;
}>;
export const transformerBus = Symbol('Scene Loaded Count') as InjectionKey<{
  update: (value: any) => void;
}>;
