/**
 * Function that receive an object and check if
 * it doesn't have any key.
 * @param object => Object to check
 */
export function objectIsEmpty(object: any): boolean {
  return Object.keys(object).length === 0;
}

export default objectIsEmpty;
