import { UserLicense } from '@/common/interfaces/userLicense';
import { CompanyLicense } from '@/views/companies/interfaces';

/**
 * Function that receive a company license and return the
 * ending date according to its license data.
 * @param license => License data
 */
export function calculateEndingDate(license: CompanyLicense | UserLicense): number {
  const date = new Date(license.startDate);

  switch (license.durationType) {
    case 'days':
      return date.setDate(date.getDate() + license.durationLength);
    case 'months':
      return date.setMonth(date.getMonth() + license.durationLength);
    default:
      return date.setFullYear(date.getFullYear() + license.durationLength);
  }
}

export default calculateEndingDate;
