import { CanvasTypes } from '@/views/creatives/enums';
import { Creative, CreativeFolder, CreativeForm, CreativeGetOptions, CreativeMoveOptions, ICanvasBatches } from '@/views/creatives/interfaces';
import { Orientation, ProductIds } from '../enums';
import { useCompanyStore } from './company';
import { requests } from '../plugins';
import { removeFromAxiosCache, removeItemInArray, updateAxiosCache } from '../utils';
import { createQueryParams, creativeQueryParms } from '../utils/getQuery';

const blankPlayCreative = {
  scenes: [],
  settings: {
    orientation: Orientation.PORTRAIT,
    orientationLock: Orientation.PORTRAIT,
    useBGAudio: false,
    BGAudio: {
      onAudioFin: 'None',
      url: '',
      playOnEndCard: true,
    },
    useGlobalTimer: false,
    globalTimer: {
      onTimerFin: 'None',
      duration: 60,
      playOnEndCard: true,
      onClickUrl: '',
      iosUrl: '',
      androidUrl: '',
    },
    usePropagateURLs: true,
    propagateURLs: {
      onClickUrl: '',
      iosUrl: '',
      androidUrl: '',
    },
    globalAddToExistingScenes: false,
    globalAddToNewScenes: false,
  },
};

export const defaultBatches: ICanvasBatches[] = [
  { id: '1', color: '#FF005A', name: 'Batch 1' },
  { id: '2', color: '#F86400', name: 'Batch 2' },
  { id: '3', color: '#8BFF00', name: 'Batch 3' },
  { id: '4', color: '#00FFEB', name: 'Batch 4' },
  { id: '5', color: '#5E00FF', name: 'Batch 5' },
  { id: '6', color: '#997BFF', name: 'Batch 6' },
];

// update to ref from creativeCanvasDetails.ts
export const blankCanvasCreative = {
  boards: [],
  settings: {
    masterDimensions: ['M_1', 'M_2'], // selected masterboards
    boardsToBeExported: [],
    variantBoardIdsToExclude: {},
    namingConvention: '{{Size Dimensions}}_{{Ad Name}}_{{Variant Name}}_{{Export Date}}',
    batches: defaultBatches,
    showInitialResize: true,
  },
  data: {
    frames: [{
      id: 'F_Back',
      name: 'Background',
      type: 'background',
    }],
    instances: {
      F_Back: [
        {
          id: 'I_BG1',
          name: 'Background 1',
          type: CanvasTypes.BACKGROUND,
        },
      ],
    },
    iterations: {},
    variations: {},
    assets: {},
    fonts: {},
    orders: {},
  },
};

export const useCreativesStore = defineStore('creatives', {
  state: () => ({
    creatives: [] as Creative[],
    favoriteIds: [],
    selectedCreativeId: '',
    folders: [] as CreativeFolder[],
    selectedFolderId: 'null',
    totalItems: 0,
    isGridMode: true,
    query: { ...creativeQueryParms },
  }),
  getters: {
    currentCreative(state) {
      return state.creatives.find((n) => state.selectedCreativeId === n.id);
    },
    currentFolder(state) {
      return state.folders.find((n) => state.selectedFolderId === n.id);
    },
  },
  actions: {
    async transferCreative(companyId: string) {
      const creative = this.currentCreative;

      await requests.patch(
        `/creatives/${creative.id}`,
        { companyId, folderId: 'null' },
        {
          cache: {
            update: {
              [`fetch-creatives-${companyId}-${creative.folderId}`]: (cache) => removeFromAxiosCache(cache, 'items', creative.id),
            },
          },
        },
      );

      // Remove from current creatives array
      const index = this.creatives.findIndex((c) => c.id === creative.id);
      this.selectedCreativeId = '';
      this.creatives.splice(index, 1);
    },

    async fetchCreatives(args: CreativeGetOptions): Promise<void> {
      const companyId = useCompanyStore().currentCompanyId;

      // !TODO move this into a helper to generate query and cacheRef
      const params = ['includes=product', 'includes=groups:id', 'includes=creator', ...createQueryParams(args)];
      const cacheRef = ['fetch-creatives', companyId];

      if (args.archived) {
        cacheRef.push('archived');
      }

      if (args.favorites) {
        cacheRef.push('favorites');
      }

      if (args.deleted) {
        cacheRef.push('deleted');
      }

      if (args.folderId) {
        cacheRef.push(args.folderId);
      }

      let result;

      if (!args.favorites) {
        result = await requests.get(`/creatives?${params.join('&')}`, { id: cacheRef.join('-'), cache: !args.name });
      }

      if (args.favorites) {
        result = await requests.get(`/me/favorites?${params.join('&')}`, { id: cacheRef.join('-'), cache: !args.name });
      }

      if (args.page === 1) {
        this.totalItems = result.total;
        this.folders = result?.folders || [];
        this.creatives = result.items;
      }

      if (args.page > 1) {
        this.creatives.push(...result.items);
      }
    },

    async fetchFavoriteIds(id: string) {
      const params = [`companies=${id}`];

      const result = await requests.get(`/me/favorites?${params.join('&')}`, {
        id: `fetch-${id}-favoritesIds`,
      });

      this.favoriteIds = result.items.map((n: Creative) => n.id);
    },

    async setCreativeAsFavorite(id: string) {
      const { currentCompanyId } = useCompanyStore();

      await requests.post(
        `/creatives/${id}/favorite`,
        {},
        {
          cache: {
            update: {
              [`fetch-${currentCompanyId}-favorites`]: 'delete',
              [`fetch-${currentCompanyId}-favoritesIds`]: 'delete',
            },
          },
        },
      );

      this.favoriteIds.push(id);
    },

    async removeFromFavorites(id: string) {
      const { currentCompanyId } = useCompanyStore();

      await requests.delete(
        `/creatives/${id}/favorite`,
        {
          cache: {
            update: {
              [`fetch-${currentCompanyId}-favorites`]: 'delete',
              [`fetch-${currentCompanyId}-favoritesIds`]: 'delete',
            },
          },
        },
      );

      // Delete id from favorites
      this.favoriteIds = this.favoriteIds.filter((creativeId) => creativeId !== id);
    },

    async deleteCreative(id: string, force = false) {
      const { currentCompanyId } = useCompanyStore();
      const currentItemIndex = this.creatives.findIndex((item) => item.id === id);
      const creative = this.creatives[currentItemIndex];
      const params = [];

      if (force) {
        params.push('force=true');
      }

      await requests.delete(
        `/creatives/${id}?${params.join('&')}`,
        {
          cache: {
            update: {
              [`fetch-creatives-${currentCompanyId}-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-deleted-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-archived-${creative.folderId}`]: 'delete',
            },
          },
        },
      );

      // Remove in selected list
      this.totalItems -= 1;
      this.creatives = removeItemInArray(this.creatives, id);
    },

    async archiveCreative(id: string) {
      const { currentCompanyId } = useCompanyStore();
      const currentItemIndex = this.creatives.findIndex((item) => item.id === id);
      const creative = this.creatives[currentItemIndex];

      await requests.post(
        `/creatives/${id}/archive`,
        {},
        {
          cache: {
            update: {
              [`fetch-creatives-${currentCompanyId}-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-deleted-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-archived-${creative.folderId}`]: 'delete',
            },
          },
        },
      );

      // Remove in selected list
      this.totalItems -= 1;
      this.creatives = removeItemInArray(this.creatives, id);
    },

    async duplicateCreative(id: string) {
      const { currentCompanyId } = useCompanyStore();
      const currentItemIndex = this.creatives.findIndex((item) => item.id === id);
      const creative = this.creatives[currentItemIndex];

      const result = await requests.post(`/creatives/${id}/duplicate?includes=creator`, {}, {
        cache: {
          update: {
            [`fetch-creatives-${currentCompanyId}-${creative.folderId}`]: (cache, response) => updateAxiosCache(cache, response, 'items', response.data.id),
          },
        },
      });

      // Insert underneath of currentItem
      this.selectedCreativeId = result.id;
      this.creatives.splice(currentItemIndex + 1, 0, result);
    },

    async uploadThumbnail(id: string, file: File) {
      const { currentCompanyId } = useCompanyStore();
      const currentItemIndex = this.creatives.findIndex((item) => item.id === id);
      const creative = this.creatives[currentItemIndex];

      const fileReader = new FileReader();

      const fileBody = await new Promise<string | ArrayBuffer>((resolve) => {
        const readFile = () => {
          const buffer = fileReader.result;
          resolve(buffer);
        };

        fileReader.addEventListener('load', readFile);
        fileReader.readAsArrayBuffer(file);
      });

      const response = await requests.post(
        `/creatives/${id}/thumbnail`,
        fileBody,
        {
          headers: {
            'Content-Type': file.type,
          },
          cache: {
            update: {
              [`fetch-creatives-${currentCompanyId}-${creative.folderId}`]: 'delete',
            },
          },
        },
      );

      creative.coverImageS3Url = response.url;
      this.creatives.splice(currentItemIndex, 1, creative);
    },

    async restoreCreative(id: string) {
      const { currentCompanyId } = useCompanyStore();
      const currentItemIndex = this.creatives.findIndex((item) => item.id === id);
      const creative = this.creatives[currentItemIndex];

      await requests.post(
        `/creatives/${id}/restore`,
        {},
        {
          cache: {
            update: {
              [`fetch-creatives-${currentCompanyId}-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-deleted-${creative.folderId}`]: 'delete',
              [`fetch-creatives-${currentCompanyId}-archived-${creative.folderId}`]: 'delete',
            },
          },
        },
      );

      this.totalItems -= 1;
      this.creatives = removeItemInArray(this.creatives, id);
    },

    async createCreative(data: CreativeForm) {
      let body = { ...data };

      // use the default settings for a play creative
      if (data.productId === ProductIds.PLAY) {
        body = {
          ...data,
          ...blankPlayCreative,
        };
      }

      if (data.productId === ProductIds.CANVAS) {
        body = {
          ...data,
          ...blankCanvasCreative,
        };
      }

      const result = await requests.post('/creatives?includes=creator', body, {
        cache: {
          update: {
            [`fetch-creatives-${body.companyId}-${body.folderId}`]: (cache, response) => updateAxiosCache(cache, response, 'items', response.data.id),
          },
        },
      });

      this.creatives.unshift(result);

      return result;
    },

    async shareCreative(groups: string[]) {
      await requests.post(`/creatives/${this.selectedCreativeId}/share`, { groups });
    },

    async fetchFolder(folderId: string) {
      return requests.get(
        `/creatives/folders/${folderId}?recursive=true`,
        { id: `fetch-folder-${folderId}` },
      );
    },

    async fetchFolders(args: CreativeGetOptions) {
      const params = ['includes=product', 'includes=groups:id', ...createQueryParams(args)];

      const result = await requests.get(
        `/creatives?${params.join('&')}`,
        { id: `fetch-folders-${args.folderId}`, cache: { ttl: 1000 * 60, interpretHeader: false } },
      );

      result.folders.sort((a, b) => a.name.localeCompare(b.name));

      return result.folders;
    },

    async updateCreativeName(name: string) {
      const { coverImageS3Url, status, folderId, id } = this.currentCreative;

      const response = await requests.patch(
        `/creatives/${id}`,
        { name, coverImageS3Url, status, folderId },
        {
          cache: {
            update: {
              [`fetch-creative-${id}`]: 'delete',
            },
          },
        },
      );

      const index = this.creatives.findIndex((n) => n.id === id);
      this.creatives[index].name = response.name;
      this.creatives[index].updatedAt = response.updatedAt;
    },

    async updateFolderName(name: string) {
      const { parentId, companyId, id } = this.currentFolder;

      const response = await requests.patch(
        `creatives/folders/${id}`,
        { name, parentId, companyId },
        {
          cache: {
            update: {
              [`fetch-creatives-${companyId}-${parentId}`]: (cache, res) => updateAxiosCache(cache, res, 'folders', id),
              [`fetch-folders-${companyId}-${parentId}`]: (cache, res) => updateAxiosCache(cache, res, 'folders', id),
            },
          },
        },
      );

      // Update
      const index = this.folders.findIndex((n) => n.id === id);
      this.folders[index].name = response.name;
      this.folders[index].updatedAt = response.updatedAt;
    },

    selectFolder(folderId: string) {
      this.selectedFolderId = folderId;
    },

    async deleteFolder(id: string) {
      await requests.delete(`/creatives/folders/${id}`);
      this.folders = removeItemInArray(this.folders, id);
    },

    async createFolder(data: Partial<CreativeFolder>) {
      const result = await requests.post('/creatives/folders?includes=creator', data, {
        cache: {
          update: {
            [`fetch-creatives-${data.companyId}-${data.parentId}`]: (cache, response) => updateAxiosCache(cache, response, 'folders', response.data.id),
            [`fetch-folders-${data.companyId}-${data.parentId}`]: (cache, response) => updateAxiosCache(cache, response, 'folders', response.data.id),
          },
        },
      });

      this.folders.unshift(result);

      return result;
    },

    async moveCreative(data: CreativeMoveOptions) {
      const companyId = useCompanyStore().currentCompanyId;

      await requests.patch(`/creatives/${data.itemId}`, { folderId: data.destinationId }, {
        cache: {
          update: {
            [`fetch-creatives-${companyId}-${data.currentParent}`]: (cache) => removeFromAxiosCache(cache, 'items', data.itemId),
            [`fetch-creatives-${companyId}-${data.destinationId}`]: (cache, response) => updateAxiosCache(cache, response, 'items', response.data.id),
          },
        },
      });

      // Remove item from current state array.
      this.totalItems -= 1;
      this.creatives = removeItemInArray(this.creatives, data.itemId);
    },

    async moveFolder(data: CreativeMoveOptions) {
      const companyId = useCompanyStore().currentCompanyId;

      await requests.patch(`/creatives/folders/${data.itemId}`, { parentId: data.destinationId }, {
        cache: {
          update: {
            [`fetch-creatives-${companyId}-${data.currentParent}`]: (cache) => removeFromAxiosCache(cache, 'folders', data.itemId),
            [`fetch-creatives-${companyId}-${data.destinationId}`]: (cache, response) => updateAxiosCache(cache, response, 'folders', response.data.id),
            [`fetch-folders-${data.currentParent}`]: (cache) => removeFromAxiosCache(cache, 'folders', data.itemId),
            [`fetch-folders-${data.itemId}`]: 'delete',
          },
        },
      });

      // Remove item from current state array.
      this.folders = removeItemInArray(this.folders, data.itemId);
    },
  },
});
