import { customAlphabet } from 'nanoid';

export const generateRandomId = (length = 5) => {
  const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const nanoid = customAlphabet(alphabet, length);
  return nanoid();
};

/**
 * Random id generation using nanoid
 */
export const generateId = (value?: string | number): string => {
  if (typeof value === 'string' && value && value !== '0') return value;
  if (typeof value === 'number' && value < 0) return generateRandomId();
  return generateRandomId();
};
