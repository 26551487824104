import { Notify, Dialog } from 'quasar';
import iconSet from 'quasar/icon-set/svg-mdi-v6';

// Import Quasar css
import '../styles/quasar.scss';

// !important
// changed where quasar points too for tree shaking
export { Quasar } from 'quasar';

Notify.setDefaults({
  position: 'bottom-left',
  timeout: 2000,
});

export const QuasarOptions = {
  iconSet,
  plugins: {
    Notify,
    Dialog,
  },
};
