import { defineStore } from 'pinia';
import { requests } from '@/common/plugins/axios';
import { CreativeData, IAssetData, ICreativeBaseData, IInstance } from '@/views/creatives/interfaces/creativeData';
import { CanvasPartnerDimensions, LayoutDimension, CanvasVariantsArtBoards, CanvasArtBoards } from '@/common/interfaces/canvasStore';
import { CanvasEditorView, CanvasTypes } from '@/views/creatives/enums';
import { useCompanyStore } from '@/common/store/company';
import { extractDataPath } from '@/views/creatives/utils/canvasPath';
import { getLayerType } from '@/views/creatives/utils/getLayerType';
import { Creative, ICanvasSettings, IHighlightedNode, IVariantBoardIds } from '@/views/creatives/interfaces';
import { buildCanvasPath } from '@/views/creatives/utils';
import { showNotify, set, validateSelectedPaths, objectIsEmpty } from '@/common/utils';
import { useEditorStore } from './editor';
import { useCreativeStore } from './creative';
import { ICanvasPartner, ICreativeDimensionBoards, MasterDimension } from '../interfaces';

export const useCanvasStore = defineStore('canvas', {
  state: () => ({
    canvasPartnerDimensions: { dimensions: [], partners: [] } as CanvasPartnerDimensions, // Partners that are available to use with canvas
    customDimensions: [] as LayoutDimension[],
    variantsList: {}, // Combination for all instance iterations
    flagged: [], // An array of flagged iterations
    activeLeftDrawerTab: CanvasEditorView.MASTERS as CanvasEditorView,
    editorSelectionType: 'frame',
    masters: [] as MasterDimension[], // An array of master objects
    selectedPaths: [] as string[], // The current selected path in the canvas
    oldSortablePath: null, // The previous selected path in the canvas
    currentVariant: null, // if on variantsTab
    sharedSelectedLayer: false, // boolean to check if selection is shared between layers
    masterArtboards: {} as CanvasArtBoards,
    reSizeArtboards: {} as CanvasArtBoards,
    variantArtboards: {} as CanvasVariantsArtBoards,
    debugAverageSavings: { runs: 0, savings: 0 }, // The average savings of the current creative
    showAddCustom: false,
    showSelectMaster: false,
    selectedVariantId: '' as string,
    variantNameUpToDate: true, // used to force update dynamicScroller since name is nested
    mouseOverLayerId: '', // This seperates mouse over for expand items Canvas Left Drawer
    selectedPartnerFilter: [],
    highlightedFrameNode: {} as IHighlightedNode, // used when highlighting the frame of an instance
    showLoader: false, // boolean to hide and show editor loader
    variantOrder: [] as string[], // Used to store order of variants
    recentInteractedDesign: '' as string,
    recentInteractedResize: '' as string,
    recentInteractedExport: '' as string,
    dirtyFlags: {} as Record<string, string>, // Used to track dirty flags for text instance validation
    bulkMode: false as boolean,
  }),

  getters: {
    getCurrentCreative() {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative ?? {} as Creative;
    },
    /**
     * get the current creative data to use for frames, instances, iterations, etc
     * @returns {object} - the current creative data property
     */
    canvasData(): CreativeData {
      const currentCreative = this.getCurrentCreative as Creative;
      return currentCreative && currentCreative.data ? currentCreative.data : {} as CreativeData;
    },

    frames: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.frames ?? [];
    },

    boards: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.boards ?? [];
    },

    instances: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.instances ?? {};
    },

    variations: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.variations ?? {};
    },

    iteration() {
      const creativeStore = useCreativeStore();
      const { iterations } = creativeStore.currentCreative.data;

      return (frameId?: string, instanceId?: string, boardId?: string) => {
        if (typeof boardId !== 'undefined' && typeof instanceId !== 'undefined') {
          return iterations?.[frameId]?.[instanceId]?.[boardId] || {}; // partial instance
        }

        if (typeof boardId !== 'undefined') {
          return iterations?.[frameId]?.[boardId] || {}; // partial frame
        }

        if (typeof frameId !== 'undefined') {
          return iterations?.[frameId] || {};
        }

        return {};
      };
    },

    iterations: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.iterations ?? {};
    },

    orders: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.data?.orders ?? {};
    },

    settings: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.settings ?? {} as ICanvasSettings;
    },

    dimensionsToBeExported(): LayoutDimension[] {
      if (!this.settings) return [];
      if (!this.settings.boardsToBeExported) this.settings.boardsToBeExported = [];
      return this.settings.boardsToBeExported?.map((id) => {
        let board = {} as LayoutDimension;
        if (id[0].toLocaleLowerCase() === 'c') {
          board = this.customDimensions.find((o) => o.id === id);
        } else {
          board = this.canvasPartnerDimensions.dimensions.find((o) => o.id === id);
        }
        return board ?? undefined;
      }).filter((o) => o !== undefined);
    },

    variantBoardIdsToExclude: (): IVariantBoardIds => {
      const creativeStore = useCreativeStore();
      if (!creativeStore.currentCreative.settings) creativeStore.currentCreative.settings = { variantBoardIdsToExclude: {} };
      if (!creativeStore.currentCreative.settings?.variantBoardIdsToExclude) creativeStore.currentCreative.settings.variantBoardIdsToExclude = {};
      return creativeStore.currentCreative.settings.variantBoardIdsToExclude;
    },

    getCurrentSelectedPath: (state): string[] => state.selectedPaths,

    getCurrentSelectionIndexes(): string[][] {
      if (!this.getCurrentSelectedPath) return [];
      return this.getCurrentSelectedPath.map((path) => path.match(/([0-9]+)/g));
    },

    getSelectedPathData(): Map<string, string> {
      if (this.getCurrentSelectedPath.length === 0) return new Map();
      return extractDataPath(this.getCurrentSelectedPath[0]);
    },

    getSelectedBoardId(): string {
      return this.getSelectedPathData.get('masters') ?? null;
    },

    getSelectedFrameId(): string {
      return this.getSelectedPathData.get('frames') ?? null;
    },

    // TODO: this is unused; remove?
    getSelectedVariantId(): string {
      return this.getSelectedPathData.get('variant') ?? null;
    },

    getSelectedInstanceId(): string {
      return this.getSelectedPathData.get('instances') ?? null;
    },

    getCurrentInstanceType(): CanvasTypes {
      const currentFrameData = this.canvasData.frames?.find((frame) => frame.id === this.getSelectedFrameId);
      return currentFrameData?.type || null;
    },

    getCurrentLayerType(): string {
      if (this.getCurrentSelectedPath.length === 0) return '';
      return getLayerType(this.getCurrentSelectedPath[0]);
    },

    getIsFrameless(): boolean {
      if (this.getCurrentLayerType !== 'frame') return false;
      return this.instances?.[this.getSelectedFrameId]?.length === 1;
    },

    isAllVariantsSelectedForExport() {
      const variantIds = Object.keys(this.variantArtboards);
      if (!variantIds.length) return true;

      return Object.keys(this.variantBoardIdsToExclude).every((boardId) => this.variantBoardIdsToExclude[boardId].length === 0);
    },

    isSettingsPositionDisabled(): boolean {
      const editorStore = useEditorStore();
      return this.activeLeftDrawerTab === CanvasEditorView.VARIANTS || (this.activeLeftDrawerTab === CanvasEditorView.ARTBOARDS && !editorStore.editableId);
    },

    getFrameOrder() {
      const { frames, orders } = this;
      return (boardId: string) => {
        if (!boardId || boardId[0] === 'M') return frames.map((frame) => frame.id);
        return orders[boardId] ?? frames.map((frame) => frame.id);
      };
    },

    getInstanceOrder() {
      const { instances } = this;
      return (frameId: string) => instances[frameId].map((instance) => instance.id);
    },

    /**
     * Checks if the selected items span across multiple masters.
     *
     * @param {Object} state - Pinia state.
     * @returns {boolean} Returns true if the selected items span across multiple masters, otherwise false.
     */
    isSelectionAcrossMasters(state): boolean {
      const selectedPaths = state.selectedPaths.map((path) => extractDataPath(path).get('masters'));
      return new Set(selectedPaths).size > 1;
    },

    /**
     * Checks if the selected item types are mixed.
     */
    isMixedSelection(state): boolean {
      if (state.selectedPaths.length === 0) return false;
      const selectedPaths = state.selectedPaths.map((path) => {
        const layer = this.canvasData.frames?.find((frame) => frame.id === extractDataPath(path).get('frames'));
        return layer?.type ?? null;
      });
      return new Set(selectedPaths).size > 1;
    },

    // getCombinedSelectedLayerType(state) Mixed/Frame/Instance
    getCombinedSelectedLayerType(state): Set<string> {
      if (state.selectedPaths.length === 0) return new Set();
      const selectedPaths = state.selectedPaths.map((path) => getLayerType(path));
      return new Set(selectedPaths);
    },

    // check if all selected layers are all frameless
    getCombinedSelectedIsFrameless(state): boolean {
      if (state.selectedPaths.length === 0) return false;
      const selectedFrameIds = state.selectedPaths
        .map((path) => extractDataPath(path).get('frames'));
      return selectedFrameIds.every((frameId) => this.instances[frameId]?.length === 1);
    },

    // return all unique selected frame ids
    getSelectedFrameIds(): string[] {
      const selectedFrameIds = this.selectedPaths
        .map((path) => extractDataPath(path).get('frames'));
      return [...new Set(selectedFrameIds)];
    },

    getSelectedLayerCount(state): number {
      return state.selectedPaths.length;
    },
  },

  actions: {
    setDirtyFlag(id: string, state = 'dirty'): void {
      this.dirtyFlags[id] = state;
    },

    clearDirtyFlag(id: string): void {
      delete this.dirtyFlags[id];
    },

    /**
     * Fetch master dimensions available
     */
    async fetchMasterDimensions() {
      const masters = requests.get('/creatives/dimensions/masters');
      const { items } = await masters;

      const masterBoards : MasterDimension[] = [];

      // backward compatibility for old creatives
      if (!this.settings.masterDimensions) {
        this.settings.masterDimensions = ['M_1', 'M_2', 'M_3'];
      }

      items.forEach(({ id, width, height, name }) => {
        // We add an M to ids to differenciate from Partner/Custom/Master dimensions
        const masterboardId = `M_${id}`;
        masterBoards.push({
          id: masterboardId,
          name,
          width,
          height,
          isEnabled: this.settings.masterDimensions.includes(masterboardId),
        });
      });

      // specific order for masterboards, we rely on this to determine order when displaying
      const masterOrder = ['M_4', 'M_1', 'M_3', 'M_2', 'M_5'];
      masterBoards.sort((a, b) => masterOrder.indexOf(a.id) - masterOrder.indexOf(b.id));

      this.masters = masterBoards;
      return masters;
    },
    /**
     * Fetch partner templates for canvas
     */
    async fetchCanvasPartners() {
      const result = await requests.get('/types/partners/canvas', {
        id: 'fetch-partners-canvas',
      });
      const canvasPartnerDimensions: CanvasPartnerDimensions = { dimensions: [], partners: [] };
      const enabledDimensions = {};

      result.items.forEach((partner: ICanvasPartner) => {
        if (partner.isEnabled) {
          partner.dimensions.forEach((dimension: ICreativeDimensionBoards) => {
            if (dimension.isEnabled) {
              const { masterId } = dimension;

              const dimensionName = dimension.name.split('x').join(' x ');
              if (enabledDimensions[dimensionName]) {
                enabledDimensions[dimensionName].partner.push(partner.name);
              } else {
                enabledDimensions[dimensionName] = {
                  ...dimension,
                  id: `P_${dimension.id}`,
                  masterId: `M_${masterId}`,
                  name: dimension.name.split('x').join(' x '),
                  partner: [partner.name],
                };
              }
            }
          });
          canvasPartnerDimensions.partners.push({ id: partner.id, name: partner.name });
        }
      });

      // Add all enabled dimensions to dimensions array
      canvasPartnerDimensions.dimensions = Object.values(enabledDimensions);

      // Sort Partners by alphabitical Order for filter display
      canvasPartnerDimensions.partners.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) { return -1; } if (nameA > nameB) { return 1; } return 0;
      });
      this.canvasPartnerDimensions = canvasPartnerDimensions;
    },

    async fetchCustomDimensions() {
      const creativeStore = useCreativeStore();
      const result = await requests.get(`/creatives/dimensions?q=creativeId:eq:${creativeStore.currentCreative.id}`);
      if (result?.items.length === 0) return;
      this.customDimensions = result.items.map((dimension) => {
        const { masterId } = dimension;

        return ({ ...dimension, id: `C_${dimension.id}`, masterId: `M_${masterId}` });
      });
    },

    async addCustomDimensions(width : number, height : number) : Promise<void> {
      const creativeStore = useCreativeStore();
      const companyId = useCompanyStore().currentCompanyId;
      const newCustomDimension = await requests.post('/creatives/dimensions', {
        name: `${width} x ${height}`,
        width: `${width}`,
        height: `${height}`,
        companyId,
        creativeId: creativeStore.currentCreative.id,
      });
      const { masterId } = newCustomDimension;

      newCustomDimension.id = `C_${newCustomDimension.id}`;
      newCustomDimension.masterId = `M_${masterId}`;
      this.customDimensions.push(newCustomDimension);
      creativeStore.currentCreative.settings?.boardsToBeExported.push(newCustomDimension.id);
    },

    handleShiftSelection(selectedPaths: string[], newSelection: string[]) {
      if (selectedPaths.length === 0 || newSelection.length === 0) {
        return [];
      }

      const selectedPathsLast = selectedPaths[selectedPaths.length - 1];
      const newSelectionLast = newSelection[newSelection.length - 1];
      const selectedPathsMap = extractDataPath(selectedPathsLast);
      const newSelectionMap = extractDataPath(newSelectionLast);
      const newPaths = new Set<string>();

      // check if selectedPaths and newSelection are in the same type if not return newSelection
      if (selectedPathsMap.has('frames') && newSelectionMap.has('frames')
        && selectedPathsMap.has('instances') !== newSelectionMap.has('instances')) {
        return newSelection;
      }
      // check if selectedPaths and newSelection are the same master id if not return newSelection
      if (selectedPathsMap.has('masters') && newSelectionMap.has('masters')
        && selectedPathsMap.get('masters') !== newSelectionMap.get('masters')) {
        return newSelection;
      }

      if (selectedPathsMap.has('instances') && newSelectionMap.has('instances')) {
        const instances = this.instances[selectedPathsMap.get('frames')];
        const currentIndex = instances.findIndex((i) => i.id === selectedPathsMap.get('instances'));
        const newIndex = instances.findIndex((i) => i.id === newSelectionMap.get('instances'));

        // check if in the same frame
        if (currentIndex === -1 || newIndex === -1) return newSelection;

        if (currentIndex <= newIndex) {
          for (let i = currentIndex; i <= newIndex; i += 1) {
            newPaths.add(`masters[${selectedPathsMap.get('masters')}].frames[${selectedPathsMap.get('frames')}].instances[${instances[i].id}]`);
          }
        } else {
          for (let i = currentIndex; i >= newIndex; i -= 1) {
            newPaths.add(`masters[${selectedPathsMap.get('masters')}].frames[${selectedPathsMap.get('frames')}].instances[${instances[i].id}]`);
          }
        }
        return Array.from(newPaths);
      }

      if (selectedPathsMap.has('frames') && newSelectionMap.has('frames')) {
        const isMaster = newSelectionMap.get('masters').startsWith('M_');

        const framesList = isMaster
          ? this.frames.map((frame) => frame.id)
          : this.orders[newSelectionMap.get('masters')]
          ?? this.frames.map((frame) => frame.id);

        const currentIndex = framesList.indexOf(selectedPathsMap.get('frames'));
        const newIndex = framesList.indexOf(newSelectionMap.get('frames'));

        // return all frames between the current and new frame
        if (currentIndex <= newIndex) {
          for (let i = currentIndex; i <= newIndex; i += 1) {
            newPaths.add(`masters[${selectedPathsMap.get('masters')}].frames[${framesList[i]}]`);
          }
        } else {
          for (let i = currentIndex; i >= newIndex; i -= 1) {
            newPaths.add(`masters[${selectedPathsMap.get('masters')}].frames[${framesList[i]}]`);
          }
        }
      }

      return Array.from(newPaths);
    },

    setSelectedPath(newSelectedPath: string | string[], isMultiSelect = false, isShiftPressed = false): void {
      const editorStore = useEditorStore();

      if (this.bulkMode && isMultiSelect) {
        // Displays Notification when user attemps to use MultiSelect in BulkMode
        showNotify('negative', 'Cannot Multi-select in Bulk Mode');
      }

      // get the new paths and remove the empty ones
      const newPaths = (Array.isArray(newSelectedPath) ? newSelectedPath : [newSelectedPath]).filter((path) => !!path);
      if (isMultiSelect && !this.bulkMode) {
        const newSelection = isShiftPressed
          ? this.handleShiftSelection(this.selectedPaths, newPaths)
          : validateSelectedPaths(this.selectedPaths, newPaths, editorStore.isCtrlPressed);
        this.selectedPaths = [...new Set(newSelection)];
      } else if (this.bulkMode) {
        const regex = /\[.*?\]/;
        // generate new paths and put original selected as first one
        const bulkPaths = this.settings.masterDimensions.map(
          (masterId) => newPaths[0].replace(regex, `[${masterId}]`),
        ).filter((path) => path !== newPaths[0]);

        this.selectedPaths = [newPaths[0], ...bulkPaths];
      } else {
        this.selectedPaths = newPaths;
      }

      if (this.selectedPaths?.flat()?.[0]?.includes('instances')) this.editorSelectionType = 'instance';
      else this.editorSelectionType = 'frame';

      if (newSelectedPath !== '' && this.activeLeftDrawerTab === CanvasEditorView.MASTERS) {
        this.setLoaderState(false);
      }
    },

    setLoaderState(state: boolean): void {
      this.showLoader = state;
    },

    removeSelectedPath(removedPath: string): void {
      const creativeStore = useCreativeStore();

      const newPaths = this.selectedPaths.filter((path) => path !== removedPath);
      if (newPaths.length === 0) this.setSelectedPath('');
      else this.selectedPaths = [...newPaths];

      creativeStore.showCreativeSettings = false;
    },

    updateActiveLeftDrawerTab(tab: CanvasEditorView) {
      const editorStore = useEditorStore();
      if (tab !== this.activeLeftDrawerTab) {
        editorStore.highlightedNode = {};
        this.highlightedFrameNode = {};
        editorStore.removeAllHighlights();
        this.bulkMode = false;
      }
      this.activeLeftDrawerTab = tab;
    },
    clearVariantsToExclude() {
      const creativeStore = useCreativeStore();
      creativeStore.currentCreative.settings.variantBoardIdsToExclude = {};
    },
    setVariantsToExclude(value) {
      const creativeStore = useCreativeStore();
      creativeStore.currentCreative.settings.variantBoardIdsToExclude = value;
    },
    deselectAllVariantsToExport() {
      const creativeStore = useCreativeStore();
      const variantIds = Object.keys(this.variantArtboards);
      for (let index = 0; index < variantIds.length; index += 1) {
        creativeStore.currentCreative.settings.variantBoardIdsToExclude[variantIds[index]] = [...creativeStore.currentCreative.settings.boardsToBeExported];
      }
    },
    updateMasterDimensionSettings(value) {
      this.settings.masterDimensions = value;
      this.masters.forEach(({ id }, index) => {
        this.masters[index].isEnabled = this.settings.masterDimensions.includes(id);
      });
    },

    isDimensionMasterDisabled(selectedMasters = undefined) {
      const creativeStore = useCreativeStore();
      const { currentCreative: { settings } } = creativeStore;
      // Find anyboard with masterboard that is not enabled.
      return !!settings.boardsToBeExported.find((boardId) => {
        const masterBoards = boardId[0] === 'C' ? this.customDimensions : this.canvasPartnerDimensions.dimensions;
        const masterId = masterBoards.find((dimension) => dimension.id === boardId)?.masterId;
        if (!masterId) return true;

        // Search array of masters we pass in or current selected masters in store
        return selectedMasters ? !selectedMasters.includes(masterId) : !settings.masterDimensions.includes(masterId);
      });
    },

    setCanvasData(creative, path, newValue): void {
      const extractedData = extractDataPath(path);
      const keys = [...extractedData.keys()];

      if (extractedData.has('instances')) {
        const currentInstance = creative.data?.instances[extractedData.get('frames')]
          .find((i) => i.id === extractedData.get('instances'));

        if (currentInstance) {
          // remove all keys before instances
          const instancesIndex = keys.indexOf('instances');
          keys.splice(0, instancesIndex + 1);

          set(currentInstance, keys.join('.'), newValue);
          return;
        }
      }

      if (extractedData.has('frames')) {
        const frame = creative.data?.frames.find((f) => f.id === extractedData.get('frames'));
        if (frame) {
          // remove all keys before frame
          const framesIndex = keys.indexOf('frames');
          keys.splice(0, framesIndex + 1);

          set(frame, keys.join('.'), newValue);
        }
      }
    },

    // returns current selected boards height and width
    getCurrentBoardDimension(boardId :string) :LayoutDimension | MasterDimension {
      return (boardId[0] === 'M') ? this.masters.find((n) => n.id === boardId)
        : [...this.customDimensions, ...this.canvasPartnerDimensions.dimensions].find((n) => n.id === boardId);
    },

    /**
   * Sets a value of nested key string inside an Object.
   * @param {[Object]} obj   Object to set the nested key
   * @param {[Array]} keys  An array to describe the keys(Ex: ['a', 'b', 'c'])
   * @param {[Object]} value Any value
   */
    setNestedKey(obj, keys, value): void {
      keys.forEach((x, i) => {
        if (x && typeof x !== 'undefined') {
          if (!obj[x] || typeof obj[x] === 'undefined') obj[x] = {};
          const v = typeof obj?.[x] === 'object' ? JSON.parse(JSON.stringify(toRaw(obj[x]))) : obj?.[x];
          obj[x] = i === keys.length - 1 ? value : v;
          obj = v;
        }
      });
    },

    /**
  * Function to set frame and instance data at the iteration level so that it is
  * referenced by all relevant boards
  */
    setIterationData(path, newValue) {
      const extractedData = extractDataPath(path);
      const extractedBoard = extractedData.get('masters');
      const extractedFrame = extractedData.get('frames');
      const extractedInstance = extractedData.get('instances');
      const extractedProperty = [...extractedData.keys()]
        .filter((key) => extractedData.get(key) === null);

      if (extractedData.has('instances')) {
        this.setNestedKey(this.iterations, [extractedFrame, extractedInstance, extractedBoard, ...extractedProperty], toRaw(newValue));
        return;
      }

      this.setNestedKey(this.iterations, [extractedFrame, extractedBoard, ...extractedProperty], toRaw(newValue));
    },

    deleteIterationKey(path) {
      const extractedData = extractDataPath(path);
      const extractedBoard = extractedData.get('masters');
      const extractedFrame = extractedData.get('frames');
      const extractedInstance = extractedData.get('instances');
      const extractedProperty = [...extractedData.keys()]
        .filter((key) => extractedData.get(key) === null);

      extractedProperty.forEach((key) => {
        if (extractedInstance) {
          delete this.getCurrentCreative.data.iterations[extractedFrame][extractedInstance][extractedBoard][key];
        } else {
          delete this.getCurrentCreative.data.iterations[extractedFrame][extractedBoard][key];
        }
      });
    },

    /**
   * Function to update top level data properties for frames and instances
   */
    updateDataProperty(array: { path: string, value: object | [] | string | number }[]) {
      const editorStore = useEditorStore();
      const creativeStore = useCreativeStore();
      editorStore.interactions.updates = true;
      array.forEach((item) => {
        this.setCanvasData(creativeStore.currentCreative, item.path, item.value);
      });
      editorStore.interactions.updates = false;
    },

    /**
   * Function to update iteration data properties
   */
    updateIterationDataProperty(array: { path: string, value: boolean | object | [] | string | number }[]) {
      const editorStore = useEditorStore();

      editorStore.interactions.updates = true;
      array.forEach((item) => {
        if (item.value !== undefined) {
          this.setIterationData(item.path, item.value);
        } else {
          this.deleteIterationKey(item.path); //  deleting iteration makes it refer to master when getting data.
        }
      });
      setTimeout(() => {
        editorStore.triggerTransformer = true;
        editorStore.interactions.updates = false;
      }, 5);
    },

    /**
   * remove frame from the order data
   */
    removeFrameFromOrders(frameIds: string[]) {
      let orders = { ...this.orders };
      frameIds.forEach((frameId) => {
        const cleanedOrders = {};
        Object.keys(orders)?.forEach((boardId) => {
          const insertIndex = orders[boardId].indexOf(frameId);
          const clonedBoardOrder = [...orders[boardId]];
          if (insertIndex !== -1) clonedBoardOrder.splice(insertIndex, 1);
          cleanedOrders[boardId] = clonedBoardOrder;
        });
        orders = cleanedOrders;
      });
      return orders;
    },

    removeFrames(array : { path :string }[]) {
      let postRemovalPath = '';
      const frameIdArray = [];
      const itemsToDelete = array.map((n) => {
        const pathData = extractDataPath(n.path);
        const frameId = pathData.get('frames');
        const frameIndex = this.frames.findIndex((each) => each.id === frameId);

        frameIdArray.push(frameId);

        const deletedAssets: IAssetData[] = [];

        this.instances[frameId]?.forEach((each) => {
          if (!each.assetId) return;
          deletedAssets.push(this.canvasData.assets[each.assetId]);
        });

        return ({
          path: n.path,
          value: this.frames[frameIndex],
          deletedInstances: this.instances[frameId],
          index: frameIndex,
          deletedIterations: this.iterations[frameId] || {},
          deletedAssets,
        });
      });

      itemsToDelete.forEach(({ path, value }) => {
        const extractedData = extractDataPath(path);
        const extractedBoard = extractedData.get('masters');
        postRemovalPath = buildCanvasPath(extractedBoard);

        this.canvasData.frames = JSON.parse(JSON.stringify(toRaw(this.frames))).filter((frame) => frame.id !== value.id);
        if (this.instances[value.id]) {
          this.instances[value.id].forEach((instance) => {
            if (instance.assetId) delete this.canvasData.assets[instance.assetId];
          });
          delete this.instances[value.id];
        }

        delete this.canvasData.iterations[value.id];
      });

      // update the new orders
      const newOrders = this.removeFrameFromOrders(frameIdArray);
      if (newOrders) {
        this.canvasData.orders = newOrders;
      }

      this.setSelectedPath(postRemovalPath);
      return itemsToDelete;
    },

    undoRemoveFrames(array: {
      path: string,
      value: ICreativeBaseData,
      index: number,
      deletedInstances: IInstance[],
      deletedAssets: IAssetData[],
      deletedIterations
    }[], orders: { [boardId: string]: (string | number)[] } | false) {
      const paths = [];

      // undo remove frame needs to go from backward to avoid messing up index
      const frameArr = [...array]; // prevent mutations
      let frame = frameArr.pop();
      while (frame) {
        const { path, value, index, deletedInstances, deletedAssets, deletedIterations } = frame;

        this.frames.splice(index, 0, value);
        this.instances[value.id] = deletedInstances;
        this.iterations[value.id] = deletedIterations;

        deletedAssets.forEach((each) => {
          this.canvasData.assets[each.id] = each;
        });

        this.setSelectedPath(path);
        paths.unshift({ path });

        frame = frameArr.pop();
      }

      // undo order changes
      if (orders) this.canvasData.orders = { ...orders };

      return paths;
    },

    getIterationData(creative, path): Partial<IInstance> {
      // check if we have iteration data for frame instance board combination
      // frameID.boardID
      // frameID.instanceID.boardID
      if (!creative?.data?.iterations) return undefined;

      const extractedData = extractDataPath(path);
      const extractedBoard = extractedData.get('masters');
      const extractedFrame = extractedData.get('frames');
      const extractedInstance = extractedData.get('instances');

      return this.iteration(extractedFrame, extractedInstance, extractedBoard);
    },

    getIterationDataProperty(creative, path) {
      const extractedData = extractDataPath(path);

      const keys = [...extractedData.keys()];
      const iterationData = this.getIterationData(creative, path);
      if (objectIsEmpty(iterationData)) {
        return undefined;
      }

      // get the last key as this will be the property we want to get
      const property = keys.pop();
      return iterationData[property];
    },

    isPathSelected(path: string): boolean {
      // check if path is in selectedPaths
      return this.selectedPaths.includes(path);
    },
  },
});

export default useCanvasStore;
