import { AxiosInstance } from 'axios';

type ResponsePart = {
  PartNumber: number;
  signedUrl: string;
};

/**
 * PSD Uploader class
 * @param {AxiosInstance} api - Axios instance
 * @param {File} file - File to upload
 */
export class Uploader {
  private api;

  // this must be bigger than or equal to 5MB,
  // otherwise AWS will respond with:
  // 'Your proposed upload is smaller than the minimum allowed size'
  private chunkSize = (1024 * 1024 * 150); // 150MB

  // number of parallel uploads
  private threadsQuantity = 5;

  private aborted = false;

  private uploadedSize = 0;

  private progressCache = {};

  private activeConnections = {};

  // adjust the timeout value to activate exponential backoff retry strategy
  private timeout = 0;

  private file;

  private parts:ResponsePart[] = [];

  private uploadedParts = [];

  private fileId = null;

  private fileKey = null;

  private timeStarted:number = 0;

  private onProgressFn = (progress: any) => Promise<any>;

  private onErrorFn = (error: any) => Promise<any>;

  private onFinishFn = (session: any) => Promise<any>;

  constructor(api, file) {
    this.api = api;
    this.file = file;
  }

  start() {
    this.timeStarted = Date.now();
    void this.initialize();
  }

  async initialize() {
    try {
      const initializeReponse = await this.api.request({
        url: '/initialize',
        method: 'POST',
        data: {
          name: this.file.name,
        },
      });

      const fileDataOutput = initializeReponse.data;
      this.fileId = fileDataOutput.fileId;
      this.fileKey = fileDataOutput.fileKey;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);
      const urlsResponse = await this.api.request({
        url: '/multipass',
        method: 'POST',
        data: {
          fileId: this.fileId,
          fileKey: this.fileKey,
          parts: numberOfparts,
        },
      });

      const newParts = urlsResponse.data.parts as ResponsePart[];
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext(retry = 0) {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) void this.complete();
      return;
    }

    const part = this.parts.pop();

    if (this.file && part) {
      const sentSize = (part.PartNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.upload(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          if (retry <= 6) {
            retry += 1;
            const wait = (ms) => new Promise((res) => { setTimeout(res, ms); });

            // exponential backoff retry before giving up remove after mvp
            console.log(`Part#${part.PartNumber} failed to upload, backing off ${2 ** retry * 100} before retrying...`);

            void wait(2 ** retry * 100)
              .then(() => {
                this.parts.push(part);
                this.sendNext(retry);
              });
          } else {
            console.log(`Part#${part.PartNumber} failed to upload, giving up`);
            void this.complete(error);
          }
        });
    }
  }

  async complete(error?) {
    if (error && !this.aborted) {
      this.onErrorFn(error);
      return;
    }

    if (error) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (err) {
      this.onErrorFn(err);
    }
  }

  async sendCompleteRequest() {
    if (!this.fileId || !this.fileKey) return;
    const response = await this.api.request({
      url: '/finalize',
      method: 'POST',
      data: {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      },
    });

    this.onFinishFn(response.data.session);
  }

  handleProgress(part, event) {
    if (this.file) {
      if (event.type === 'progress' || event.type === 'error' || event.type === 'abort') {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo + this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);
      const timeElapsed = Date.now() - this.timeStarted; // Assuming that timeStarted is a Date Object
      const uploadSpeed = sent / (timeElapsed / 1000); // Upload speed in second

      this.onProgressFn({
        sent,
        total,
        percentage,
        time: (total - sent) / uploadSpeed,
      });
    }
  }

  async upload(file, part, sendChunkStarted) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      const throwXHRError = (error, abortPart, abortFx) => {
        delete this.activeConnections[abortPart.PartNumber - 1];
        reject(error);
        window.removeEventListener('offline', abortFx);
      };

      if (this.fileId && this.fileKey) {
        if (!window.navigator.onLine) reject(new Error('System is offline'));

        const xhr = new XMLHttpRequest();
        this.activeConnections[part.PartNumber - 1] = xhr;
        xhr.timeout = this.timeout;
        sendChunkStarted();

        const progressListener = this.handleProgress.bind(this, part.PartNumber - 1);

        xhr.upload.addEventListener('progress', progressListener, false);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);
        const abortXHR = () => xhr.abort();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.PartNumber,
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.PartNumber - 1];
              window.removeEventListener('offline', abortXHR);
            }
          }
        };

        xhr.onerror = (error) => {
          throwXHRError(error, part, abortXHR);
        };

        xhr.ontimeout = (error) => {
          throwXHRError(error, part, abortXHR);
        };

        xhr.onabort = () => {
          throwXHRError(new Error('Upload canceled by user or system'), part, abortXHR);
        };

        window.addEventListener('offline', abortXHR);
        xhr.send(file);
      }
    });
  }

  onFinish(onFinish) {
    this.onFinishFn = onFinish;
    return this;
  }

  onProgress(onProgress) {
    this.onProgressFn = onProgress;
    return this;
  }

  onError(onError) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
