import { AxiosResponse } from 'axios';
/**
 * Helper function that updates axios cache
 * @param cache => CachedStorageValue
 * @param response => AxiosResponse
 * @param property => The property name to update
 * @param conditionId => The condition id to update
 */
export function updateAxiosCache(
  cache: any,
  response: AxiosResponse,
  property: string,
  conditionId: string | number,
): any {
  if (cache.state !== 'cached') return 'ignore';
  const newCache = { ...cache };
  // the cached index will be different to the state management index
  const userIndex = newCache.data.data[property].findIndex((n) => {
    const id = n.id ? n.id : n;
    return id === conditionId;
  });

  // if not in the cache then add it
  if (userIndex === -1) newCache.data.data[property].push(response.data);

  // otherwise replace the value
  else newCache.data.data[property][userIndex] = response.data;

  return newCache;
}

/**
 * Helper function that removes data from axios cache
 * @param cache => CachedStorageValue
 * @param property => The property name to update
 * @param conditionId => The condition id to update
 */
export function removeFromAxiosCache(
  cache: any,
  property: string,
  conditionId: string | number,
): any {
  if (cache.state !== 'cached') return 'ignore';
  const newCache = { ...cache };

  newCache.data.data[property] = cache.data.data[property].filter((n) => {
    const id = n.id ? n.id : n;
    return id !== conditionId;
  });
  return newCache;
}

/**
 * Helper function that replace object data from axios cache
 * @param cache => CachedStorageValue
 * @param newData => AxiosResponse
 */
export function replaceAxiosCache(
  cache: any,
  newData: any,
): any {
  if (cache.state !== 'cached') return 'ignore';
  const newCache = { ...cache };
  newCache.data = newData;
  return newCache;
}
