import { defineStore } from 'pinia';
import { requests } from '@/common/plugins/axios';
import { Creative } from '@/views/creatives/interfaces';
import { Orientation } from '@/common/enums';
import { useCompanyStore } from '@/common/store/company';
import { useHistoryStore } from '@/common/store/history';

// small function to update incoming creative data with any missing values
const formatPlayData = (data: Creative) => {
  const clone = { ...data };

  // check if the creative is for play
  // if doesn't contain scenes, return
  if (!clone.scenes) return clone;

  // corrects migrated playables orientation on load
  if (clone.settings.orientation !== clone.settings.orientationLock && clone.settings.orientationLock !== Orientation.LANDSCAPE_PORTRAIT) {
    clone.settings.orientation = clone.settings.orientationLock;
  }

  return clone;
};

const getFonts = (data: Creative['data']['iterations']) => {
  const iterations = { ...data };
  const fonts = {};

  if (!data) return { iterations, fonts };

  Object.entries(data).forEach(([key, value]) => {
    Object.entries(value).forEach(([k, v]) => {
      const clone = { ...v };
      if (!v || typeof v !== 'object') return;
      if (!('family' in v) || !('url' in v)) {
        Object.entries(clone).forEach(([m, n]) => {
          if (typeof n !== 'object' || Array.isArray(n)) return;
          if (!n) return;
          const c = { ...n };
          if (!('family' in n) || !('url' in n)) return;

          fonts[n.family] = n.url;
          delete c.url;

          iterations[key][k][m] = c;
        });
      }

      if ('family' in v && typeof v.family === 'string') {
        fonts[v.family] = v.url;
        // @ts-ignore
        delete clone.url;
        iterations[key][k] = clone;
      }
    });
  });

  return { iterations, fonts };
};

const formatCanvasData = (data: Creative) => {
  const clone = { ...data };

  // check if the creative is for canvas
  // if doesn't contain data, return
  if (!clone.data) return clone;

  // move font urls to global fonts object
  // delete font urls from previous iteration data
  if (!clone.data.fonts || !Object.values(clone.data.fonts).length) {
    const { iterations, fonts } = getFonts(clone.data.iterations);
    clone.data.fonts = fonts;
    clone.data.iterations = iterations;
  }

  return clone;
};

const formatCreativeData = (data: Creative) => {
  if ('scenes' in data) return formatPlayData(data);
  if ('data' in data) formatCanvasData(data);
  return data;
};

export const useCreativeStore = defineStore('creative', {
  state: () => ({
    currentCreative: {} as Creative,
    cutItem: null, // the cut item to be used for pasting
    isSaving: false as string | boolean, // isSaving flag can be bool or string date
    showCreativeSettings: true, // show the settings panel for the current creative
    historyMode: false,
    firstLoad: true,
    users: [], // used to store active users editing a creative
  }),

  actions: {
    /**
     * Fetch currentCreative data by Id and set its data
     * to currentCreative state variable.
     * @param id => Creative id to fetch
     */
    async fetchCreativeById(id: string, historyId?: string): Promise<void> {
      const historyStore = useHistoryStore();

      // reset the history store if we are loading a different creative
      // !TODO $reset the actions if switching product
      if (id !== this.currentCreative.id) {
        historyStore.$reset();
        historyStore.resetCommands();
      }

      const response: Creative = await requests.get(`/creatives/${id}?includes=product&includes=company:id`, {
        id: `fetch-creative-${id}`,
      });

      if (response.company) {
        const companyId = response.company.id;
        const companyStore = useCompanyStore();
        // Check currentCompanyId
        if (companyStore.currentCompanyId !== companyId) {
          await companyStore.selectCompany(companyId);
        }
      }

      if (this.historyMode) {
        const historyEntry = await requests.get(`/creatives/history/${historyId}?productId=${response.type.id}`);
        response.scenes = historyEntry.scenes;
        response.settings = historyEntry.settings;
      }

      this.currentCreative = formatCreativeData(response);
    },
  },
});

export default useCreativeStore;
