import { defineStore } from 'pinia';
import { requests } from '@/common/plugins/axios';
import { filterByName, filterByKeys, updateAxiosCache } from '@/common/utils';
import { Company, CompanyUser } from '@/views/companies/interfaces';
import { useCompanyUserStore } from './companyUser';

export const userAdminStore = defineStore('admin', {
  state: () => ({
    users: [] as CompanyUser[],
    companies: [] as Company[],
    deletedUsers: [] as CompanyUser[],
    selectedUser: {} as CompanyUser,
    initialUserFormData: '',
  }),

  getters: {},

  actions: {
    /**
     * Set comapnies from the companies endpoint
     * @params name => the company name to search for
     */
    async fetchDeletedCompanies(name?: string): Promise<void> {
      const result = await requests.get('/companies?includes=deletedBy:createdAt.user:email&deleted=only', {
        id: 'fetch-deleted-companies',
      });

      // Filter in results => TODO: Update when endpoint will be ready.
      if (name?.length > 0) {
        this.companies = filterByName(result.items, name) as Company[];
      } else {
        this.companies = result.items;
      }
    },

    /**
     * Restore a give company
     * @params id => the id of the company to restore
     */
    async restoreCompany(id: string) {
      await requests.post(`/companies/${id}/restore`, {}, {
        cache: {
          update: {
            'fetch-companies': 'delete',
            'fetch-deleted-companies': 'delete',
          },
        },
      });

      this.companies = this.companies.filter((n) => n.id !== id);
    },

    /**
     * Set users from the users endpoint
     * @params name => the user name to search for
     */
    async fetchDeletedUsers(name?: string): Promise<void> {
      const result = await requests.get('/users?includes=deletedBy:createdAt.user:email&deleted=only&includes=companies', {
        id: 'fetch-deleted-users',
      });

      // Filter in results => TODO: Update when endpoint will be ready.
      if (name?.length > 0) {
        this.deletedUsers = filterByKeys(result.items, ['firstName', 'lastName', 'email'], name);
      } else {
        this.deletedUsers = result.items;
      }
    },

    /**
     * Restore a give user
     * @params id => the id of the user to restore
     */
    async restoreUser(user: CompanyUser) {
      const userCompany = user.companies?.[0] as Partial<Company>;

      const caches = {
        'fetch-craftsman-users': 'delete',
        'fetch-deleted-users': 'delete',
      };

      if (userCompany) {
        caches[`fetch-company-users-${userCompany?.id}`] = 'delete';
      }

      await requests.post(`/users/${user.id}/restore`, {}, {
        cache: {
          update: caches,
        },
      });

      // Restore in companyUser store
      const companyUserStore = useCompanyUserStore();
      const deletedUserIndex = companyUserStore.deletedUsers.findIndex((id) => user.id === id);

      if (deletedUserIndex !== -1) companyUserStore.deletedUsers.splice(deletedUserIndex, 1);

      this.deletedUsers = this.deletedUsers.filter((n) => n.id !== user.id);
    },

    /**
     * Force delete user, removes them from the db
     * @params id => the id of the user to delete
     */
    async forceDeleteUser(id: string) {
      await requests.delete(`/users/${id}?force=true`, {
        cache: {
          update: {
            'fetch-craftsman-users': 'delete',
            'fetch-company-users': 'delete',
            'fetch-deleted-users': 'delete',
          },
        },
      });

      this.deletedUsers = this.deletedUsers.filter((n) => n.id !== id);
    },

    /**
     * Set users from the users endpoint
     * @params name => the user name to search for
     */
    async fetchCraftsmanUsers(name?: string): Promise<void> {
      const result = await requests.get('/users?q=email:ends:@craftsmanplus.com', {
        id: 'fetch-craftsman-users',
      });

      // Filter in results => TODO: Update when endpoint will be ready.
      if (name?.length > 0) {
        this.users = filterByKeys(result.items, ['firstName', 'lastName', 'email'], name);
      } else {
        this.users = result.items;
      }
    },

    /**
     * Submit user data to the API
     */
    async submitUser(): Promise<void> {
      const user = this.selectedUser;

      if (!user.id) {
        const newUser = await requests.post('/users', { ...user }, {
          cache: {
            update: {
              'fetch-craftsman-users': (cache, response) => updateAxiosCache(cache, response, 'items', user.id),
            },
          },
        });

        this.users.push(newUser);
      } else {
        // Current user index
        // Make update
        const updatedUser = await requests.patch(`/users/${user.id}`, { ...user }, {
          cache: {
            update: {
              'fetch-craftsman-users': (cache, response) => updateAxiosCache(cache, response, 'items', user.id),
            },
          },
        });

        const userIndex = this.users.findIndex((n) => n.id === user.id);
        // Replace new value in Users array
        this.users[userIndex] = updatedUser;
      }
    },

    /**
     * Set users from the users endpoint
     * @params name => the user name to search for
     */
    setInitialFormData(newUser: Partial<CompanyUser>) {
      this.initialUserFormData = JSON.stringify({ ...newUser });
    },

    /**
     * Soft delete a user in db
     * @params id => the users id
     */
    async deleteUser(id: string) {
      await requests.delete(`/users/${id}`, {
        cache: {
          update: {
            'fetch-craftsman-users': 'delete',
            'fetch-deleted-users': 'delete',
          },
        },
      });

      this.users = this.users.filter((n) => n.id !== id);
    },

    /**
     * Reset the users data in the store
     */
    resetUser() {
      this.initialUserFormData = '';
      this.selectedUser = {} as CompanyUser;
    },

    /**
     * Make a POST request to allow user to update his password.
     * @param userId => User id
     */
    async resetUserPassword(userId: string) {
      await requests.post(`/users/${userId}/reset`);
    },
  },
});

export default userAdminStore;
