export enum CanvasTypesWithUnknown {
  BACKGROUND = 'background',
  IMAGE = 'image',
  TEXT = 'text',
  SHAPE = 'shape',
  UNKNOWN = 'unknown',
}

export enum ImportStep {
  UPLOADING,
  PROCESSING,
  IMPORTING,
  COMPLETE,
  ERROR,
}

export enum Alignment {
  LEFT = 0,
  RIGHT = 1,
  CENTER = 2,
  JUSTIFY = 3,
}
