import { createI18n } from 'vue-i18n';
import en from '../../locales/en.json';

export const i18n = createI18n({
  legacy: false,
  // https://github.com/intlify/vue-i18n-next/issues/350
  globalInjection: true,
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
  },
});

export default i18n;
