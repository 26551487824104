type ESModule = {
  default: any;
};

const modules = import.meta.glob('@/common/cmp/*.ts', { eager: true });

export default function loadCMPComponents(app) {
  if (!modules) return;
  // eslint-disable-next-line guard-for-in
  for (const path in modules as Record<string, ESModule>) {
    const componentName = path.split('/').at(-1).split('.')[0] as string;
    app.component(`cmp-${componentName.toLowerCase()}`, (modules[path] as ESModule).default);
  }
}
