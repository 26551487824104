export enum UserRole {
  SYSTEM_ADMIN = 'system-admin',
  CRAFTSMAN_ADMIN = 'craftsman-admin',
  COMPANY = 'company-admin',
  CREATOR = 'creator',
  AUTHOR = 'author',
  CONTRIBUTOR = 'contributor',
}

export interface IUserRole {
  id: number;
  name: UserRole;
}
