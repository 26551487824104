type IdObject = { id: string };

/**
 * Function that receive an entity list and the entity
 * id and return an array filtered by id value.
 * @param list => Entity array
 * @param id => Entity id
 */
export function removeItemInArray<T extends { id: string } | string>(list: T[], id: string): T[] {
  if (list.length === 0) return [];

  return list.filter((item) => {
    if (typeof item === 'string') return item !== id;
    return item.id !== id;
  });
}

export default removeItemInArray;
