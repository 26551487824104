const getItem = (obj: object, path: string, defaultValue = undefined) => {
  if (!path) return obj;
  const travel = (regexp: RegExp) => String.prototype.split
    .call(path, regexp)
    .filter(Boolean)
    .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
};

export const get = (obj: object, paths: string[] | string, defaultValue = undefined) => {
  const values = Array.isArray(paths) ? paths : [paths];
  const array = values.map((path) => getItem(obj, path, defaultValue));
  return array.length <= 1 ? array[0] : array;
};

export default get;
