import { createNewSortInstance } from 'fast-sort';
import { Column, ListItem } from '@/common/interfaces';

function isValidDate(input: string) {
  if (/[A-Za-z]+[-_ ]+[0-9]/.test(input) || +input === 0) return false; // solution for string like number-12
  if (/[0-9]/.test(input) && `${input}`.length < 10) return false; // solution for string 2 or 43 -> not valid date
  const isDate = Date.parse(input);
  return !Number.isNaN(isDate);
}

export function sortItems<T extends ListItem | File>(list: T[], sortOption: Partial<Column>): T[] {
  if (list.length === 0) return [];
  if (!sortOption.sortBy) return list;

  const comparer = new Intl.Collator('en', { numeric: true, sensitivity: 'base', caseFirst: 'lower' }).compare;

  const data = [...list];
  const { desc } = sortOption;
  const order = desc ? 'desc' : 'asc';
  const sorting = [
    { asc: (u) => u.order, comparer }, // sort by order first, allows to order folders before ads
    { desc: (u) => (u[sortOption.sortBy] === 'N/A' ? 0 : 1), comparer }, // sort N/A values to the bottom of the table
    {
      [order]: (u) => {
        const value = u[sortOption.sortBy];
        if (isValidDate(value)) return Date.now() - Date.parse(value);
        return value;
      },
    } as { 'asc': () => void }, // sort by provided key
    { asc: (u) => u?.name, comparer }, // finally sort by name key
  ];

  const customSort = createNewSortInstance({ comparer, inPlaceSorting: true });

  return customSort(data).by(sorting);
}

export default sortItems;
