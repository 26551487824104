export enum FileType {
  ANY = '',
  VIDEO = 'video',
  IMAGE = 'image',
  AUDIO = 'audio',
  FONT = 'font',
  SEQUENCE = 'sequence',
}

export default FileType;
