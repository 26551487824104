import axios from 'axios';
import { defineStore } from 'pinia';
import { EditOptionType, FileType, TrimType } from '@/views/assets/enums';
import { AssetTrimType, CoordinateType, CropType, DimensionType } from '@/views/assets/types';
import { AssetEditData, AssetDimension, AssetEditBody, CropCoordinates, AssetEditResult, AssetProgressResult, AssetOptimize } from '@/views/assets/interfaces';

const instance = axios.create({
  baseURL: import.meta.env.VITE_ASSETS_OPTIMIZER_URI,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': import.meta.env.VITE_ASSETS_API_KEY,
  },
});

export const useAssetEditorStore = defineStore('assetEditor', {
  state: () => ({
    assetName: '',
    componentState: 'global',
    cropLimits: {} as CropCoordinates,
    selectedAsset: {} as AssetEditData,
    assetDimensions: {} as AssetDimension,
    assetPreviewData: {} as AssetEditResult,
    cropperCoordinates: {} as CropCoordinates,
    optimizeOptions: {} as AssetOptimize,
    selectedEditorOptions: [],
    customStencilRatio: null,
    transformSize: 100,
    cropOption: '0' as CropType,
    cropStencilRatio: null,
    // Video
    requestController: new AbortController(),
    trimTypeValue: TrimType.Time,
    trimValues: { time: {}, frame: {} } as AssetTrimType,
    videoImage: '',
    videoProgress: 0,
    isVideoLoading: false,
  }),

  getters: {
    isImageAsset: (state) => state.selectedAsset.type === FileType.IMAGE,

    cropperImage(state) {
      if (this.isImageAsset) return state.selectedAsset.src;

      return state.videoImage;
    },

    currentEditOption(state) {
      const { selectedEditorOptions } = state;
      // Set default option
      if (selectedEditorOptions.length === 0) {
        // const defaultOption = (this.isImageAsset) ? EditOptionType.TRANSFORM : EditOptionType.TRIM;
        const defaultOption = EditOptionType.TRANSFORM;

        this.selectedEditorOptions.push(defaultOption);
      }

      return selectedEditorOptions[0];
    },

    totalFrames(state) {
      if (!state.selectedAsset.metadata) return 0;
      const { frames, frameRate, avgFrameRate, duration } = state.selectedAsset.metadata;
      const rate = frameRate ?? avgFrameRate;
      if (typeof frames === 'number') return frames;

      // Calculate frames
      if ((typeof frames !== 'number') && rate && (duration > 0)) {
        const frameRates = rate.split('/').map((n: string) => +n);
        return Math.round((frameRates[0] / frameRates[1]) * duration);
      }

      return 0;
    },

    needsToCalcDimensions(state) {
      if (this.isImageAsset || !state.selectedAsset.metadata) return false;

      const { height, width } = state.selectedAsset.metadata;

      return (height !== state.cropLimits.height) || (width !== state.cropLimits.width);
    },

    assetRatio(state) {
      const result = { ratio: 1, horizontal: true };

      if (!state.selectedAsset.metadata || this.isImageAsset) return result;

      const { metadata } = state.selectedAsset;
      const [w, h] = metadata.displayAspectRatio.split(':').map((i) => +i);
      // Prevent N/A values
      if (Number.isNaN(w) || Number.isNaN(h)) return result;

      return {
        ratio: (w > h) ? (w / h) : (h / w),
        horizontal: w > h,
      };
    },
  },

  actions: {
    /**
     * Set asset value to 'selectedAsset'.
     * @param asset - Selected asset
     * @param isLayer - To manage save submit
     */
    setSelectedAsset(asset: AssetEditData, isLayer = false) {
      // TODO: Set asset size after loaded (Play edit view)
      this.selectedAsset = asset;
      this.assetName = `${asset.name} (Copy)`;
      // Asset selected from layer
      this.componentState = isLayer ? 'editor' : 'global';
    },

    setEditorOption(option: EditOptionType) {
      this.selectedEditorOptions.unshift(option);
    },

    hideEditorOption(option: EditOptionType) {
      const optionIndex = this.selectedEditorOptions.findIndex((o) => o === option);
      this.selectedEditorOptions.splice(optionIndex, 1);
    },

    setCropperCoordinates(coordinates: CropCoordinates, percentage = 100) {
      Object.assign(this.cropperCoordinates, {
        left: Math.round(coordinates.left * (percentage / 100)),
        top: Math.round(coordinates.top * (percentage / 100)),
        width: Math.round(coordinates.width * (percentage / 100)),
        height: Math.round(coordinates.height * (percentage / 100)),
      });
    },

    updateCoordinate(key: CoordinateType, value: number) {
      this.cropperCoordinates[key] = value;
    },

    setCropLimits(coordinates: CropCoordinates) {
      Object.assign(this.cropLimits, coordinates);
    },

    setAssetSizeDimensions(data: AssetDimension, percentage = 100) {
      Object.assign(this.assetDimensions, {
        width: Math.round(data.width * (percentage / 100)),
        height: Math.round(data.height * (percentage / 100)),
        lock: true,
      });
    },

    updateAssetDimension(key: DimensionType, value: number) {
      this.assetDimensions[key] = value;
    },

    setOptimizeOptions(data: Partial<AssetOptimize>) {
      if (!data.format) return;
      Object.assign(this.optimizeOptions, data);
    },

    /**
     * Update 'cropOption' value and center or
     * maximize stencil depends on selected value.
     * @param value - New aspect ratio
     */
    updateCropPreset(value: CropType) {
      this.cropOption = value;
      // Update custom stencil value
      const [a, b] = this.cropOption.split('/').map((i) => +i);
      this.customStencilRatio = b ? (a / b) : a;
    },

    updateStencilRatio(value: number) {
      this.cropStencilRatio = value;
    },

    /**
     * Set new data to 'assetPreviewData'.
     * @param data - Asset data
     */
    setPreviewData(data: AssetEditResult) {
      Object.assign(this.assetPreviewData, data);
    },

    /**
     * Receive asset coordinates and convert them
     * using asset ratio getter.
     * @param data - Coordinates info
     * @returns - New cordinates calculated with ratio
     */
    convertAssetCoordinates(data: CropCoordinates) {
      if (this.isImageAsset) return data;

      const { width, height, top, left } = data;
      const { ratio, horizontal } = this.assetRatio;

      const result = {
        width: horizontal ? (width / ratio) : width,
        height: horizontal ? height : (height / ratio),
      };

      if (!top && !left) return result;

      return {
        ...result,
        left: horizontal ? (left / ratio) : left,
        top: horizontal ? top : (top / ratio),
      };
    },

    /**
     * Make POST request to edit asset to selected asset src
     * and return edited data result URL and Asset size.
     * @param data - AssetEditBody
     * @returns - Asset edit result
     */
    async processAsset(data: AssetEditBody): Promise<Partial<AssetEditResult>> {
      // https://stackoverflow.com/questions/28150967/typescript-cloning-object
      const requestData = structuredClone(data);
      let { resize, crop } = requestData.options;

      if (!this.isImageAsset) {
        // Convert optimize value for video
        const QUALITY_VALUES = [22, 28, 34, 40, 46, 54];
        const SLIDER_VALUES = [20, 36, 52, 68, 84, 100];
        const currentIndex = SLIDER_VALUES.findIndex((val) => val === data.options.quality);

        // Update data value
        requestData.options.quality = QUALITY_VALUES[currentIndex];

        if (this.needsToCalcDimensions) {
          resize = this.convertAssetCoordinates(requestData.options.resize);
          crop = this.convertAssetCoordinates(requestData.options.crop);
        }
      }

      const response = await instance.post(
        `/${this.selectedAsset.type}s/process`,
        {
          ...requestData,
          options: {
            ...requestData.options,
            resize,
            crop,
          },
        },
        { signal: this.requestController.signal },
      );

      if (this.isImageAsset) {
        return {
          url: { prev: data.input.url, next: response.data.url },
          assetSize: { prev: data.options.assetSize, next: response.data.meta.size },
        };
      }

      return this.checkEditProgress(response.data.id, data);
    },

    setTrimValues(data: AssetTrimType) {
      Object.assign(this.trimValues, data);
    },

    async processVideo(data: AssetEditBody) {
      await instance.post(
        '/videos/process',
        data,
        { signal: this.requestController.signal },
      );
      // console.log(response);
    },

    async checkEditProgress(id: string, data: AssetEditBody): Promise<Partial<AssetEditResult>> {
      return new Promise((resolve) => {
        const { signal } = this.requestController;
        const interval = setInterval(async () => {
          if (signal.aborted) {
            // Stop interval
            clearInterval(interval);

            return;
          }

          this.isVideoLoading = true;

          const result: AssetProgressResult = (await instance.post(
            '/videos/progress',
            { id },
            { signal },
          )).data;

          this.isVideoLoading = false;
          this.videoProgress = result.progress.percent;

          if (result.state === 'COMPLETE' && !signal.aborted) {
            resolve({
              url: { prev: data.input.url, next: result.url },
              assetSize: { prev: data.options.assetSize, next: result.meta.size },
            });
            // Stop interval
            clearInterval(interval);
          }
        }, 5000); // Call every 10 seconds
      });
    },

    setVideoImage(src: string) {
      this.videoImage = src;
    },

    cancelRequests() {
      this.requestController.abort();
    },

    resetVideoProgress() {
      this.videoProgress = 0;
    },
  },
});

export default useAssetEditorStore;
