export enum ProductIds {
  PLAY = 2,
  CANVAS = 1,
  VIDEO = 3,
}

export enum ProductTypes {
  PLAY = 'play',
  CANVAS = 'canvas',
  VIDEO = 'video',
}
