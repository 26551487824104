import type { ILayer } from '@cmp/common';

const getNewGlobalInstance = (globalParent: ILayer) => {
  if (!globalParent) return null;
  const globalLayerId = globalParent.globalLayerId ?? globalParent.id;
  const clone = { globalLayerId, type: globalParent.type, globalLayer: false, config: {} } as ILayer;
  if (globalParent.children) clone.children = globalParent.children;
  return clone;
};

export default getNewGlobalInstance;
