export const getPathFromIndex = (indexes : (string | number)[]) => {
  if (indexes.length === 0) return '';
  let path = `scenes[${indexes.shift()}]`;

  indexes.forEach((index) => {
    path = path.concat(`.children[${index}]`);
  });

  return path;
};

export default getPathFromIndex;
