import axios from 'axios';
import { Uploader } from '@/views/creatives/utils/psdUploader';
import { generateRandomId } from '@/views/creatives/utils/generateRandomId';
import { useUserStore } from '@/common/store/user';
import { useCanvasStore } from '@/common/store/canvas';
import { CanvasTypesWithUnknown } from '@/views/creatives/enums/psd';
import { Frame, GroupedData, GroupedFolder, Instance, Layer, PSDData, PSDLayer, RichTextData, TextLayer } from '@/views/creatives/interfaces/psd';
import { IAssetData, IImageFrame, IInstance, ITextFrame } from '@/views/creatives/interfaces/creativeData';
import { CanvasTypes } from '@/views/creatives/enums';
import { ICanvasInstance } from '@/views/creatives/interfaces/layer';
import { useEditorStore } from './editor';
// import { psdErrorConstants, psdWarningConditions } from '@/views/creatives/constants';

const instance = axios.create({
  baseURL: import.meta.env.VITE_PSD_URI,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': import.meta.env.VITE_PSD_API_KEY,
  },
});

export interface ImportDialogConfig {
  show: boolean;
  okText: string;
  onOk?: (data) => void;
  onCancel?: () => void;
}

type TFrame = IImageFrame | ITextFrame;

export const INITIAL_DIALOG_SETUP = {
  show: false,
};

export const usePsdImportStore = defineStore('psdImport', {
  state: () => ({
    dialogSettings: { ...INITIAL_DIALOG_SETUP },
    assetsToImport: [],
    assetsProgress: null,
    importStep: null,
    artboardSize: null,
    layersToConvert: {},
    fonts: {},
  }),

  actions: {
    /**
     * Set options values to dialogSettings to update ImportDialog
     * values and enable show status.
     * @param options - Dialog options
     */
    showImportDialog(options?: Partial<ImportDialogConfig>) {
      Object.assign(this.dialogSettings, {
        ...options,
        show: true,
      });
    },

    /**
     * Reset dialogSettings with Initial config to close
     * ImportDialog component
     */
    hideImportDialog() {
      Object.assign(this.dialogSettings, { ...INITIAL_DIALOG_SETUP });
    },

    /**
     * Create a new instance of Uploader class
     * @param file - File to upload
     * @returns Uploader instance
     */
    uploader(file) {
      return new Uploader(instance, file);
    },

    /**
     * Fetch psd read status from server
     * @param session - session id
     * @returns Promise with psd status and data
     */
    async fetchReadStatus(session: string) {
      if (!session) return Promise.resolve();

      const response = await instance.request({
        url: `/output?session=${session}`,
        method: 'GET',
      });

      if (response.data.folders) return this.processLayerData(response.data);

      const wait = (ms) => new Promise((res) => { setTimeout(res, ms); });
      await wait(2500);
      return this.fetchReadStatus(session);
    },

    async fetchAssetStatus(session: string) {
      if (!session) return Promise.resolve();

      const response = await instance.request({
        url: `/assets?session=${session}`,
        method: 'GET',
      });
      this.assetsProgress = response.data.status;
      if (response.data.status === 'SUCCESS') return response.data;

      const wait = (ms) => new Promise((res) => { setTimeout(res, ms); });
      await wait(5000);
      return this.fetchAssetStatus(session);
    },

    resetAssetsToImport() {
      this.assetsToImport = [];
      this.assetsProgress = null;
      this.importStep = null;
      this.artboardSize = null;
      this.layersToConvert = [];
      this.fonts = {};
    },

    importAssets(session: string) {
      if (!session) return Promise.resolve();
      const userStore = useUserStore();
      const { currentUser } = storeToRefs(userStore);
      const companyId = window.sessionStorage.getItem('cmp:company-id');
      const response = instance.request({
        url: '/assets',
        method: 'POST',
        data: {
          session,
          companyId,
          userId: currentUser.value.id,
          assets: this.assetsToImport,
          stage: import.meta.env.VITE_NODE_ENV,
        },
      });
      return response;
    },

    /**
     * Groups layers by type and folder.
     * @param {PSDData} data - The data containing folders and layers.
     * @returns {GroupedData} An array of folders with their respective layers grouped by type.
     */
    groupLayersByTypeAndFolder(data:PSDData): GroupedData {
      const groupedData = [];

      // Iterate through each folder
      for (const folder of data.folders) {
        const folderName = folder.name;
        // Create a new folder object in the groupedData
        const newFolder = {
          ...folder,
          layers: {},
          id: data.layers[folderName][0]?.id,
        } as GroupedFolder;

        // check if has parent and use parent id for group id
        const parentId = data.layers[folderName][0]?.parent;
        if (parentId) {
          newFolder.id = parentId;
        }
        groupedData.push(newFolder);

        // Iterate through each layer in the current folder
        for (const layer of data.layers[folderName] || []) {
          const layerType = layer.type;

          // Create a new array for the layer type if not present
          if (!newFolder.layers[layerType]) {
            newFolder.layers[layerType] = [];
          }

          // Add the layer to the corresponding layer type array
          newFolder.layers[layerType].push(layer);
        }
      }
      return groupedData;
    },

    /**
     * Different validation conditions for each layer type.
     * @param layer - The layer to check for validation conditions.
     */
    layerValidationConditions(layer:PSDLayer) {
      const warningMessage = [];
      const errorMessage = [];

      // check if layer has blend_mode and is not 'norm' add warning
      if (layer.blend_mode && layer.blend_mode !== 'norm') {
        warningMessage.push({
          message: 'Unsupported Blend Mode',
          value: [layer.blend_mode],
        });
      }
      // check if layer has effects add warning
      if (layer.effects && layer.effects.length > 0) {
        warningMessage.push({
          message: 'Unsupported Effects',
          value: layer.effects,
        });
      }
      // check if layer width or height is over 4096 add error
      if (layer.width > 4096 || layer.height > 4096) {
        errorMessage.push('Over Max 4096x4096 Dimensions');
      }

      let removedFontWarnings;
      if (layer.type === 'type') {
        const supportedFonts = this.getSupportedFonts();
        removedFontWarnings = layer.warnings.filter(({ message }) => {
          const isFontError = message === 'Fonts not available';
          const fontSupported = layer?.rich_text.every((text) => supportedFonts.has(text.font));
          return !(isFontError && fontSupported);
        });
      }

      layer.warnings = [...(removedFontWarnings || layer.warnings), ...warningMessage];
      layer.errors = [...layer.errors, ...errorMessage];
      if (layer.errors.length > 0) layer.is_valid = false;
    },

    /**
     * Checks if each layer has any warning or error conditions.
     * @param data - The data containing folders and layers.
     */
    checkLayerValidation(data: GroupedData): void {
      // Iterate through each folder in the data and check if each layer for any warning or error conditions
      data.forEach((folder) => {
        // Iterate through each layer type in the current folder (pixel, type, shape)
        for (const layerType in folder.layers) {
          if (!Object.prototype.hasOwnProperty.call(folder.layers, layerType)) continue;
          // Iterate through each layer in the current layer type
          folder.layers[layerType].forEach((layer) => {
            this.layerValidationConditions(layer);
          });
        }
      });
    },

    /**
     * Checks if each folder has a single layer type.
     * @param data - The data containing folders and layers.
     */
    checkFolderValidation(data: GroupedData): void {
      // Iterate through each folder in the data
      for (const folder of data) {
        // Get an array of unique layer types in the current folder
        const uniqueLayerTypes = Object.keys(folder.layers);
        folder.is_valid = true;

        // Check if there is more than one unique layer type
        if (uniqueLayerTypes.length > 1) {
          // Update folder properties for mixed layer types
          folder.is_valid = false;
          folder.errors = ['Mixed Layer Types'];
        }
        // check if any layers are unsupported type 'shape', 'smartobject', set folder to invalid
        if (uniqueLayerTypes.includes('shape')) {
          // folder.is_valid = false;
          // allow shape layers for now and remove later on
          folder.is_valid = true;
          const warningMessage = {
            message: 'Unsupported Layer Type',
            value: ['shape'],
          };
          folder.warnings = [warningMessage];
        }
        if (uniqueLayerTypes.includes('smartobject')) {
          folder.is_valid = false;
          folder.errors = ['Unsupported Layer Type'];
        }
      }
    },

    /**
     * Handle root folder and move layers into there own folder to be converted to frames
     * @param data - The data containing folders and layers from the psd file.
     */
    handleConvertRootFolder(data: PSDData): void {
      const rootFolder = data.layers?.Root as Layer[];
      if (rootFolder) {
        // create new list to hold new folders so we dont mutate the original data just yet
        const newFolders = [];
        rootFolder.forEach((layer) => {
          const newFolder = {
            id: `${generateRandomId(5)}`,
            name: layer.name,
            bbox: layer.bbox,
            width: layer.width,
            height: layer.height,
            layers: [layer],
          };
          newFolders.push(newFolder);
        });
        newFolders.reverse().forEach((folder) => {
          const payload = {
            name: folder.name,
            bbox: folder.bbox,
            width: folder.width,
            height: folder.height,
          };
          // add new folder to the start of data folders
          data.folders.unshift(payload);
          data.layers[folder.name] = folder.layers;
        });
        // remove root folder from data layers and folders
        const rootFolderIndex = data.folders.indexOf(data.folders.find((folder) => folder.name === 'Root'));
        data.folders.splice(rootFolderIndex, 1);
        delete data.layers.Root;
      }
    },

    /**
     * Converts the grouped data to a frame and layer structure.
     * @param data - The data containing folders and layers from the psd file.
     * @returns An array of frames with their respective instances.
     */
    convertToFramesAndInstances(data: GroupedData): Frame[] {
      // create a map of layer ids to frame ids
      const layersToConvertMap = {};
      return data.map((folder) => {
        const isMixed = folder.errors?.includes('Mixed Layer Types');
        // get the layer type for the folder from the first layer
        const frameType = isMixed ? CanvasTypesWithUnknown.UNKNOWN
          : this.mapLayerType(Object.keys(folder.layers)[0]);
        const id = `${generateRandomId(5)}`;
        const { name, bbox, width, height, errors, warnings } = folder;
        const isUnknown = frameType === CanvasTypesWithUnknown.UNKNOWN;
        const isFrameValid = isUnknown ? false : folder.is_valid;
        const frame: Frame = {
          id,
          name,
          type: frameType,
          instances: [],
          bbox,
          width,
          height,
          is_valid: isFrameValid,
          errors,
          warnings,
        };
        let mapId = id as string | number;
        // if folder has a single layer then set mapId to layer id so that
        // we can convert it later on if needed
        if (Object.keys(folder.layers).length === 1
          && folder.layers[Object.keys(folder.layers)[0]].length === 1) {
          mapId = folder.layers[Object.keys(folder.layers)[0]][0].id;
        }

        if (frameType !== CanvasTypesWithUnknown.IMAGE && frameType !== CanvasTypesWithUnknown.UNKNOWN) {
          layersToConvertMap[mapId] = { layers: [], convert: true };
        }
        for (const layerType in folder.layers) {
          if (!Object.prototype.hasOwnProperty.call(folder.layers, layerType)) continue;
          const instanceType = this.mapLayerType(layerType);
          const frameValid = isFrameValid;
          folder.layers[layerType].forEach((layer) => {
            // check if frame is valid, if not then set all instances to invalid
            const isValid = frameValid ? layer.is_valid : false;

            const payload: Instance = {
              ...layer,
              id: layer.id,
              name: layer.name,
              type: instanceType,
              is_valid: isValid,
              errors: frameValid ? layer.errors : frame.errors,
              warnings: layer.warnings,
            };
            frame.instances.push(payload);
            // add all frame ids that are not pixel to layersToConvert
            if (frameType !== CanvasTypesWithUnknown.IMAGE && frameType !== CanvasTypesWithUnknown.UNKNOWN) {
              layersToConvertMap[mapId].layers.push(layer.id);
            }
          });
        }
        this.layersToConvert = layersToConvertMap;
        return frame;
      });
    },

    // Helper function to map psd layer type to instance type
    mapLayerType(layerType: string): CanvasTypesWithUnknown {
      switch (layerType) {
        case 'pixel':
          return CanvasTypesWithUnknown.IMAGE;
        case 'type':
          return CanvasTypesWithUnknown.TEXT;
        case 'shape':
          return CanvasTypesWithUnknown.SHAPE;
        default:
          return CanvasTypesWithUnknown.UNKNOWN;
      }
    },

    // Helper function to sort ids by order index
    sortIds(list, order) {
      return list.sort((a, b) => {
        const aIndex = order.indexOf(a.id);
        const bIndex = order.indexOf(b.id);
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      });
    },

    // sort function to order frames by psd generator order
    sortGroups(list: GroupedData, generator: number[]) {
      const order = [...generator];
      // we need to also reverse the order of the list to match the order in ui, e.g. background last
      this.sortIds(list, order).reverse();
      // also sort nested layers by generator order
      list.forEach((folder) => {
        for (const layerType in folder.layers) {
          if (!Object.prototype.hasOwnProperty.call(folder.layers, layerType)) continue;
          this.sortIds(folder.layers[layerType], order);
        }
      });
    },

    /**
     * Process the psd data into a list of frames and instances.
     * @param data - The data containing folders and layers from the psd file.
     * @returns An array of frames with their respective instances.
     */
    processLayerData(data: PSDData) {
      // check if folder is empty
      if (data.folders.length === 0) return [];
      // set artboard size from root folder root object
      const { width, height } = data.folders.find((folder) => folder.name === 'Root');
      this.artboardSize = { width, height };
      // handle root folder and move layers into there own folders to be converted to frames
      this.handleConvertRootFolder(data);
      // loop through folders and layers and group them by type and folder
      const groupedLayers = this.groupLayersByTypeAndFolder(data);
      // remove any folders with no layers
      groupedLayers.forEach((folder, index) => {
        if (Object.keys(folder.layers).length === 0) {
          groupedLayers.splice(index, 1);
        }
      });
      this.checkLayerValidation(groupedLayers);
      // check that each folder has a single type of layer
      this.checkFolderValidation(groupedLayers);
      // loop over layers and if valid then add its id to assets to import list
      // remove when we allow users to select which layers to import
      groupedLayers.forEach((folder) => {
        if (folder.is_valid) {
          for (const layerType in folder.layers) {
            if (!Object.prototype.hasOwnProperty.call(folder.layers, layerType)) continue;
            folder.layers[layerType].forEach((layer) => {
              if (layer.is_valid) {
                this.assetsToImport.push(layer.id);
              }
            });
          }
        }
      });
      // order layer list order by psd generator order
      this.sortGroups(groupedLayers, data.generator);
      // convert the grouped layers to a frame and layer structure
      const list = this.convertToFramesAndInstances(groupedLayers);
      return list;
    },

    resizeLayerData(originalArtboardSize, newArtboardSize, size) {
      // if originalArtboardSize or newArtboardSize is null then return fallback size
      if (originalArtboardSize === null || newArtboardSize === null) return { width: 50, height: 50 };
      // Calculate the scale factors for width and height
      const scaleX = newArtboardSize.width / originalArtboardSize.width;
      const scaleY = newArtboardSize.height / originalArtboardSize.height;

      // check if size is 100% of artboard size is so then use math.max otherwise use math.min
      const shouldFill = size.width === originalArtboardSize.width && size.height === originalArtboardSize.height;

      // Use the larger scale factor to maintain the aspect ratio
      const scale = shouldFill ? Math.max(scaleX, scaleY) : Math.min(scaleX, scaleY);

      // Calculate the scaled layer width and height as a decimal of newArtboardSize
      const scaledLayerWidthPercentage = ((size.width * scale) / newArtboardSize.width);
      const scaledLayerHeightPercentage = ((size.height * scale) / newArtboardSize.height);

      return {
        width: scaledLayerWidthPercentage,
        height: scaledLayerHeightPercentage,
      };
    },

    /**
     * Function to process frame iteration data for each frame, mapping
     * the psd layer data for each master size.
     * @param data - psd layer data
     * @param frameId - frame id that will be used in canvas
     * @param iterations - object to hold iteration data for each frame
     * @returns void
     */
    processFrameIterationData(data, frameId, iterations) {
      // if artboard size is not set then return as the user has cancelled the import
      if (this.artboardSize === null) return;
      const canvasStore = useCanvasStore();
      const { masters } = canvasStore;
      const { width: artboardWidth, height: artboardHeight } = this.artboardSize;
      const psdRatio = artboardWidth / artboardHeight;
      // normalize frame data based on artboard size
      const frameIteration = {
        x: (data.bbox[0] + (data.bbox[2] - data.bbox[0]) / 2) / artboardWidth,
        y: (data.bbox[1] + (data.bbox[3] - data.bbox[1]) / 2) / artboardHeight,
        w: data.width / artboardWidth,
        h: data.height / artboardHeight,
      };
      const iterationData = {};
      masters.forEach((master) => {
        const { width: masterWidth, height: masterHeight } = master;
        const masterRatio = masterWidth / masterHeight;
        iterationData[master.id] = {};
        switch (true) {
          case psdRatio < 1 && masterRatio < 1:
            iterationData[master.id] = frameIteration;
            iterations[frameId] = iterationData;
            return;
          case psdRatio > 1 && masterRatio > 1:
            iterationData[master.id] = frameIteration;
            iterations[frameId] = iterationData;
            return;
          case psdRatio === 1 && masterRatio === 1:
            iterationData[master.id] = frameIteration;
            iterations[frameId] = iterationData;
            return;
          default:
            break;
        }
        const { width: frameWidth, height: frameHeight } = this.resizeLayerData(this.artboardSize, master, data);
        iterationData[master.id] = {
          x: frameIteration.x,
          y: frameIteration.y,
          w: frameWidth,
          h: frameHeight,
        };
      });
      iterations[frameId] = iterationData;
    },

    getSupportedFonts() {
      const { fonts } = useEditorStore();
      const postScriptKeys = new Map();
      fonts.forEach((font) => {
        if (font.metadata && font.metadata.fontPostScript) {
          postScriptKeys.set(font.metadata.fontPostScript, {
            name: font.name,
            src: font.src,
          });
        }
      });
      return postScriptKeys;
    },

    // use function to loop over text data array and move common properties
    // to the top level of the object and keep the rest in the format object
    processTextData(textData:RichTextData[]) {
      const textArray = [];
      const totleLength = textData.length;

      const postScriptFontKeys = this.getSupportedFonts();

      if (this.artboardSize === null) return [];
      const artboardHeight = this.artboardSize.height;

      textData.forEach((textItem) => {
        const { size, fillColor, tracking, font } = textItem;

        // find and replace and \r as photoshop adds them for new lines
        let text = textItem.text.replace(/\r/g, '\n');
        // if text is last item in array then remove \n from end of string
        if (textData.indexOf(textItem) === totleLength - 1) {
          text = text.replace(/\n$/, '');
        }

        const targetBoardHeight = 1080; // hard coded for now
        const format = {} as any;
        if (size !== undefined) format.fontSize = size / (artboardHeight / targetBoardHeight);
        if (tracking !== undefined) format.letterSpacing = textItem.tracking / 50; // photoshop 100% is equal to 2 in canvas
        if (fillColor !== undefined) format.color = fillColor;

        if (postScriptFontKeys.has(font)) {
          const currentFont = postScriptFontKeys.get(font);
          format.family = currentFont.name;
          if (!this.fonts[currentFont.name]) this.fonts[currentFont.name] = import.meta.env.VITE_ASSETS_URI + currentFont.src;
        }
        textArray.push({ text, format });
      });
      return textArray;
    },

    convertLeadingToLineHeight(layer: TextLayer) {
      let foundLeading = 0;

      // fallback if no valid data.
      if (layer.rich_text.length === 0) return 0;

      // find either auto_leading or leading
      const firstRichTextItem = layer.rich_text[0];
      const { autoLeading, leading, size } = firstRichTextItem;

      if (autoLeading === false) {
        foundLeading = leading;
      } else {
        foundLeading = size * 1.2;
      }

      // build a css style string from richTextObj
      const styleString = `${layer.rich_text[0].size}px ${layer.rich_text[0].font}`;

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 1;
      canvas.height = 1;

      ctx.font = styleString;
      ctx.textAlign = 'left';
      ctx.textBaseline = 'alphabetic';

      const textMetrics = ctx.measureText('W');

      const lineHeightMultiplier = (foundLeading - textMetrics.fontBoundingBoxDescent) / (textMetrics.fontBoundingBoxAscent * 1.2);

      // remove the created canvas element
      canvas.remove();

      return lineHeightMultiplier;
    },

    processTextLayerIterationData(layer: TextLayer) {
      const canvasStore = useCanvasStore();
      const { masters } = canvasStore;

      // use function to loop over text data array and move common properties
      // to the top level of the object and keep the rest in the format object
      const textData = this.processTextData(layer.rich_text);

      const allSameFont = (arr) => arr.every((val) => val.format.family === arr[0].format.family);
      const textFont = (allSameFont(textData)) ? textData[0]?.format?.family : 'mixed';

      const textLineHeight = this.convertLeadingToLineHeight(layer);

      const iterationData = {
        text: textData,
        lineHeight: textLineHeight || 1, // default to 1 if we dont find a value
        color: '#ffffff',
        family: textFont || 'Arial',
        size: 24,
        style: 'normal',
      };

      const iterations = {};

      // loop over masters and add iteration data for each master
      masters.forEach((master) => {
        iterations[master.id] = iterationData;
      });
      return iterations;
    },

    handleTextLayerInstance(layer) {
      const instanceId = `I_${generateRandomId(5)}`;
      return {
        id: instanceId,
        name: layer.name,
        type: CanvasTypes.TEXT,
      } as ICanvasInstance;
    },

    handleImageLayerInstance(layer, assets, keys) {
      const { id: keyId, name: instanceName } = layer;

      const assetUrl = keys[keyId];
      const assetId = `A_${generateRandomId(5)}`;
      const asset: IAssetData = {
        id: assetId,
        name: instanceName,
        src: assetUrl,
      };

      assets[assetId] = asset;

      const instanceId = `I_${generateRandomId(5)}`;
      const instancePayload = {
        id: instanceId,
        name: instanceName,
        type: CanvasTypes.IMAGE,
        assetId,
      } as ICanvasInstance;

      return instancePayload;
    },

    // convert data to work with canvas and create a list of frames, instances and assets to return
    convertFrameInstanceToCanvas(data: Frame[], keys: { [id:number]: string }) {
      const assets: { [assetId: string]: IAssetData } = {};
      const frames: TFrame[] = [];
      const instances: { [frameId: string]: IInstance[] } = {};
      const iterations: any = {};

      // filter out shapes and smart objects
      const filteredData = data.filter((frame) => frame.type !== CanvasTypesWithUnknown.UNKNOWN);

      filteredData.forEach((frame) => {
        const { name, instances: layerInstances } = frame;
        // check if frame should be converted (use psd layer id)
        let type = CanvasTypes[frame.type.toUpperCase()];
        let convertId = frame.id;
        // if frame has a single layer then use that layer id
        if (Object.keys(frame.instances).length === 1) {
          convertId = frame.instances[0].id;
        }
        if (this.layersToConvert[convertId]?.convert ?? false) {
          type = CanvasTypes.IMAGE;
        }
        const frameId = `F_${generateRandomId(5)}`;
        const frameData: TFrame = {
          id: frameId,
          name,
          type,
          locked: [],
        };
        frames.push(frameData);
        instances[frameId] = [];
        iterations[frameId] = {};
        this.processFrameIterationData(frame, frameId, iterations);
        const frameIterationData = { ...iterations[frameId] };
        layerInstances.forEach((instanceItem) => {
          let instancePayload = {} as ICanvasInstance;
          let instanceIterationData = {};
          if (type === CanvasTypes.IMAGE && keys[instanceItem.id]) {
            instancePayload = this.handleImageLayerInstance(instanceItem, assets, keys);
            instanceIterationData = frameIterationData;
          }
          if (type === CanvasTypes.TEXT) {
            instancePayload = this.handleTextLayerInstance(instanceItem);
            instanceIterationData = this.processTextLayerIterationData(instanceItem);
          }

          instances[frameId].push(instancePayload);
          iterations[frameId][instancePayload.id] = instanceIterationData;
        });
      });
      return { assets, frames, instances, iterations, fonts: this.fonts };
    },
  },
});
export default usePsdImportStore;
