/**
 * TODO: Check if can be combined with @/common/utils/axiosCache.ts utility
 * Update assets cache with new data, according to received params.
 * @param cacheId - Cache ID
 * @param list - List to update
 * @param items - Items to set in list
 */
export function updateAssetsCache(cacheId: string, list: string, items: Array<{ id: string }>) {
  const key = `axios-cache:${cacheId}`;
  const cache = window.sessionStorage.getItem(key);
  // Check if cache exists
  if (!cache) return;
  // Parse data
  const json = JSON.parse(cache);
  // Update cache list
  json.data.data[list] = items;
  window.sessionStorage.setItem(key, JSON.stringify(json));
}

export default updateAssetsCache;
