import { defineStore } from 'pinia';
import { i18n } from '@/common/plugins';
import { requests } from '@/common/plugins/axios';
import { objectIsEmpty, getFormatDate, updateAxiosCache, removeFromAxiosCache, replaceAxiosCache } from '@/common/utils';
import { CompanyInsight, CompanyLicense, CompanyStats, CompanyGroup, Company, ChartExportStats } from '@/views/companies/interfaces';

const { t } = i18n.global;

export const useCompanyStore = defineStore('company', {
  state: () => ({
    createdCompanyId: '',
    currentCompanyId: '', // only used for the creatives views
    companies: [] as Company[],
    currentCompany: {} as Company,
    currentCompanyGroups: [] as CompanyGroup[],
    statsById: {} as CompanyStats,
  }),

  getters: {
    /**
     * Check statsById data of current company and create an array of
     * CompanyInsight to be showed in companyEdit view.
     * @param state => Current state.
     * @returns => Array of CompanyInsight[]
     */
    currentCompanyInsights: (state): CompanyInsight[] => {
      if (objectIsEmpty(state.currentCompany)) return [];

      const totalCreatives = state.statsById.creatives?.total;
      const totalCreativesExports = state.statsById.creatives?.totalExports;
      const averageExports = (totalCreatives && totalCreativesExports) ? (totalCreativesExports / totalCreatives).toFixed(2) : null;

      return [
        { label: t('INSIGHTS.LAST_USED'), value: state.currentCompany.lastUsed ? Math.floor((Date.now() - Math.floor(new Date(state.currentCompany.lastUsed).getTime())) / 86400000) : '--' },
        { label: t('INSIGHTS.SESSIONS_COUNT'), value: state.statsById.sessions ?? '--' },
        { label: t('INSIGHTS.LAST_SESSION'), value: state.currentCompany.lastUsed ? getFormatDate(state.currentCompany.lastUsed) : '--' },
        { label: t('INSIGHTS.ADS_COUNT'), value: totalCreatives ?? '--' },
        { label: t('INSIGHTS.EXPORTS_DOWNLOADED'), value: totalCreativesExports ?? '--' },
        { label: t('INSIGHTS.AVERAGE_EXPORTS'), value: averageExports ?? '--' },
      ];
    },

    currentCompanyExportStats: (state): ChartExportStats => {
      if (objectIsEmpty(state.statsById)) return null;

      // template for chart data so bar chart can be rendered the same way when less than 5
      let topExports = [{ '': 0 }, { '': 0 }, { '': 0 }, { '': 0 }, { '': 0 }] as { [key: string]: number }[];

      // remove the Default exports as it is not a partner.
      const exports = state.statsById.creatives?.topExports;
      // .filter((exported) => (Object.keys(exported)[0] !== 'Default'));

      // clone the topExports array to avoid mutating the original array
      topExports = [...exports, ...topExports];

      // limit the array to 5 elements.
      topExports.length = 5;

      const dataLabels = topExports.map((exported) => Object.keys(exported)[0]);
      const dataValues = topExports.map((exported) => Object.values(exported)[0]);
      return { dataValues, dataLabels };
    },
  },

  actions: {
    /**
     * Check to see if a companyId is in session storage
     */
    async checkCompanyId() {
      if (this.currentCompanyId) return;
      const companyId = window.sessionStorage.getItem('cmp:company-id');
      if (!companyId) return;
      await this.selectCompany(companyId);
    },

    /**
     * Set currentCompanyId store variable
     * companyId value received.
     * @param companyId => Company id
     */
    async selectCompany(companyId: string): Promise<void> {
      this.currentCompanyId = companyId;
      // if no companies are loaded yet, fetch a single company
      // quicker than making the request for all companies
      if (this.companies.length === 0) await this.fetchCompanyById(companyId);
      else this.currentCompany = this.companies.find((n) => n.id === companyId);
      window.sessionStorage.setItem('cmp:company-id', companyId);
    },

    /**
     * Calls 'selectCompany' action and set
     * @param companyId => Company id
     */
    async editCompany(companyId: string): Promise<void> {
      if (companyId !== this.currentCompanyId) this.$reset();
      // this.selectCompany(companyId);
      await this.fetchCompanyById(companyId);
    },

    /**
     * Fetch companies from API and set to
     * companies store variable.
     */
    async fetchCompanies(name?: string): Promise<void> {
      const params = [];

      if (name) {
        params.push(`q=name:contains:${encodeURIComponent(name)}`);
      }

      const result = await requests.get(`/companies?${params.join('&')}`, {
        id: 'fetch-companies',
      });

      this.companies = result.items.sort((a, b) => a.name.localeCompare(b.name));
    },

    /**
     * Make a POST request to create a new company, add
     * the company data result to companies state variable
     * and return the request message.
     * @param name => Company name
     * @returns => Request success message
     */
    async createCompany(name: string): Promise<void> {
      const result = await requests.post(
        '/companies',
        { name },
        {
          cache: {
            update: {
              'fetch-companies':
              (cache, response) => updateAxiosCache(cache, response, 'items', response.data.id),
            },
          },
        },
      );

      // Add new company to company store
      this.companies.push(result);
      // Set value to created company id to createdCompany
      this.createdCompanyId = result.id;
    },

    /**
     * Fetch currentCompany data by Id and set its data
     * to currentCompany state variable.
     * @param id => Company id to fetch
     */
    async fetchCompanyById(id: string): Promise<void> {
      this.currentCompany = await requests.get(`/companies/${id}`, {
        id: `fetch-company-${id}`,
      });
    },

    /**
     * Add a license to current company licenses array
     * @param license => License to add
     */
    addLicense(license: CompanyLicense) {
      this.currentCompany.licenses.push(license);
    },

    /**
     * Make DELETE request to delete specific company
     * and reset current store.
     * @param id => Company id
     */
    async deleteCompany(id: string): Promise<void> {
      const companyIndex = this.companies.findIndex((company) => company.id === id);

      await requests.delete(`/companies/${id}`, {
        cache: {
          update: {
            'fetch-companies': (cache) => removeFromAxiosCache(cache, 'items', id),
          },
        },
      });

      this.companies.splice(companyIndex, 1);
    },

    /**
     * Make a PATCH request to update any value of specific
     * company data.
     * @param id => Company Id
     */
    async updateCompany(id: string): Promise<void> {
      await requests.patch(
        `/companies/${id}`,
        this.currentCompany,
        {
          cache: {
            update: {
              'fetch-companies': 'delete',
              [`fetch-company-stats-${id}`]: 'delete',
              [`fetch-company-${id}`]: (cache, response) => replaceAxiosCache(cache, response),
            },
          },
        },
      );
    },

    /**
     * Make a GET request to get stats data by specific company.
     * @param id => Company Id
     */
    async fetchStatsById(id: string):Promise<void> {
      const response = await requests.get(`/companies/${id}/stats`, {
        id: `fetch-company-stats-${id}`,
      });

      this.statsById = response.stats;
    },

    /**
     * Make a GET request to get groups list of specific company
     * and set them to currentCompanyUsers state variable.
     * @param id => Company Id
     */
    async fetchCompanyGroups(id: string): Promise<void> {
      const result = await requests.get(`/companies/${id}/groups`, {
        id: `fetch-company-groups-${id}`,
      });

      this.currentCompanyGroups = result.items as CompanyGroup[];
    },

    /**
     * Make a POST request to create a new User Group
     * in specific company and update its current data.
     * @param name => Group name
     */
    async createCompanyGroup(name: string) {
      const { id } = this.currentCompany;
      const newGroup = await requests.post(
        `/companies/${id}/groups`,
        { name },
        {
          cache: {
            update: {
              [`fetch-company-groups-${id}`]: 'delete',
            },
          },
        },
      );

      if (!objectIsEmpty(newGroup)) {
        this.currentCompanyGroups.push(newGroup);
      }
    },

    /**
     * Remove companyId  from sessionStorage and reset
     * 'currentCompanyId' value.
     */
    resetEditMode() {
      this.currentCompanyId = '';
      window.sessionStorage.removeItem('cmp:company-id');
    },
  },
});

export default useCompanyStore;
