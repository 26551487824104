import { defineStore } from 'pinia';
import type { GameSchema } from '@cmp/game-mechanics';
import { type FinishCondition, assertNever, FinishConditionType } from '@cmp/common';
import { requests } from '@/common/plugins/axios';

export interface IGameResponse {
  id: number;
  name: string;
  description: string;
  thumbnailUrl: string;
  isHidden: boolean;
  creator: {
    email: string;
  };
  status: 'hidden' | 'coming_soon' | 'published',
  createdAt: string;
  updatedAt: string;
  mechanic: {
    folderName: string;
    id: number;
    isHidden: boolean;
    type: string;
  };
  settings: {
    interactive: boolean;
    properties: Record<string, string | number | boolean>;
    config: Record<string, string | number | boolean>;
    children: [];
    onGameFin: Array<FinishCondition>;
  };
}

export interface AssetDialogConfig {
  onOk?: (item: IGameResponse) => void;
  onCancel?: () => void;
}

export const useGamesStore = defineStore('games', {
  state: () => ({
    games: [] as IGameResponse[],
    selectedGameSchema: {} as Partial<GameSchema>,
    selectedGameId: null, // game id for identify which published game to use in games, this id is not a layer id
    selectedGamePath: '',
    dialogSettings: {} as AssetDialogConfig,
    showDialog: false,
    showDialogComponents: false,
    score: 0,
    moves: 0,
    health: 0,
  }),

  getters: {
    getGame: (state) => {
      if (!state.selectedGameId) return undefined;
      return state.games.find((n) => n.id === state.selectedGameId);
    },
    getAllGames: (state) => state.games,
    defaultGameSettings: (state) => Object.entries(state.selectedGameSchema.properties || {})
      .reduce((prev, [k, n]) => {
        const clone = { ...prev };
        clone[k] = n.default;
        return clone;
      }, {}),
  },

  actions: {
    async getGames(): Promise<void> {
      const result = await requests.get('/types/games', {
        id: 'fetch-games',
      });

      this.games = result.items;
    },

    showGamesDialog(options: AssetDialogConfig): void {
      this.showDialog = true;
      this.dialogSettings = options;
    },

    hideGamesDialog() {
      this.dialogSettings = {};
      this.showDialog = false;
    },

    showComponentsDialog(options: AssetDialogConfig): void {
      this.showDialogComponents = true;
      this.dialogSettings = options;
    },

    hideComponentsDialog() {
      this.dialogSettings = {};
      this.showDialogComponents = false;
    },

    setProgression(variable: FinishConditionType, value: number) {
      switch (variable) {
        case 'SCORE': this.score = value; break;
        case 'HEALTH': this.health = value; break;
        case 'MOVES': this.moves = value; break;
        default:
          assertNever(variable);
      }
    },

    clearProgression() {
      this.score = 0;
      this.moves = 0;
      this.health = 0;
    },
  },
});

export default useGamesStore;
